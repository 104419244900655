// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const StudentInventoryCategory = {
  Avatar: 'Avatar',
  Realm: 'Realm',
  RealmItem: 'RealmItem',
} as const
export type StudentInventoryCategory =
  (typeof StudentInventoryCategory)[keyof typeof StudentInventoryCategory]

export function StudentInventoryCategoryFromJSON(
  json: any,
): StudentInventoryCategory {
  return StudentInventoryCategoryFromJSONTyped(json, false)
}

export function StudentInventoryCategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentInventoryCategory {
  return json as StudentInventoryCategory
}

export function StudentInventoryCategoryToJSON(
  value?: StudentInventoryCategory | null,
): any {
  return value as any
}
