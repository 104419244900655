// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface BookBlurbBook
 */
export interface BookBlurbBook {
  /**
   *
   * @type {string}
   * @memberof BookBlurbBook
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof BookBlurbBook
   */
  title?: string | null
  /**
   *
   * @type {string}
   * @memberof BookBlurbBook
   */
  blurb?: string | null
  /**
   *
   * @type {string}
   * @memberof BookBlurbBook
   */
  thumbnail?: string | null
}

/**
 * Check if a given object implements the BookBlurbBook interface.
 */
export function instanceOfBookBlurbBook(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function BookBlurbBookFromJSON(json: any): BookBlurbBook {
  return BookBlurbBookFromJSONTyped(json, false)
}

export function BookBlurbBookFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookBlurbBook {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    blurb: !exists(json, 'blurb') ? undefined : json['blurb'],
    thumbnail: !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
  }
}

export function BookBlurbBookToJSON(value?: BookBlurbBook | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_id: value.activityId,
    title: value.title,
    blurb: value.blurb,
    thumbnail: value.thumbnail,
  }
}
