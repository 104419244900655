// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BookBlurbRepresentation } from './BookBlurbRepresentation'
import {
  BookBlurbRepresentationFromJSON,
  BookBlurbRepresentationFromJSONTyped,
  BookBlurbRepresentationToJSON,
} from './BookBlurbRepresentation'

/**
 *
 * @export
 * @interface BookBlurbRepresentationBaseApiListResult
 */
export interface BookBlurbRepresentationBaseApiListResult {
  /**
   *
   * @type {Array<BookBlurbRepresentation>}
   * @memberof BookBlurbRepresentationBaseApiListResult
   */
  data: Array<BookBlurbRepresentation>
  /**
   *
   * @type {number}
   * @memberof BookBlurbRepresentationBaseApiListResult
   */
  total: number
}

/**
 * Check if a given object implements the BookBlurbRepresentationBaseApiListResult interface.
 */
export function instanceOfBookBlurbRepresentationBaseApiListResult(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'total' in value

  return isInstance
}

export function BookBlurbRepresentationBaseApiListResultFromJSON(
  json: any,
): BookBlurbRepresentationBaseApiListResult {
  return BookBlurbRepresentationBaseApiListResultFromJSONTyped(json, false)
}

export function BookBlurbRepresentationBaseApiListResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookBlurbRepresentationBaseApiListResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(BookBlurbRepresentationFromJSON),
    total: json['total'],
  }
}

export function BookBlurbRepresentationBaseApiListResultToJSON(
  value?: BookBlurbRepresentationBaseApiListResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(BookBlurbRepresentationToJSON),
    total: value.total,
  }
}
