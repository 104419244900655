// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StudentLessonResultRepresentation } from './StudentLessonResultRepresentation'
import {
  StudentLessonResultRepresentationFromJSON,
  StudentLessonResultRepresentationFromJSONTyped,
  StudentLessonResultRepresentationToJSON,
} from './StudentLessonResultRepresentation'

/**
 *
 * @export
 * @interface StudentLessonResultsRepresentation
 */
export interface StudentLessonResultsRepresentation {
  /**
   *
   * @type {number}
   * @memberof StudentLessonResultsRepresentation
   */
  totalItems: number
  /**
   *
   * @type {Array<StudentLessonResultRepresentation>}
   * @memberof StudentLessonResultsRepresentation
   */
  readonly items: Array<StudentLessonResultRepresentation>
}

/**
 * Check if a given object implements the StudentLessonResultsRepresentation interface.
 */
export function instanceOfStudentLessonResultsRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'totalItems' in value
  isInstance = isInstance && 'items' in value

  return isInstance
}

export function StudentLessonResultsRepresentationFromJSON(
  json: any,
): StudentLessonResultsRepresentation {
  return StudentLessonResultsRepresentationFromJSONTyped(json, false)
}

export function StudentLessonResultsRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentLessonResultsRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    totalItems: json['total_items'],
    items: (json['items'] as Array<any>).map(
      StudentLessonResultRepresentationFromJSON,
    ),
  }
}

export function StudentLessonResultsRepresentationToJSON(
  value?: StudentLessonResultsRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    total_items: value.totalItems,
  }
}
