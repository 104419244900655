// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const FeedbackStatus = {
  NotStarted: 'NotStarted',
  InProgress: 'InProgress',
  Finished: 'Finished',
} as const
export type FeedbackStatus =
  (typeof FeedbackStatus)[keyof typeof FeedbackStatus]

export function FeedbackStatusFromJSON(json: any): FeedbackStatus {
  return FeedbackStatusFromJSONTyped(json, false)
}

export function FeedbackStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FeedbackStatus {
  return json as FeedbackStatus
}

export function FeedbackStatusToJSON(value?: FeedbackStatus | null): any {
  return value as any
}
