// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const WritingStatus = {
  NotStarted: 'NotStarted',
  Drafting: 'Drafting',
  Submitted: 'Submitted',
  Deleted: 'Deleted',
  Checkpoint: 'Checkpoint',
  Revising: 'Revising',
} as const
export type WritingStatus = (typeof WritingStatus)[keyof typeof WritingStatus]

export function WritingStatusFromJSON(json: any): WritingStatus {
  return WritingStatusFromJSONTyped(json, false)
}

export function WritingStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WritingStatus {
  return json as WritingStatus
}

export function WritingStatusToJSON(value?: WritingStatus | null): any {
  return value as any
}
