// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SpellingByStudentDateResponseDto
 */
export interface SpellingByStudentDateResponseDto {
  /**
   * The school id
   * @type {number}
   * @memberof SpellingByStudentDateResponseDto
   */
  schoolBlakeId: number
  /**
   * The blake student id
   * @type {number}
   * @memberof SpellingByStudentDateResponseDto
   */
  studentBlakeId: number
  /**
   * The student CUID student id
   * @type {string}
   * @memberof SpellingByStudentDateResponseDto
   */
  studentId: string
  /**
   * The total number of correct spelling
   * @type {number}
   * @memberof SpellingByStudentDateResponseDto
   */
  totalCorrect?: number | null
  /**
   * The total number of incorrect spelling
   * @type {number}
   * @memberof SpellingByStudentDateResponseDto
   */
  totalIncorrect?: number | null
  /**
   * The error rate percentage
   * @type {number}
   * @memberof SpellingByStudentDateResponseDto
   */
  errorRate?: number | null
}

/**
 * Check if a given object implements the SpellingByStudentDateResponseDto interface.
 */
export function instanceOfSpellingByStudentDateResponseDto(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'schoolBlakeId' in value
  isInstance = isInstance && 'studentBlakeId' in value
  isInstance = isInstance && 'studentId' in value

  return isInstance
}

export function SpellingByStudentDateResponseDtoFromJSON(
  json: any,
): SpellingByStudentDateResponseDto {
  return SpellingByStudentDateResponseDtoFromJSONTyped(json, false)
}

export function SpellingByStudentDateResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SpellingByStudentDateResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    schoolBlakeId: json['schoolBlakeId'],
    studentBlakeId: json['studentBlakeId'],
    studentId: json['studentId'],
    totalCorrect: !exists(json, 'totalCorrect')
      ? undefined
      : json['totalCorrect'],
    totalIncorrect: !exists(json, 'totalIncorrect')
      ? undefined
      : json['totalIncorrect'],
    errorRate: !exists(json, 'errorRate') ? undefined : json['errorRate'],
  }
}

export function SpellingByStudentDateResponseDtoToJSON(
  value?: SpellingByStudentDateResponseDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    schoolBlakeId: value.schoolBlakeId,
    studentBlakeId: value.studentBlakeId,
    studentId: value.studentId,
    totalCorrect: value.totalCorrect,
    totalIncorrect: value.totalIncorrect,
    errorRate: value.errorRate,
  }
}
