// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentLessonProgressionRequest
 */
export interface StudentLessonProgressionRequest {
  /**
   *
   * @type {string}
   * @memberof StudentLessonProgressionRequest
   */
  eventId: string
  /**
   *
   * @type {string}
   * @memberof StudentLessonProgressionRequest
   */
  lessonStepId?: string | null
  /**
   *
   * @type {string}
   * @memberof StudentLessonProgressionRequest
   */
  onboardingStepId?: string | null
  /**
   *
   * @type {string}
   * @memberof StudentLessonProgressionRequest
   */
  assignmentId?: string | null
  /**
   *
   * @type {number}
   * @memberof StudentLessonProgressionRequest
   */
  assignmentTaskId?: number | null
}

/**
 * Check if a given object implements the StudentLessonProgressionRequest interface.
 */
export function instanceOfStudentLessonProgressionRequest(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'eventId' in value

  return isInstance
}

export function StudentLessonProgressionRequestFromJSON(
  json: any,
): StudentLessonProgressionRequest {
  return StudentLessonProgressionRequestFromJSONTyped(json, false)
}

export function StudentLessonProgressionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentLessonProgressionRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    eventId: json['eventId'],
    lessonStepId: !exists(json, 'lessonStepId')
      ? undefined
      : json['lessonStepId'],
    onboardingStepId: !exists(json, 'onboardingStepId')
      ? undefined
      : json['onboardingStepId'],
    assignmentId: !exists(json, 'assignmentId')
      ? undefined
      : json['assignmentId'],
    assignmentTaskId: !exists(json, 'assignmentTaskId')
      ? undefined
      : json['assignmentTaskId'],
  }
}

export function StudentLessonProgressionRequestToJSON(
  value?: StudentLessonProgressionRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    eventId: value.eventId,
    lessonStepId: value.lessonStepId,
    onboardingStepId: value.onboardingStepId,
    assignmentId: value.assignmentId,
    assignmentTaskId: value.assignmentTaskId,
  }
}
