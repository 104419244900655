// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SpellingByStudentIssueDateResponseDto
 */
export interface SpellingByStudentIssueDateResponseDto {
  /**
   * The spelling issue id
   * @type {number}
   * @memberof SpellingByStudentIssueDateResponseDto
   */
  spellingIssueId?: number | null
  /**
   * The spelling issue
   * @type {string}
   * @memberof SpellingByStudentIssueDateResponseDto
   */
  spellingIssue?: string | null
  /**
   * The total number of incorrect spelling
   * @type {number}
   * @memberof SpellingByStudentIssueDateResponseDto
   */
  totalIncorrect?: number | null
  /**
   * The total number of written words
   * @type {number}
   * @memberof SpellingByStudentIssueDateResponseDto
   */
  totalWritten?: number | null
  /**
   * The error rate percentage
   * @type {number}
   * @memberof SpellingByStudentIssueDateResponseDto
   */
  errorRate?: number | null
}

/**
 * Check if a given object implements the SpellingByStudentIssueDateResponseDto interface.
 */
export function instanceOfSpellingByStudentIssueDateResponseDto(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function SpellingByStudentIssueDateResponseDtoFromJSON(
  json: any,
): SpellingByStudentIssueDateResponseDto {
  return SpellingByStudentIssueDateResponseDtoFromJSONTyped(json, false)
}

export function SpellingByStudentIssueDateResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SpellingByStudentIssueDateResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    spellingIssueId: !exists(json, 'spellingIssueId')
      ? undefined
      : json['spellingIssueId'],
    spellingIssue: !exists(json, 'spellingIssue')
      ? undefined
      : json['spellingIssue'],
    totalIncorrect: !exists(json, 'totalIncorrect')
      ? undefined
      : json['totalIncorrect'],
    totalWritten: !exists(json, 'totalWritten')
      ? undefined
      : json['totalWritten'],
    errorRate: !exists(json, 'errorRate') ? undefined : json['errorRate'],
  }
}

export function SpellingByStudentIssueDateResponseDtoToJSON(
  value?: SpellingByStudentIssueDateResponseDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    spellingIssueId: value.spellingIssueId,
    spellingIssue: value.spellingIssue,
    totalIncorrect: value.totalIncorrect,
    totalWritten: value.totalWritten,
    errorRate: value.errorRate,
  }
}
