// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { TimeOnTaskModel } from '../models/index'
import { TimeOnTaskModelFromJSON, TimeOnTaskModelToJSON } from '../models/index'

export interface ApiV1TimeontaskUserIdPostRequest {
  userId: string
  timeOnTaskModel?: TimeOnTaskModel
}

/**
 *
 */
export class TimeOnTaskApi extends runtime.BaseAPI {
  /**
   * Time on task event (Auth policies: StudentTokenRoleRequirement, UserResourceRequirement) (Auth policies: StudentTokenRoleRequirement, UserResourceRequirement)
   */
  async apiV1TimeontaskUserIdPostRaw(
    requestParameters: ApiV1TimeontaskUserIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling apiV1TimeontaskUserIdPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/timeontask/{userId}`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TimeOnTaskModelToJSON(requestParameters.timeOnTaskModel),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Time on task event (Auth policies: StudentTokenRoleRequirement, UserResourceRequirement) (Auth policies: StudentTokenRoleRequirement, UserResourceRequirement)
   */
  async apiV1TimeontaskUserIdPost(
    requestParameters: ApiV1TimeontaskUserIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.apiV1TimeontaskUserIdPostRaw(requestParameters, initOverrides)
  }
}
