// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StudentLessonResultRepresentationV2 } from './StudentLessonResultRepresentationV2'
import {
  StudentLessonResultRepresentationV2FromJSON,
  StudentLessonResultRepresentationV2FromJSONTyped,
  StudentLessonResultRepresentationV2ToJSON,
} from './StudentLessonResultRepresentationV2'

/**
 *
 * @export
 * @interface StudentLessonResultsRepresentationV2
 */
export interface StudentLessonResultsRepresentationV2 {
  /**
   *
   * @type {number}
   * @memberof StudentLessonResultsRepresentationV2
   */
  totalItems: number
  /**
   *
   * @type {Array<StudentLessonResultRepresentationV2>}
   * @memberof StudentLessonResultsRepresentationV2
   */
  readonly items: Array<StudentLessonResultRepresentationV2>
}

/**
 * Check if a given object implements the StudentLessonResultsRepresentationV2 interface.
 */
export function instanceOfStudentLessonResultsRepresentationV2(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'totalItems' in value
  isInstance = isInstance && 'items' in value

  return isInstance
}

export function StudentLessonResultsRepresentationV2FromJSON(
  json: any,
): StudentLessonResultsRepresentationV2 {
  return StudentLessonResultsRepresentationV2FromJSONTyped(json, false)
}

export function StudentLessonResultsRepresentationV2FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentLessonResultsRepresentationV2 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    totalItems: json['total_items'],
    items: (json['items'] as Array<any>).map(
      StudentLessonResultRepresentationV2FromJSON,
    ),
  }
}

export function StudentLessonResultsRepresentationV2ToJSON(
  value?: StudentLessonResultsRepresentationV2 | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    total_items: value.totalItems,
  }
}
