// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { QuizResultResponseRepresentationData } from './QuizResultResponseRepresentationData'
import {
  QuizResultResponseRepresentationDataFromJSON,
  QuizResultResponseRepresentationDataFromJSONTyped,
  QuizResultResponseRepresentationDataToJSON,
} from './QuizResultResponseRepresentationData'

/**
 *
 * @export
 * @interface QuizResultResponseRepresentation
 */
export interface QuizResultResponseRepresentation {
  /**
   *
   * @type {QuizResultResponseRepresentationData}
   * @memberof QuizResultResponseRepresentation
   */
  data: QuizResultResponseRepresentationData
}

/**
 * Check if a given object implements the QuizResultResponseRepresentation interface.
 */
export function instanceOfQuizResultResponseRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value

  return isInstance
}

export function QuizResultResponseRepresentationFromJSON(
  json: any,
): QuizResultResponseRepresentation {
  return QuizResultResponseRepresentationFromJSONTyped(json, false)
}

export function QuizResultResponseRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): QuizResultResponseRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: QuizResultResponseRepresentationDataFromJSON(json['data']),
  }
}

export function QuizResultResponseRepresentationToJSON(
  value?: QuizResultResponseRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: QuizResultResponseRepresentationDataToJSON(value.data),
  }
}
