// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { RealmItemRepresentation } from './RealmItemRepresentation'
import {
  RealmItemRepresentationFromJSON,
  RealmItemRepresentationFromJSONTyped,
  RealmItemRepresentationToJSON,
} from './RealmItemRepresentation'

/**
 *
 * @export
 * @interface RealmItemRepresentationListBaseApiResult
 */
export interface RealmItemRepresentationListBaseApiResult {
  /**
   *
   * @type {Array<RealmItemRepresentation>}
   * @memberof RealmItemRepresentationListBaseApiResult
   */
  data?: Array<RealmItemRepresentation> | null
}

/**
 * Check if a given object implements the RealmItemRepresentationListBaseApiResult interface.
 */
export function instanceOfRealmItemRepresentationListBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function RealmItemRepresentationListBaseApiResultFromJSON(
  json: any,
): RealmItemRepresentationListBaseApiResult {
  return RealmItemRepresentationListBaseApiResultFromJSONTyped(json, false)
}

export function RealmItemRepresentationListBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RealmItemRepresentationListBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : json['data'] === null
        ? null
        : (json['data'] as Array<any>).map(RealmItemRepresentationFromJSON),
  }
}

export function RealmItemRepresentationListBaseApiResultToJSON(
  value?: RealmItemRepresentationListBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
          ? null
          : (value.data as Array<any>).map(RealmItemRepresentationToJSON),
  }
}
