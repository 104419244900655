// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StudentLessonWritingRepresentation } from './StudentLessonWritingRepresentation'
import {
  StudentLessonWritingRepresentationFromJSON,
  StudentLessonWritingRepresentationFromJSONTyped,
  StudentLessonWritingRepresentationToJSON,
} from './StudentLessonWritingRepresentation'

/**
 *
 * @export
 * @interface StudentLessonWritingRepresentationBaseApiListResult
 */
export interface StudentLessonWritingRepresentationBaseApiListResult {
  /**
   *
   * @type {Array<StudentLessonWritingRepresentation>}
   * @memberof StudentLessonWritingRepresentationBaseApiListResult
   */
  data: Array<StudentLessonWritingRepresentation>
  /**
   *
   * @type {number}
   * @memberof StudentLessonWritingRepresentationBaseApiListResult
   */
  total: number
}

/**
 * Check if a given object implements the StudentLessonWritingRepresentationBaseApiListResult interface.
 */
export function instanceOfStudentLessonWritingRepresentationBaseApiListResult(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'total' in value

  return isInstance
}

export function StudentLessonWritingRepresentationBaseApiListResultFromJSON(
  json: any,
): StudentLessonWritingRepresentationBaseApiListResult {
  return StudentLessonWritingRepresentationBaseApiListResultFromJSONTyped(
    json,
    false,
  )
}

export function StudentLessonWritingRepresentationBaseApiListResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentLessonWritingRepresentationBaseApiListResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(
      StudentLessonWritingRepresentationFromJSON,
    ),
    total: json['total'],
  }
}

export function StudentLessonWritingRepresentationBaseApiListResultToJSON(
  value?: StudentLessonWritingRepresentationBaseApiListResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(
      StudentLessonWritingRepresentationToJSON,
    ),
    total: value.total,
  }
}
