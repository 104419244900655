// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TextStatsRequest
 */
export interface TextStatsRequest {
  /**
   *
   * @type {string}
   * @memberof TextStatsRequest
   */
  transactionId?: string
  /**
   *
   * @type {string}
   * @memberof TextStatsRequest
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof TextStatsRequest
   */
  language?: string
}

/**
 * Check if a given object implements the TextStatsRequest interface.
 */
export function instanceOfTextStatsRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'text' in value

  return isInstance
}

export function TextStatsRequestFromJSON(json: any): TextStatsRequest {
  return TextStatsRequestFromJSONTyped(json, false)
}

export function TextStatsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TextStatsRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    transactionId: !exists(json, 'transaction_id')
      ? undefined
      : json['transaction_id'],
    text: json['text'],
    language: !exists(json, 'language') ? undefined : json['language'],
  }
}

export function TextStatsRequestToJSON(value?: TextStatsRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    transaction_id: value.transactionId,
    text: value.text,
    language: value.language,
  }
}
