// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface EwiPointsResponse
 */
export interface EwiPointsResponse {
  /**
   *
   * @type {number}
   * @memberof EwiPointsResponse
   */
  wordCount: number
  /**
   *
   * @type {number}
   * @memberof EwiPointsResponse
   */
  extraWords: number
  /**
   *
   * @type {number}
   * @memberof EwiPointsResponse
   */
  wordVariety: number
  /**
   *
   * @type {number}
   * @memberof EwiPointsResponse
   */
  sentenceLengthVariety: number
  /**
   *
   * @type {number}
   * @memberof EwiPointsResponse
   */
  languageGems: number
  /**
   *
   * @type {number}
   * @memberof EwiPointsResponse
   */
  total: number
}

/**
 * Check if a given object implements the EwiPointsResponse interface.
 */
export function instanceOfEwiPointsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'wordCount' in value
  isInstance = isInstance && 'extraWords' in value
  isInstance = isInstance && 'wordVariety' in value
  isInstance = isInstance && 'sentenceLengthVariety' in value
  isInstance = isInstance && 'languageGems' in value
  isInstance = isInstance && 'total' in value

  return isInstance
}

export function EwiPointsResponseFromJSON(json: any): EwiPointsResponse {
  return EwiPointsResponseFromJSONTyped(json, false)
}

export function EwiPointsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EwiPointsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    wordCount: json['word_count'],
    extraWords: json['extra_words'],
    wordVariety: json['word_variety'],
    sentenceLengthVariety: json['sentence_length_variety'],
    languageGems: json['language_gems'],
    total: json['total'],
  }
}

export function EwiPointsResponseToJSON(value?: EwiPointsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    word_count: value.wordCount,
    extra_words: value.extraWords,
    word_variety: value.wordVariety,
    sentence_length_variety: value.sentenceLengthVariety,
    language_gems: value.languageGems,
    total: value.total,
  }
}
