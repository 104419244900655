// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AvatarRepresentation
 */
export interface AvatarRepresentation {
  /**
   *
   * @type {string}
   * @memberof AvatarRepresentation
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof AvatarRepresentation
   */
  price?: number
}

/**
 * Check if a given object implements the AvatarRepresentation interface.
 */
export function instanceOfAvatarRepresentation(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function AvatarRepresentationFromJSON(json: any): AvatarRepresentation {
  return AvatarRepresentationFromJSONTyped(json, false)
}

export function AvatarRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AvatarRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    price: !exists(json, 'price') ? undefined : json['price'],
  }
}

export function AvatarRepresentationToJSON(
  value?: AvatarRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    price: value.price,
  }
}
