// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const WritingMood = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
} as const
export type WritingMood = (typeof WritingMood)[keyof typeof WritingMood]

export function WritingMoodFromJSON(json: any): WritingMood {
  return WritingMoodFromJSONTyped(json, false)
}

export function WritingMoodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WritingMood {
  return json as WritingMood
}

export function WritingMoodToJSON(value?: WritingMood | null): any {
  return value as any
}
