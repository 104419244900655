// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BookBlurbBook } from './BookBlurbBook'
import {
  BookBlurbBookFromJSON,
  BookBlurbBookFromJSONTyped,
  BookBlurbBookToJSON,
} from './BookBlurbBook'
import type { WowWordCountRepresentation } from './WowWordCountRepresentation'
import {
  WowWordCountRepresentationFromJSON,
  WowWordCountRepresentationFromJSONTyped,
  WowWordCountRepresentationToJSON,
} from './WowWordCountRepresentation'

/**
 *
 * @export
 * @interface ClassroomWowWordCountRepresentation
 */
export interface ClassroomWowWordCountRepresentation {
  /**
   *
   * @type {string}
   * @memberof ClassroomWowWordCountRepresentation
   */
  classroomId?: string
  /**
   *
   * @type {Array<WowWordCountRepresentation>}
   * @memberof ClassroomWowWordCountRepresentation
   */
  wowWords?: Array<WowWordCountRepresentation> | null
  /**
   *
   * @type {Array<BookBlurbBook>}
   * @memberof ClassroomWowWordCountRepresentation
   */
  classroomBooks?: Array<BookBlurbBook> | null
}

/**
 * Check if a given object implements the ClassroomWowWordCountRepresentation interface.
 */
export function instanceOfClassroomWowWordCountRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ClassroomWowWordCountRepresentationFromJSON(
  json: any,
): ClassroomWowWordCountRepresentation {
  return ClassroomWowWordCountRepresentationFromJSONTyped(json, false)
}

export function ClassroomWowWordCountRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ClassroomWowWordCountRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    classroomId: !exists(json, 'classroom_id')
      ? undefined
      : json['classroom_id'],
    wowWords: !exists(json, 'wow_words')
      ? undefined
      : json['wow_words'] === null
        ? null
        : (json['wow_words'] as Array<any>).map(
            WowWordCountRepresentationFromJSON,
          ),
    classroomBooks: !exists(json, 'classroom_books')
      ? undefined
      : json['classroom_books'] === null
        ? null
        : (json['classroom_books'] as Array<any>).map(BookBlurbBookFromJSON),
  }
}

export function ClassroomWowWordCountRepresentationToJSON(
  value?: ClassroomWowWordCountRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    classroom_id: value.classroomId,
    wow_words:
      value.wowWords === undefined
        ? undefined
        : value.wowWords === null
          ? null
          : (value.wowWords as Array<any>).map(
              WowWordCountRepresentationToJSON,
            ),
    classroom_books:
      value.classroomBooks === undefined
        ? undefined
        : value.classroomBooks === null
          ? null
          : (value.classroomBooks as Array<any>).map(BookBlurbBookToJSON),
  }
}
