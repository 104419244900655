// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { TeacherRepresentation } from './TeacherRepresentation'
import {
  TeacherRepresentationFromJSON,
  TeacherRepresentationFromJSONTyped,
  TeacherRepresentationToJSON,
} from './TeacherRepresentation'

/**
 *
 * @export
 * @interface TeacherRepresentationListBaseApiResult
 */
export interface TeacherRepresentationListBaseApiResult {
  /**
   *
   * @type {Array<TeacherRepresentation>}
   * @memberof TeacherRepresentationListBaseApiResult
   */
  data?: Array<TeacherRepresentation> | null
}

/**
 * Check if a given object implements the TeacherRepresentationListBaseApiResult interface.
 */
export function instanceOfTeacherRepresentationListBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function TeacherRepresentationListBaseApiResultFromJSON(
  json: any,
): TeacherRepresentationListBaseApiResult {
  return TeacherRepresentationListBaseApiResultFromJSONTyped(json, false)
}

export function TeacherRepresentationListBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TeacherRepresentationListBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : json['data'] === null
        ? null
        : (json['data'] as Array<any>).map(TeacherRepresentationFromJSON),
  }
}

export function TeacherRepresentationListBaseApiResultToJSON(
  value?: TeacherRepresentationListBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
          ? null
          : (value.data as Array<any>).map(TeacherRepresentationToJSON),
  }
}
