// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ActivityCatalogueItem
 */
export interface ActivityCatalogueItem {
  /**
   *
   * @type {string}
   * @memberof ActivityCatalogueItem
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof ActivityCatalogueItem
   */
  title?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityCatalogueItem
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityCatalogueItem
   */
  thumbnail?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityCatalogueItem
   */
  template?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof ActivityCatalogueItem
   */
  readonly tags: Array<string>
  /**
   *
   * @type {number}
   * @memberof ActivityCatalogueItem
   */
  ranking?: number
}

/**
 * Check if a given object implements the ActivityCatalogueItem interface.
 */
export function instanceOfActivityCatalogueItem(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'tags' in value

  return isInstance
}

export function ActivityCatalogueItemFromJSON(
  json: any,
): ActivityCatalogueItem {
  return ActivityCatalogueItemFromJSONTyped(json, false)
}

export function ActivityCatalogueItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityCatalogueItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityId: json['activity_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    thumbnail: !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
    template: !exists(json, 'template') ? undefined : json['template'],
    tags: json['tags'],
    ranking: !exists(json, 'ranking') ? undefined : json['ranking'],
  }
}

export function ActivityCatalogueItemToJSON(
  value?: ActivityCatalogueItem | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_id: value.activityId,
    title: value.title,
    description: value.description,
    thumbnail: value.thumbnail,
    template: value.template,
    ranking: value.ranking,
  }
}
