// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TermLogResponse
 */
export interface TermLogResponse {
  /**
   *
   * @type {string}
   * @memberof TermLogResponse
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TermLogResponse
   */
  term: string
  /**
   *
   * @type {string}
   * @memberof TermLogResponse
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof TermLogResponse
   */
  lemma: string
  /**
   *
   * @type {number}
   * @memberof TermLogResponse
   */
  i: number
  /**
   *
   * @type {number}
   * @memberof TermLogResponse
   */
  idx: number
  /**
   *
   * @type {string}
   * @memberof TermLogResponse
   */
  pos?: string
  /**
   *
   * @type {string}
   * @memberof TermLogResponse
   */
  entType?: string
  /**
   *
   * @type {boolean}
   * @memberof TermLogResponse
   */
  isLanguageGem?: boolean
  /**
   *
   * @type {boolean}
   * @memberof TermLogResponse
   */
  isMisspelling?: boolean
  /**
   *
   * @type {boolean}
   * @memberof TermLogResponse
   */
  isPhrase?: boolean
  /**
   *
   * @type {boolean}
   * @memberof TermLogResponse
   */
  isDeleted?: boolean
  /**
   *
   * @type {string}
   * @memberof TermLogResponse
   */
  editedFromId?: string
}

/**
 * Check if a given object implements the TermLogResponse interface.
 */
export function instanceOfTermLogResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'term' in value
  isInstance = isInstance && 'text' in value
  isInstance = isInstance && 'lemma' in value
  isInstance = isInstance && 'i' in value
  isInstance = isInstance && 'idx' in value

  return isInstance
}

export function TermLogResponseFromJSON(json: any): TermLogResponse {
  return TermLogResponseFromJSONTyped(json, false)
}

export function TermLogResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TermLogResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    term: json['term'],
    text: json['text'],
    lemma: json['lemma'],
    i: json['i'],
    idx: json['idx'],
    pos: !exists(json, 'pos') ? undefined : json['pos'],
    entType: !exists(json, 'ent_type') ? undefined : json['ent_type'],
    isLanguageGem: !exists(json, 'is_language_gem')
      ? undefined
      : json['is_language_gem'],
    isMisspelling: !exists(json, 'is_misspelling')
      ? undefined
      : json['is_misspelling'],
    isPhrase: !exists(json, 'is_phrase') ? undefined : json['is_phrase'],
    isDeleted: !exists(json, 'is_deleted') ? undefined : json['is_deleted'],
    editedFromId: !exists(json, 'edited_from_id')
      ? undefined
      : json['edited_from_id'],
  }
}

export function TermLogResponseToJSON(value?: TermLogResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    term: value.term,
    text: value.text,
    lemma: value.lemma,
    i: value.i,
    idx: value.idx,
    pos: value.pos,
    ent_type: value.entType,
    is_language_gem: value.isLanguageGem,
    is_misspelling: value.isMisspelling,
    is_phrase: value.isPhrase,
    is_deleted: value.isDeleted,
    edited_from_id: value.editedFromId,
  }
}
