// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetStudentResultTotalsRepresentation } from './GetStudentResultTotalsRepresentation'
import {
  GetStudentResultTotalsRepresentationFromJSON,
  GetStudentResultTotalsRepresentationFromJSONTyped,
  GetStudentResultTotalsRepresentationToJSON,
} from './GetStudentResultTotalsRepresentation'

/**
 *
 * @export
 * @interface GetStudentResultTotalsRepresentationBaseApiResult
 */
export interface GetStudentResultTotalsRepresentationBaseApiResult {
  /**
   *
   * @type {GetStudentResultTotalsRepresentation}
   * @memberof GetStudentResultTotalsRepresentationBaseApiResult
   */
  data?: GetStudentResultTotalsRepresentation
}

/**
 * Check if a given object implements the GetStudentResultTotalsRepresentationBaseApiResult interface.
 */
export function instanceOfGetStudentResultTotalsRepresentationBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function GetStudentResultTotalsRepresentationBaseApiResultFromJSON(
  json: any,
): GetStudentResultTotalsRepresentationBaseApiResult {
  return GetStudentResultTotalsRepresentationBaseApiResultFromJSONTyped(
    json,
    false,
  )
}

export function GetStudentResultTotalsRepresentationBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetStudentResultTotalsRepresentationBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : GetStudentResultTotalsRepresentationFromJSON(json['data']),
  }
}

export function GetStudentResultTotalsRepresentationBaseApiResultToJSON(
  value?: GetStudentResultTotalsRepresentationBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: GetStudentResultTotalsRepresentationToJSON(value.data),
  }
}
