// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  ActivityDataRepresentationBaseApiListResult,
  ActivityDataRepresentationBaseApiResult,
} from '../models/index'
import {
  ActivityDataRepresentationBaseApiListResultFromJSON,
  ActivityDataRepresentationBaseApiListResultToJSON,
  ActivityDataRepresentationBaseApiResultFromJSON,
  ActivityDataRepresentationBaseApiResultToJSON,
} from '../models/index'

export interface ApiV1ActivitiesActivityIdGetRequest {
  activityId: string
}

export interface ApiV1ActivitiesGetRequest {
  createdBy?: string
  activityIds?: Array<string>
  templates?: string
  tags?: string
  terms?: string
  includeUsers?: boolean
  includeBlocks?: boolean
  skip?: number
  take?: number
}

/**
 *
 */
export class ActivitiesApi extends runtime.BaseAPI {
  /**
   * Get one Activity (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1ActivitiesActivityIdGetRaw(
    requestParameters: ApiV1ActivitiesActivityIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ActivityDataRepresentationBaseApiResult>> {
    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1ActivitiesActivityIdGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/activities/{activityId}`.replace(
          `{${'activityId'}}`,
          encodeURIComponent(String(requestParameters.activityId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityDataRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   * Get one Activity (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1ActivitiesActivityIdGet(
    requestParameters: ApiV1ActivitiesActivityIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityDataRepresentationBaseApiResult> {
    const response = await this.apiV1ActivitiesActivityIdGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Get Activities with Blocks (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1ActivitiesGetRaw(
    requestParameters: ApiV1ActivitiesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ActivityDataRepresentationBaseApiListResult>> {
    const queryParameters: any = {}

    if (requestParameters.createdBy !== undefined) {
      queryParameters['createdBy'] = requestParameters.createdBy
    }

    if (requestParameters.activityIds) {
      queryParameters['activityIds'] = requestParameters.activityIds
    }

    if (requestParameters.templates !== undefined) {
      queryParameters['templates'] = requestParameters.templates
    }

    if (requestParameters.tags !== undefined) {
      queryParameters['tags'] = requestParameters.tags
    }

    if (requestParameters.terms !== undefined) {
      queryParameters['terms'] = requestParameters.terms
    }

    if (requestParameters.includeUsers !== undefined) {
      queryParameters['includeUsers'] = requestParameters.includeUsers
    }

    if (requestParameters.includeBlocks !== undefined) {
      queryParameters['includeBlocks'] = requestParameters.includeBlocks
    }

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/activities`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityDataRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   * Get Activities with Blocks (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1ActivitiesGet(
    requestParameters: ApiV1ActivitiesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityDataRepresentationBaseApiListResult> {
    const response = await this.apiV1ActivitiesGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
