// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TimeOnTaskModel
 */
export interface TimeOnTaskModel {
  /**
   *
   * @type {string}
   * @memberof TimeOnTaskModel
   */
  taskName: string
}

/**
 * Check if a given object implements the TimeOnTaskModel interface.
 */
export function instanceOfTimeOnTaskModel(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'taskName' in value

  return isInstance
}

export function TimeOnTaskModelFromJSON(json: any): TimeOnTaskModel {
  return TimeOnTaskModelFromJSONTyped(json, false)
}

export function TimeOnTaskModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TimeOnTaskModel {
  if (json === undefined || json === null) {
    return json
  }
  return {
    taskName: json['taskName'],
  }
}

export function TimeOnTaskModelToJSON(value?: TimeOnTaskModel | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    taskName: value.taskName,
  }
}
