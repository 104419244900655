// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FeedbackStatus } from './FeedbackStatus'
import {
  FeedbackStatusFromJSON,
  FeedbackStatusFromJSONTyped,
  FeedbackStatusToJSON,
} from './FeedbackStatus'

/**
 *
 * @export
 * @interface ActivityFeedbackRepresentation
 */
export interface ActivityFeedbackRepresentation {
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackRepresentation
   */
  feedbackId: string
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackRepresentation
   */
  studentId: string
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackRepresentation
   */
  resultId: string
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackRepresentation
   */
  activityResultSnapshotId: string
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackRepresentation
   */
  answerId?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackRepresentation
   */
  sticker?: string | null
  /**
   *
   * @type {FeedbackStatus}
   * @memberof ActivityFeedbackRepresentation
   */
  status?: FeedbackStatus
  /**
   *
   * @type {boolean}
   * @memberof ActivityFeedbackRepresentation
   */
  isSeen?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ActivityFeedbackRepresentation
   */
  isDeleted?: boolean
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackRepresentation
   */
  feedbackText?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityFeedbackRepresentation
   */
  timeTaken?: number | null
  /**
   *
   * @type {number}
   * @memberof ActivityFeedbackRepresentation
   */
  mark?: number | null
  /**
   *
   * @type {Date}
   * @memberof ActivityFeedbackRepresentation
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof ActivityFeedbackRepresentation
   */
  updatedDate?: Date | null
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackRepresentation
   */
  teacherFullName?: string | null
}

/**
 * Check if a given object implements the ActivityFeedbackRepresentation interface.
 */
export function instanceOfActivityFeedbackRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'feedbackId' in value
  isInstance = isInstance && 'studentId' in value
  isInstance = isInstance && 'resultId' in value
  isInstance = isInstance && 'activityResultSnapshotId' in value

  return isInstance
}

export function ActivityFeedbackRepresentationFromJSON(
  json: any,
): ActivityFeedbackRepresentation {
  return ActivityFeedbackRepresentationFromJSONTyped(json, false)
}

export function ActivityFeedbackRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityFeedbackRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    feedbackId: json['feedback_id'],
    studentId: json['student_id'],
    resultId: json['result_id'],
    activityResultSnapshotId: json['activity_result_snapshot_id'],
    answerId: !exists(json, 'answer_id') ? undefined : json['answer_id'],
    sticker: !exists(json, 'sticker') ? undefined : json['sticker'],
    status: !exists(json, 'status')
      ? undefined
      : FeedbackStatusFromJSON(json['status']),
    isSeen: !exists(json, 'is_seen') ? undefined : json['is_seen'],
    isDeleted: !exists(json, 'is_deleted') ? undefined : json['is_deleted'],
    feedbackText: !exists(json, 'feedback_text')
      ? undefined
      : json['feedback_text'],
    timeTaken: !exists(json, 'time_taken') ? undefined : json['time_taken'],
    mark: !exists(json, 'mark') ? undefined : json['mark'],
    createdDate: !exists(json, 'created_date')
      ? undefined
      : new Date(json['created_date']),
    updatedDate: !exists(json, 'updated_date')
      ? undefined
      : json['updated_date'] === null
        ? null
        : new Date(json['updated_date']),
    teacherFullName: !exists(json, 'teacher_full_name')
      ? undefined
      : json['teacher_full_name'],
  }
}

export function ActivityFeedbackRepresentationToJSON(
  value?: ActivityFeedbackRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    feedback_id: value.feedbackId,
    student_id: value.studentId,
    result_id: value.resultId,
    activity_result_snapshot_id: value.activityResultSnapshotId,
    answer_id: value.answerId,
    sticker: value.sticker,
    status: FeedbackStatusToJSON(value.status),
    is_seen: value.isSeen,
    is_deleted: value.isDeleted,
    feedback_text: value.feedbackText,
    time_taken: value.timeTaken,
    mark: value.mark,
    created_date:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString(),
    updated_date:
      value.updatedDate === undefined
        ? undefined
        : value.updatedDate === null
          ? null
          : value.updatedDate.toISOString(),
    teacher_full_name: value.teacherFullName,
  }
}
