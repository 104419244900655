// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  AnalyzeTextResponse,
  EwiDataRequest,
  HTTPValidationError,
  LegendFeedbacksResponse,
  TermLogResponse,
  TextStatsRequest,
  TextStatsResponse,
  VerifyTextResponse,
} from '../models/index'
import {
  AnalyzeTextResponseFromJSON,
  AnalyzeTextResponseToJSON,
  EwiDataRequestFromJSON,
  EwiDataRequestToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LegendFeedbacksResponseFromJSON,
  LegendFeedbacksResponseToJSON,
  TermLogResponseFromJSON,
  TermLogResponseToJSON,
  TextStatsRequestFromJSON,
  TextStatsRequestToJSON,
  TextStatsResponseFromJSON,
  TextStatsResponseToJSON,
  VerifyTextResponseFromJSON,
  VerifyTextResponseToJSON,
} from '../models/index'

export interface AnalyzeTextAnalyzeTextPostRequest {
  ewiDataRequest: EwiDataRequest
}

export interface ExtractLanguageGemsLanguageGemsPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface ExtractLanguageGemsWithTagsLanguageGemsWithTagsPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface ExtractLegendFeedbacksLegendFeedbacksPostRequest {
  ewiDataRequest: EwiDataRequest
}

export interface ExtractSentencesSentencesPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface ExtractSpaCySentencesSpaCySentencesPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface ExtractTermFrequencyTermFrequencyPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface ExtractTerrificAlternativesTerrificAlternativesPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface ExtractTextStatsTextStatsPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface ExtractTokenCountsTokenCountsPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface ExtractTokensTokensPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface NounChunksNounChunksPostRequest {
  textStatsRequest: TextStatsRequest
}

export interface VerifyTextVerifyTextPostRequest {
  textStatsRequest: TextStatsRequest
}

/**
 *
 */
export class ExcitingWritingIdeasApi extends runtime.BaseAPI {
  /**
   * Analyze Text
   */
  async analyzeTextAnalyzeTextPostRaw(
    requestParameters: AnalyzeTextAnalyzeTextPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AnalyzeTextResponse>> {
    if (
      requestParameters.ewiDataRequest === null ||
      requestParameters.ewiDataRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'ewiDataRequest',
        'Required parameter requestParameters.ewiDataRequest was null or undefined when calling analyzeTextAnalyzeTextPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/analyze_text`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EwiDataRequestToJSON(requestParameters.ewiDataRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AnalyzeTextResponseFromJSON(jsonValue),
    )
  }

  /**
   * Analyze Text
   */
  async analyzeTextAnalyzeTextPost(
    requestParameters: AnalyzeTextAnalyzeTextPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AnalyzeTextResponse> {
    const response = await this.analyzeTextAnalyzeTextPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Extract Language Gems
   */
  async extractLanguageGemsLanguageGemsPostRaw(
    requestParameters: ExtractLanguageGemsLanguageGemsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractLanguageGemsLanguageGemsPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/language_gems`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Extract Language Gems
   */
  async extractLanguageGemsLanguageGemsPost(
    requestParameters: ExtractLanguageGemsLanguageGemsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<string>> {
    const response = await this.extractLanguageGemsLanguageGemsPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Extract Language Gems With Tags
   */
  async extractLanguageGemsWithTagsLanguageGemsWithTagsPostRaw(
    requestParameters: ExtractLanguageGemsWithTagsLanguageGemsWithTagsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: Array<string> }>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractLanguageGemsWithTagsLanguageGemsWithTagsPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/language_gems_with_tags`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Extract Language Gems With Tags
   */
  async extractLanguageGemsWithTagsLanguageGemsWithTagsPost(
    requestParameters: ExtractLanguageGemsWithTagsLanguageGemsWithTagsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: Array<string> }> {
    const response =
      await this.extractLanguageGemsWithTagsLanguageGemsWithTagsPostRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Extract Legend Feedbacks
   */
  async extractLegendFeedbacksLegendFeedbacksPostRaw(
    requestParameters: ExtractLegendFeedbacksLegendFeedbacksPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LegendFeedbacksResponse>> {
    if (
      requestParameters.ewiDataRequest === null ||
      requestParameters.ewiDataRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'ewiDataRequest',
        'Required parameter requestParameters.ewiDataRequest was null or undefined when calling extractLegendFeedbacksLegendFeedbacksPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/legend_feedbacks`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EwiDataRequestToJSON(requestParameters.ewiDataRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LegendFeedbacksResponseFromJSON(jsonValue),
    )
  }

  /**
   * Extract Legend Feedbacks
   */
  async extractLegendFeedbacksLegendFeedbacksPost(
    requestParameters: ExtractLegendFeedbacksLegendFeedbacksPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LegendFeedbacksResponse> {
    const response = await this.extractLegendFeedbacksLegendFeedbacksPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Extract Sentences
   */
  async extractSentencesSentencesPostRaw(
    requestParameters: ExtractSentencesSentencesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractSentencesSentencesPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/sentences`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Extract Sentences
   */
  async extractSentencesSentencesPost(
    requestParameters: ExtractSentencesSentencesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<string>> {
    const response = await this.extractSentencesSentencesPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Extract Spacy Sentences
   */
  async extractSpaCySentencesSpaCySentencesPostRaw(
    requestParameters: ExtractSpaCySentencesSpaCySentencesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractSpaCySentencesSpaCySentencesPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/spaCy_sentences`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Extract Spacy Sentences
   */
  async extractSpaCySentencesSpaCySentencesPost(
    requestParameters: ExtractSpaCySentencesSpaCySentencesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<string>> {
    const response = await this.extractSpaCySentencesSpaCySentencesPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Extract Term Frequency
   */
  async extractTermFrequencyTermFrequencyPostRaw(
    requestParameters: ExtractTermFrequencyTermFrequencyPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: number }>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractTermFrequencyTermFrequencyPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/term_frequency`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Extract Term Frequency
   */
  async extractTermFrequencyTermFrequencyPost(
    requestParameters: ExtractTermFrequencyTermFrequencyPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: number }> {
    const response = await this.extractTermFrequencyTermFrequencyPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Extract Terrific Alternatives
   */
  async extractTerrificAlternativesTerrificAlternativesPostRaw(
    requestParameters: ExtractTerrificAlternativesTerrificAlternativesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: Array<string> }>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractTerrificAlternativesTerrificAlternativesPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/terrific_alternatives`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Extract Terrific Alternatives
   */
  async extractTerrificAlternativesTerrificAlternativesPost(
    requestParameters: ExtractTerrificAlternativesTerrificAlternativesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: Array<string> }> {
    const response =
      await this.extractTerrificAlternativesTerrificAlternativesPostRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Extract Text Stats
   */
  async extractTextStatsTextStatsPostRaw(
    requestParameters: ExtractTextStatsTextStatsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TextStatsResponse>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractTextStatsTextStatsPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/text_stats`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TextStatsResponseFromJSON(jsonValue),
    )
  }

  /**
   * Extract Text Stats
   */
  async extractTextStatsTextStatsPost(
    requestParameters: ExtractTextStatsTextStatsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TextStatsResponse> {
    const response = await this.extractTextStatsTextStatsPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Extract Token Counts
   */
  async extractTokenCountsTokenCountsPostRaw(
    requestParameters: ExtractTokenCountsTokenCountsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: number }>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractTokenCountsTokenCountsPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/token_counts`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Extract Token Counts
   */
  async extractTokenCountsTokenCountsPost(
    requestParameters: ExtractTokenCountsTokenCountsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: number }> {
    const response = await this.extractTokenCountsTokenCountsPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Extract Tokens
   */
  async extractTokensTokensPostRaw(
    requestParameters: ExtractTokensTokensPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<object>>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling extractTokensTokensPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/tokens`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Extract Tokens
   */
  async extractTokensTokensPost(
    requestParameters: ExtractTokensTokensPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<object>> {
    const response = await this.extractTokensTokensPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Noun Chunks
   */
  async nounChunksNounChunksPostRaw(
    requestParameters: NounChunksNounChunksPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<TermLogResponse>>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling nounChunksNounChunksPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/noun_chunks`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TermLogResponseFromJSON),
    )
  }

  /**
   * Noun Chunks
   */
  async nounChunksNounChunksPost(
    requestParameters: NounChunksNounChunksPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<TermLogResponse>> {
    const response = await this.nounChunksNounChunksPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Verify Text
   */
  async verifyTextVerifyTextPostRaw(
    requestParameters: VerifyTextVerifyTextPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<VerifyTextResponse>> {
    if (
      requestParameters.textStatsRequest === null ||
      requestParameters.textStatsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'textStatsRequest',
        'Required parameter requestParameters.textStatsRequest was null or undefined when calling verifyTextVerifyTextPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/verify_text`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TextStatsRequestToJSON(requestParameters.textStatsRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      VerifyTextResponseFromJSON(jsonValue),
    )
  }

  /**
   * Verify Text
   */
  async verifyTextVerifyTextPost(
    requestParameters: VerifyTextVerifyTextPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<VerifyTextResponse> {
    const response = await this.verifyTextVerifyTextPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
