// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ActivityStatus = {
  Created: 'Created',
  InProgress: 'InProgress',
  Finished: 'Finished',
  Deleted: 'Deleted',
  ToBeReviewed: 'ToBeReviewed',
  Approved: 'Approved',
} as const
export type ActivityStatus =
  (typeof ActivityStatus)[keyof typeof ActivityStatus]

export function ActivityStatusFromJSON(json: any): ActivityStatus {
  return ActivityStatusFromJSONTyped(json, false)
}

export function ActivityStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityStatus {
  return json as ActivityStatus
}

export function ActivityStatusToJSON(value?: ActivityStatus | null): any {
  return value as any
}
