// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StudentBookBlurbRatingRepresentation } from './StudentBookBlurbRatingRepresentation'
import {
  StudentBookBlurbRatingRepresentationFromJSON,
  StudentBookBlurbRatingRepresentationFromJSONTyped,
  StudentBookBlurbRatingRepresentationToJSON,
} from './StudentBookBlurbRatingRepresentation'

/**
 *
 * @export
 * @interface ClassroomBookBlurbRatingsRepresentation
 */
export interface ClassroomBookBlurbRatingsRepresentation {
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbRatingsRepresentation
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbRatingsRepresentation
   */
  activityTitle?: string
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbRatingsRepresentation
   */
  activityThumbnail?: string
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbRatingsRepresentation
   */
  textBlockContent?: string
  /**
   *
   * @type {Array<StudentBookBlurbRatingRepresentation>}
   * @memberof ClassroomBookBlurbRatingsRepresentation
   */
  studentRatings?: Array<StudentBookBlurbRatingRepresentation> | null
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ClassroomBookBlurbRatingsRepresentation
   */
  wordCounts?: { [key: string]: number } | null
}

/**
 * Check if a given object implements the ClassroomBookBlurbRatingsRepresentation interface.
 */
export function instanceOfClassroomBookBlurbRatingsRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ClassroomBookBlurbRatingsRepresentationFromJSON(
  json: any,
): ClassroomBookBlurbRatingsRepresentation {
  return ClassroomBookBlurbRatingsRepresentationFromJSONTyped(json, false)
}

export function ClassroomBookBlurbRatingsRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ClassroomBookBlurbRatingsRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    activityTitle: !exists(json, 'activity_title')
      ? undefined
      : json['activity_title'],
    activityThumbnail: !exists(json, 'activity_thumbnail')
      ? undefined
      : json['activity_thumbnail'],
    textBlockContent: !exists(json, 'text_block_content')
      ? undefined
      : json['text_block_content'],
    studentRatings: !exists(json, 'student_ratings')
      ? undefined
      : json['student_ratings'] === null
        ? null
        : (json['student_ratings'] as Array<any>).map(
            StudentBookBlurbRatingRepresentationFromJSON,
          ),
    wordCounts: !exists(json, 'word_counts') ? undefined : json['word_counts'],
  }
}

export function ClassroomBookBlurbRatingsRepresentationToJSON(
  value?: ClassroomBookBlurbRatingsRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_id: value.activityId,
    activity_title: value.activityTitle,
    activity_thumbnail: value.activityThumbnail,
    text_block_content: value.textBlockContent,
    student_ratings:
      value.studentRatings === undefined
        ? undefined
        : value.studentRatings === null
          ? null
          : (value.studentRatings as Array<any>).map(
              StudentBookBlurbRatingRepresentationToJSON,
            ),
    word_counts: value.wordCounts,
  }
}
