// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { AvatarRepresentationBaseApiListResult } from '../models/index'
import {
  AvatarRepresentationBaseApiListResultFromJSON,
  AvatarRepresentationBaseApiListResultToJSON,
} from '../models/index'

/**
 *
 */
export class AvatarsApi extends runtime.BaseAPI {
  /**
   *  (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1AvatarsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AvatarRepresentationBaseApiListResult>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/avatars`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AvatarRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1AvatarsGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AvatarRepresentationBaseApiListResult> {
    const response = await this.apiV1AvatarsGetRaw(initOverrides)
    return await response.value()
  }
}
