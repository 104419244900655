// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateStudentAvatarModel
 */
export interface UpdateStudentAvatarModel {
  /**
   *
   * @type {string}
   * @memberof UpdateStudentAvatarModel
   */
  avatar?: string | null
}

/**
 * Check if a given object implements the UpdateStudentAvatarModel interface.
 */
export function instanceOfUpdateStudentAvatarModel(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function UpdateStudentAvatarModelFromJSON(
  json: any,
): UpdateStudentAvatarModel {
  return UpdateStudentAvatarModelFromJSONTyped(json, false)
}

export function UpdateStudentAvatarModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateStudentAvatarModel {
  if (json === undefined || json === null) {
    return json
  }
  return {
    avatar: !exists(json, 'avatar') ? undefined : json['avatar'],
  }
}

export function UpdateStudentAvatarModelToJSON(
  value?: UpdateStudentAvatarModel | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    avatar: value.avatar,
  }
}
