// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityResultDataRepresentation } from './ActivityResultDataRepresentation'
import {
  ActivityResultDataRepresentationFromJSON,
  ActivityResultDataRepresentationFromJSONTyped,
  ActivityResultDataRepresentationToJSON,
} from './ActivityResultDataRepresentation'

/**
 *
 * @export
 * @interface ActivityResultDataRepresentationBaseApiResult
 */
export interface ActivityResultDataRepresentationBaseApiResult {
  /**
   *
   * @type {ActivityResultDataRepresentation}
   * @memberof ActivityResultDataRepresentationBaseApiResult
   */
  data?: ActivityResultDataRepresentation
}

/**
 * Check if a given object implements the ActivityResultDataRepresentationBaseApiResult interface.
 */
export function instanceOfActivityResultDataRepresentationBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ActivityResultDataRepresentationBaseApiResultFromJSON(
  json: any,
): ActivityResultDataRepresentationBaseApiResult {
  return ActivityResultDataRepresentationBaseApiResultFromJSONTyped(json, false)
}

export function ActivityResultDataRepresentationBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityResultDataRepresentationBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : ActivityResultDataRepresentationFromJSON(json['data']),
  }
}

export function ActivityResultDataRepresentationBaseApiResultToJSON(
  value?: ActivityResultDataRepresentationBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: ActivityResultDataRepresentationToJSON(value.data),
  }
}
