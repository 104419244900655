// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface VerifyTextResponse
 */
export interface VerifyTextResponse {
  /**
   *
   * @type {number}
   * @memberof VerifyTextResponse
   */
  wordCount: number
  /**
   *
   * @type {number}
   * @memberof VerifyTextResponse
   */
  uniqueWordCount: number
  /**
   *
   * @type {number}
   * @memberof VerifyTextResponse
   */
  sentenceCount: number
  /**
   *
   * @type {Array<number>}
   * @memberof VerifyTextResponse
   */
  sentenceLengths: Array<number>
  /**
   *
   * @type {Array<string>}
   * @memberof VerifyTextResponse
   */
  alertWords: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof VerifyTextResponse
   */
  inappropriateWords: Array<string>
}

/**
 * Check if a given object implements the VerifyTextResponse interface.
 */
export function instanceOfVerifyTextResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'wordCount' in value
  isInstance = isInstance && 'uniqueWordCount' in value
  isInstance = isInstance && 'sentenceCount' in value
  isInstance = isInstance && 'sentenceLengths' in value
  isInstance = isInstance && 'alertWords' in value
  isInstance = isInstance && 'inappropriateWords' in value

  return isInstance
}

export function VerifyTextResponseFromJSON(json: any): VerifyTextResponse {
  return VerifyTextResponseFromJSONTyped(json, false)
}

export function VerifyTextResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): VerifyTextResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    wordCount: json['word_count'],
    uniqueWordCount: json['unique_word_count'],
    sentenceCount: json['sentence_count'],
    sentenceLengths: json['sentence_lengths'],
    alertWords: json['alert_words'],
    inappropriateWords: json['inappropriate_words'],
  }
}

export function VerifyTextResponseToJSON(
  value?: VerifyTextResponse | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    word_count: value.wordCount,
    unique_word_count: value.uniqueWordCount,
    sentence_count: value.sentenceCount,
    sentence_lengths: value.sentenceLengths,
    alert_words: value.alertWords,
    inappropriate_words: value.inappropriateWords,
  }
}
