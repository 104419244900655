// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ClassroomRepresentation } from './ClassroomRepresentation'
import {
  ClassroomRepresentationFromJSON,
  ClassroomRepresentationFromJSONTyped,
  ClassroomRepresentationToJSON,
} from './ClassroomRepresentation'
import type { Role } from './Role'
import { RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from './Role'

/**
 *
 * @export
 * @interface TeacherRepresentation
 */
export interface TeacherRepresentation {
  /**
   *
   * @type {string}
   * @memberof TeacherRepresentation
   */
  userId: string
  /**
   *
   * @type {number}
   * @memberof TeacherRepresentation
   */
  blakeId: number
  /**
   *
   * @type {string}
   * @memberof TeacherRepresentation
   */
  schoolId: string
  /**
   *
   * @type {number}
   * @memberof TeacherRepresentation
   */
  blakeSchoolId: number
  /**
   *
   * @type {string}
   * @memberof TeacherRepresentation
   */
  username: string
  /**
   *
   * @type {Role}
   * @memberof TeacherRepresentation
   */
  role: Role
  /**
   *
   * @type {string}
   * @memberof TeacherRepresentation
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof TeacherRepresentation
   */
  lastName: string
  /**
   *
   * @type {boolean}
   * @memberof TeacherRepresentation
   */
  active: boolean
  /**
   *
   * @type {string}
   * @memberof TeacherRepresentation
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof TeacherRepresentation
   */
  avatar?: string
  /**
   *
   * @type {string}
   * @memberof TeacherRepresentation
   */
  dna?: string | null
  /**
   *
   * @type {number}
   * @memberof TeacherRepresentation
   */
  gradeSetId: number
  /**
   *
   * @type {Array<ClassroomRepresentation>}
   * @memberof TeacherRepresentation
   */
  classrooms?: Array<ClassroomRepresentation> | null
}

/**
 * Check if a given object implements the TeacherRepresentation interface.
 */
export function instanceOfTeacherRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'userId' in value
  isInstance = isInstance && 'blakeId' in value
  isInstance = isInstance && 'schoolId' in value
  isInstance = isInstance && 'blakeSchoolId' in value
  isInstance = isInstance && 'username' in value
  isInstance = isInstance && 'role' in value
  isInstance = isInstance && 'firstName' in value
  isInstance = isInstance && 'lastName' in value
  isInstance = isInstance && 'active' in value
  isInstance = isInstance && 'email' in value
  isInstance = isInstance && 'gradeSetId' in value

  return isInstance
}

export function TeacherRepresentationFromJSON(
  json: any,
): TeacherRepresentation {
  return TeacherRepresentationFromJSONTyped(json, false)
}

export function TeacherRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TeacherRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userId: json['user_id'],
    blakeId: json['blakeId'],
    schoolId: json['schoolId'],
    blakeSchoolId: json['blakeSchoolId'],
    username: json['username'],
    role: RoleFromJSON(json['role']),
    firstName: json['first_name'],
    lastName: json['last_name'],
    active: json['active'],
    email: json['email'],
    avatar: !exists(json, 'avatar') ? undefined : json['avatar'],
    dna: !exists(json, 'dna') ? undefined : json['dna'],
    gradeSetId: json['gradeSetId'],
    classrooms: !exists(json, 'classrooms')
      ? undefined
      : json['classrooms'] === null
        ? null
        : (json['classrooms'] as Array<any>).map(
            ClassroomRepresentationFromJSON,
          ),
  }
}

export function TeacherRepresentationToJSON(
  value?: TeacherRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_id: value.userId,
    blakeId: value.blakeId,
    schoolId: value.schoolId,
    blakeSchoolId: value.blakeSchoolId,
    username: value.username,
    role: RoleToJSON(value.role),
    first_name: value.firstName,
    last_name: value.lastName,
    active: value.active,
    email: value.email,
    avatar: value.avatar,
    dna: value.dna,
    gradeSetId: value.gradeSetId,
    classrooms:
      value.classrooms === undefined
        ? undefined
        : value.classrooms === null
          ? null
          : (value.classrooms as Array<any>).map(ClassroomRepresentationToJSON),
  }
}
