// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityBlockRepresentation } from './ActivityBlockRepresentation'
import {
  ActivityBlockRepresentationFromJSON,
  ActivityBlockRepresentationFromJSONTyped,
  ActivityBlockRepresentationToJSON,
} from './ActivityBlockRepresentation'
import type { ActivityStatus } from './ActivityStatus'
import {
  ActivityStatusFromJSON,
  ActivityStatusFromJSONTyped,
  ActivityStatusToJSON,
} from './ActivityStatus'
import type { Role } from './Role'
import { RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from './Role'
import type { SimpleProfileRepresentation } from './SimpleProfileRepresentation'
import {
  SimpleProfileRepresentationFromJSON,
  SimpleProfileRepresentationFromJSONTyped,
  SimpleProfileRepresentationToJSON,
} from './SimpleProfileRepresentation'

/**
 *
 * @export
 * @interface ActivityDataRepresentation
 */
export interface ActivityDataRepresentation {
  /**
   *
   * @type {Array<string>}
   * @memberof ActivityDataRepresentation
   */
  readonly externalBlockIds?: Array<string> | null
  /**
   *
   * @type {Array<ActivityBlockRepresentation>}
   * @memberof ActivityDataRepresentation
   */
  blocks?: Array<ActivityBlockRepresentation> | null
  /**
   *
   * @type {SimpleProfileRepresentation}
   * @memberof ActivityDataRepresentation
   */
  createdByProfile?: SimpleProfileRepresentation
  /**
   *
   * @type {SimpleProfileRepresentation}
   * @memberof ActivityDataRepresentation
   */
  updatedByProfile?: SimpleProfileRepresentation
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  creatorNote?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  hiddenTags?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityDataRepresentation
   */
  learningTime?: number | null
  /**
   *
   * @type {ActivityStatus}
   * @memberof ActivityDataRepresentation
   */
  status?: ActivityStatus
  /**
   *
   * @type {Role}
   * @memberof ActivityDataRepresentation
   */
  creatorRole?: Role
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  createdBy?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  updatedBy?: string | null
  /**
   *
   * @type {Date}
   * @memberof ActivityDataRepresentation
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof ActivityDataRepresentation
   */
  updatedDate?: Date | null
  /**
   *
   * @type {boolean}
   * @memberof ActivityDataRepresentation
   */
  isUserCreated?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ActivityDataRepresentation
   */
  isLocked?: boolean | null
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  originalActivityId?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ActivityDataRepresentation
   */
  publishStatus?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ActivityDataRepresentation
   */
  isFeatured?: boolean | null
  /**
   *
   * @type {number}
   * @memberof ActivityDataRepresentation
   */
  targetWordCount?: number
  /**
   *
   * @type {number}
   * @memberof ActivityDataRepresentation
   */
  maxCoinAward?: number
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  title?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  thumbnail?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  template?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityDataRepresentation
   */
  tags?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityDataRepresentation
   */
  ranking?: number
}

/**
 * Check if a given object implements the ActivityDataRepresentation interface.
 */
export function instanceOfActivityDataRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'activityId' in value

  return isInstance
}

export function ActivityDataRepresentationFromJSON(
  json: any,
): ActivityDataRepresentation {
  return ActivityDataRepresentationFromJSONTyped(json, false)
}

export function ActivityDataRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityDataRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    externalBlockIds: !exists(json, 'external_block_ids')
      ? undefined
      : json['external_block_ids'],
    blocks: !exists(json, 'blocks')
      ? undefined
      : json['blocks'] === null
        ? null
        : (json['blocks'] as Array<any>).map(
            ActivityBlockRepresentationFromJSON,
          ),
    createdByProfile: !exists(json, 'created_by_profile')
      ? undefined
      : SimpleProfileRepresentationFromJSON(json['created_by_profile']),
    updatedByProfile: !exists(json, 'updated_by_profile')
      ? undefined
      : SimpleProfileRepresentationFromJSON(json['updated_by_profile']),
    creatorNote: !exists(json, 'creator_note')
      ? undefined
      : json['creator_note'],
    hiddenTags: !exists(json, 'hidden_tags') ? undefined : json['hidden_tags'],
    learningTime: !exists(json, 'learning_time')
      ? undefined
      : json['learning_time'],
    status: !exists(json, 'status')
      ? undefined
      : ActivityStatusFromJSON(json['status']),
    creatorRole: !exists(json, 'creator_role')
      ? undefined
      : RoleFromJSON(json['creator_role']),
    createdBy: !exists(json, 'created_by') ? undefined : json['created_by'],
    updatedBy: !exists(json, 'updated_by') ? undefined : json['updated_by'],
    createdDate: !exists(json, 'created_date')
      ? undefined
      : new Date(json['created_date']),
    updatedDate: !exists(json, 'updated_date')
      ? undefined
      : json['updated_date'] === null
        ? null
        : new Date(json['updated_date']),
    isUserCreated: !exists(json, 'is_user_created')
      ? undefined
      : json['is_user_created'],
    isLocked: !exists(json, 'is_locked') ? undefined : json['is_locked'],
    originalActivityId: !exists(json, 'original_activity_id')
      ? undefined
      : json['original_activity_id'],
    publishStatus: !exists(json, 'publish_status')
      ? undefined
      : json['publish_status'],
    isFeatured: !exists(json, 'is_featured') ? undefined : json['is_featured'],
    targetWordCount: !exists(json, 'target_word_count')
      ? undefined
      : json['target_word_count'],
    maxCoinAward: !exists(json, 'max_coin_award')
      ? undefined
      : json['max_coin_award'],
    activityId: json['activity_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    thumbnail: !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
    template: !exists(json, 'template') ? undefined : json['template'],
    tags: !exists(json, 'tags') ? undefined : json['tags'],
    ranking: !exists(json, 'ranking') ? undefined : json['ranking'],
  }
}

export function ActivityDataRepresentationToJSON(
  value?: ActivityDataRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    blocks:
      value.blocks === undefined
        ? undefined
        : value.blocks === null
          ? null
          : (value.blocks as Array<any>).map(ActivityBlockRepresentationToJSON),
    created_by_profile: SimpleProfileRepresentationToJSON(
      value.createdByProfile,
    ),
    updated_by_profile: SimpleProfileRepresentationToJSON(
      value.updatedByProfile,
    ),
    creator_note: value.creatorNote,
    hidden_tags: value.hiddenTags,
    learning_time: value.learningTime,
    status: ActivityStatusToJSON(value.status),
    creator_role: RoleToJSON(value.creatorRole),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    created_date:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString(),
    updated_date:
      value.updatedDate === undefined
        ? undefined
        : value.updatedDate === null
          ? null
          : value.updatedDate.toISOString(),
    is_user_created: value.isUserCreated,
    is_locked: value.isLocked,
    original_activity_id: value.originalActivityId,
    publish_status: value.publishStatus,
    is_featured: value.isFeatured,
    target_word_count: value.targetWordCount,
    max_coin_award: value.maxCoinAward,
    activity_id: value.activityId,
    title: value.title,
    description: value.description,
    thumbnail: value.thumbnail,
    template: value.template,
    tags: value.tags,
    ranking: value.ranking,
  }
}
