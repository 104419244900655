// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { WritingStatus } from './WritingStatus'
import {
  WritingStatusFromJSON,
  WritingStatusFromJSONTyped,
  WritingStatusToJSON,
} from './WritingStatus'

/**
 *
 * @export
 * @interface UpdateActivityResultFinishRepresentation
 */
export interface UpdateActivityResultFinishRepresentation {
  /**
   *
   * @type {WritingStatus}
   * @memberof UpdateActivityResultFinishRepresentation
   */
  status: WritingStatus
  /**
   *
   * @type {string}
   * @memberof UpdateActivityResultFinishRepresentation
   */
  activityResultSnapshotId: string
}

/**
 * Check if a given object implements the UpdateActivityResultFinishRepresentation interface.
 */
export function instanceOfUpdateActivityResultFinishRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'activityResultSnapshotId' in value

  return isInstance
}

export function UpdateActivityResultFinishRepresentationFromJSON(
  json: any,
): UpdateActivityResultFinishRepresentation {
  return UpdateActivityResultFinishRepresentationFromJSONTyped(json, false)
}

export function UpdateActivityResultFinishRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateActivityResultFinishRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: WritingStatusFromJSON(json['status']),
    activityResultSnapshotId: json['activityResultSnapshotId'],
  }
}

export function UpdateActivityResultFinishRepresentationToJSON(
  value?: UpdateActivityResultFinishRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: WritingStatusToJSON(value.status),
    activityResultSnapshotId: value.activityResultSnapshotId,
  }
}
