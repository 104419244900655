// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SimpleActivityRepresentation
 */
export interface SimpleActivityRepresentation {
  /**
   *
   * @type {string}
   * @memberof SimpleActivityRepresentation
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof SimpleActivityRepresentation
   */
  title?: string | null
  /**
   *
   * @type {string}
   * @memberof SimpleActivityRepresentation
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof SimpleActivityRepresentation
   */
  thumbnail?: string | null
  /**
   *
   * @type {string}
   * @memberof SimpleActivityRepresentation
   */
  template?: string | null
  /**
   *
   * @type {string}
   * @memberof SimpleActivityRepresentation
   */
  tags?: string | null
  /**
   *
   * @type {number}
   * @memberof SimpleActivityRepresentation
   */
  ranking?: number
}

/**
 * Check if a given object implements the SimpleActivityRepresentation interface.
 */
export function instanceOfSimpleActivityRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'activityId' in value

  return isInstance
}

export function SimpleActivityRepresentationFromJSON(
  json: any,
): SimpleActivityRepresentation {
  return SimpleActivityRepresentationFromJSONTyped(json, false)
}

export function SimpleActivityRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SimpleActivityRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityId: json['activity_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    thumbnail: !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
    template: !exists(json, 'template') ? undefined : json['template'],
    tags: !exists(json, 'tags') ? undefined : json['tags'],
    ranking: !exists(json, 'ranking') ? undefined : json['ranking'],
  }
}

export function SimpleActivityRepresentationToJSON(
  value?: SimpleActivityRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_id: value.activityId,
    title: value.title,
    description: value.description,
    thumbnail: value.thumbnail,
    template: value.template,
    tags: value.tags,
    ranking: value.ranking,
  }
}
