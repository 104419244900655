// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const Role = {
  Invalid: 'Invalid',
  Student: 'Student',
  Parent: 'Parent',
  Teacher: 'Teacher',
  SubCo: 'SubCo',
  DisCo: 'DisCo',
  System: 'System',
  Contributor: 'Contributor',
  Editor: 'Editor',
} as const
export type Role = (typeof Role)[keyof typeof Role]

export function RoleFromJSON(json: any): Role {
  return RoleFromJSONTyped(json, false)
}

export function RoleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Role {
  return json as Role
}

export function RoleToJSON(value?: Role | null): any {
  return value as any
}
