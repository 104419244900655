// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { WhoAmIRepresentation } from '../models/index'
import {
  WhoAmIRepresentationFromJSON,
  WhoAmIRepresentationToJSON,
} from '../models/index'

export interface ApiV1WhoamiGetRequest {
  correlationId?: string
}

/**
 *
 */
export class WhoAmIApi extends runtime.BaseAPI {
  /**
   * If the local state is older than the refresh timespan, this causes an entire state refresh
   * Returns a WhoAmI, if existing, or creates one (via Gravity API call). Designed for login use only! (Auth) (Auth)
   */
  async apiV1WhoamiGetRaw(
    requestParameters: ApiV1WhoamiGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<WhoAmIRepresentation>> {
    const queryParameters: any = {}

    if (requestParameters.correlationId !== undefined) {
      queryParameters['correlationId'] = requestParameters.correlationId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/whoami`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WhoAmIRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * If the local state is older than the refresh timespan, this causes an entire state refresh
   * Returns a WhoAmI, if existing, or creates one (via Gravity API call). Designed for login use only! (Auth) (Auth)
   */
  async apiV1WhoamiGet(
    requestParameters: ApiV1WhoamiGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WhoAmIRepresentation> {
    const response = await this.apiV1WhoamiGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
