// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StudentInventoryCategory } from './StudentInventoryCategory'
import {
  StudentInventoryCategoryFromJSON,
  StudentInventoryCategoryFromJSONTyped,
  StudentInventoryCategoryToJSON,
} from './StudentInventoryCategory'

/**
 *
 * @export
 * @interface StudentPurchasedItemRepresentation
 */
export interface StudentPurchasedItemRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentPurchasedItemRepresentation
   */
  id?: string | null
  /**
   *
   * @type {StudentInventoryCategory}
   * @memberof StudentPurchasedItemRepresentation
   */
  category?: StudentInventoryCategory
  /**
   *
   * @type {number}
   * @memberof StudentPurchasedItemRepresentation
   */
  price?: number
}

/**
 * Check if a given object implements the StudentPurchasedItemRepresentation interface.
 */
export function instanceOfStudentPurchasedItemRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function StudentPurchasedItemRepresentationFromJSON(
  json: any,
): StudentPurchasedItemRepresentation {
  return StudentPurchasedItemRepresentationFromJSONTyped(json, false)
}

export function StudentPurchasedItemRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentPurchasedItemRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    category: !exists(json, 'category')
      ? undefined
      : StudentInventoryCategoryFromJSON(json['category']),
    price: !exists(json, 'price') ? undefined : json['price'],
  }
}

export function StudentPurchasedItemRepresentationToJSON(
  value?: StudentPurchasedItemRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    category: StudentInventoryCategoryToJSON(value.category),
    price: value.price,
  }
}
