// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentLessonResultRepresentation
 */
export interface StudentLessonResultRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentLessonResultRepresentation
   */
  eventId: string
  /**
   *
   * @type {string}
   * @memberof StudentLessonResultRepresentation
   */
  studentId: string
  /**
   *
   * @type {string}
   * @memberof StudentLessonResultRepresentation
   */
  activityId: string
  /**
   *
   * @type {number}
   * @memberof StudentLessonResultRepresentation
   */
  quizAttemptCount: number
  /**
   *
   * @type {boolean}
   * @memberof StudentLessonResultRepresentation
   */
  isStepCompleted: boolean
  /**
   *
   * @type {string}
   * @memberof StudentLessonResultRepresentation
   */
  lastCompletedActivityId: string | null
}

/**
 * Check if a given object implements the StudentLessonResultRepresentation interface.
 */
export function instanceOfStudentLessonResultRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'eventId' in value
  isInstance = isInstance && 'studentId' in value
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'quizAttemptCount' in value
  isInstance = isInstance && 'isStepCompleted' in value
  isInstance = isInstance && 'lastCompletedActivityId' in value

  return isInstance
}

export function StudentLessonResultRepresentationFromJSON(
  json: any,
): StudentLessonResultRepresentation {
  return StudentLessonResultRepresentationFromJSONTyped(json, false)
}

export function StudentLessonResultRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentLessonResultRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    eventId: json['event_id'],
    studentId: json['student_id'],
    activityId: json['activity_id'],
    quizAttemptCount: json['quiz_attempt_count'],
    isStepCompleted: json['is_step_completed'],
    lastCompletedActivityId: json['last_completed_activity_id'],
  }
}

export function StudentLessonResultRepresentationToJSON(
  value?: StudentLessonResultRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    event_id: value.eventId,
    student_id: value.studentId,
    activity_id: value.activityId,
    quiz_attempt_count: value.quizAttemptCount,
    is_step_completed: value.isStepCompleted,
    last_completed_activity_id: value.lastCompletedActivityId,
  }
}
