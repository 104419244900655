// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Role } from './Role'
import { RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from './Role'

/**
 *
 * @export
 * @interface WhoAmIRepresentation
 */
export interface WhoAmIRepresentation {
  /**
   *
   * @type {string}
   * @memberof WhoAmIRepresentation
   */
  subject?: string
  /**
   *
   * @type {string}
   * @memberof WhoAmIRepresentation
   */
  issuer?: string
  /**
   *
   * @type {string}
   * @memberof WhoAmIRepresentation
   */
  userId?: string
  /**
   *
   * @type {Role}
   * @memberof WhoAmIRepresentation
   */
  role?: Role
  /**
   *
   * @type {number}
   * @memberof WhoAmIRepresentation
   */
  blakeId?: number
  /**
   *
   * @type {string}
   * @memberof WhoAmIRepresentation
   */
  schoolId?: string | null
  /**
   *
   * @type {number}
   * @memberof WhoAmIRepresentation
   */
  blakeSchoolId?: number | null
  /**
   *
   * @type {Date}
   * @memberof WhoAmIRepresentation
   */
  lastUpdated?: Date
}

/**
 * Check if a given object implements the WhoAmIRepresentation interface.
 */
export function instanceOfWhoAmIRepresentation(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function WhoAmIRepresentationFromJSON(json: any): WhoAmIRepresentation {
  return WhoAmIRepresentationFromJSONTyped(json, false)
}

export function WhoAmIRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WhoAmIRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    subject: !exists(json, 'subject') ? undefined : json['subject'],
    issuer: !exists(json, 'issuer') ? undefined : json['issuer'],
    userId: !exists(json, 'userId') ? undefined : json['userId'],
    role: !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
    blakeId: !exists(json, 'blakeId') ? undefined : json['blakeId'],
    schoolId: !exists(json, 'schoolId') ? undefined : json['schoolId'],
    blakeSchoolId: !exists(json, 'blakeSchoolId')
      ? undefined
      : json['blakeSchoolId'],
    lastUpdated: !exists(json, 'lastUpdated')
      ? undefined
      : new Date(json['lastUpdated']),
  }
}

export function WhoAmIRepresentationToJSON(
  value?: WhoAmIRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    subject: value.subject,
    issuer: value.issuer,
    userId: value.userId,
    role: RoleToJSON(value.role),
    blakeId: value.blakeId,
    schoolId: value.schoolId,
    blakeSchoolId: value.blakeSchoolId,
    lastUpdated:
      value.lastUpdated === undefined
        ? undefined
        : value.lastUpdated.toISOString(),
  }
}
