// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ActivityResultSnapshotType = {
  None: 'None',
  RevisionSnapshot: 'RevisionSnapshot',
} as const
export type ActivityResultSnapshotType =
  (typeof ActivityResultSnapshotType)[keyof typeof ActivityResultSnapshotType]

export function ActivityResultSnapshotTypeFromJSON(
  json: any,
): ActivityResultSnapshotType {
  return ActivityResultSnapshotTypeFromJSONTyped(json, false)
}

export function ActivityResultSnapshotTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityResultSnapshotType {
  return json as ActivityResultSnapshotType
}

export function ActivityResultSnapshotTypeToJSON(
  value?: ActivityResultSnapshotType | null,
): any {
  return value as any
}
