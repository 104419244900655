// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityResultDataRepresentation } from './ActivityResultDataRepresentation'
import {
  ActivityResultDataRepresentationFromJSON,
  ActivityResultDataRepresentationFromJSONTyped,
  ActivityResultDataRepresentationToJSON,
} from './ActivityResultDataRepresentation'

/**
 *
 * @export
 * @interface ActivityResultDataRepresentationBaseApiListResult
 */
export interface ActivityResultDataRepresentationBaseApiListResult {
  /**
   *
   * @type {Array<ActivityResultDataRepresentation>}
   * @memberof ActivityResultDataRepresentationBaseApiListResult
   */
  data: Array<ActivityResultDataRepresentation>
  /**
   *
   * @type {number}
   * @memberof ActivityResultDataRepresentationBaseApiListResult
   */
  total: number
}

/**
 * Check if a given object implements the ActivityResultDataRepresentationBaseApiListResult interface.
 */
export function instanceOfActivityResultDataRepresentationBaseApiListResult(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'total' in value

  return isInstance
}

export function ActivityResultDataRepresentationBaseApiListResultFromJSON(
  json: any,
): ActivityResultDataRepresentationBaseApiListResult {
  return ActivityResultDataRepresentationBaseApiListResultFromJSONTyped(
    json,
    false,
  )
}

export function ActivityResultDataRepresentationBaseApiListResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityResultDataRepresentationBaseApiListResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(
      ActivityResultDataRepresentationFromJSON,
    ),
    total: json['total'],
  }
}

export function ActivityResultDataRepresentationBaseApiListResultToJSON(
  value?: ActivityResultDataRepresentationBaseApiListResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(
      ActivityResultDataRepresentationToJSON,
    ),
    total: value.total,
  }
}
