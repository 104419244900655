// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface QuizResultEventRequestRepresentation
 */
export interface QuizResultEventRequestRepresentation {
  /**
   *
   * @type {number}
   * @memberof QuizResultEventRequestRepresentation
   */
  correctCount: number
  /**
   *
   * @type {number}
   * @memberof QuizResultEventRequestRepresentation
   */
  totalCount: number
  /**
   *
   * @type {Array<string>}
   * @memberof QuizResultEventRequestRepresentation
   */
  correctAnswers: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof QuizResultEventRequestRepresentation
   */
  studentAnswers: Array<string>
  /**
   *
   * @type {string}
   * @memberof QuizResultEventRequestRepresentation
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof QuizResultEventRequestRepresentation
   */
  assignmentId?: string | null
  /**
   *
   * @type {number}
   * @memberof QuizResultEventRequestRepresentation
   */
  coinsEarned?: number
  /**
   *
   * @type {Array<string>}
   * @memberof QuizResultEventRequestRepresentation
   */
  rewardIds?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof QuizResultEventRequestRepresentation
   */
  passCriteriaIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof QuizResultEventRequestRepresentation
   */
  contentDataName?: string | null
  /**
   *
   * @type {string}
   * @memberof QuizResultEventRequestRepresentation
   */
  activityName?: string | null
  /**
   *
   * @type {string}
   * @memberof QuizResultEventRequestRepresentation
   */
  activityType?: string | null
  /**
   *
   * @type {boolean}
   * @memberof QuizResultEventRequestRepresentation
   */
  isEndOfLessonEvent?: boolean | null
  /**
   *
   * @type {string}
   * @memberof QuizResultEventRequestRepresentation
   */
  eventId?: string | null
}

/**
 * Check if a given object implements the QuizResultEventRequestRepresentation interface.
 */
export function instanceOfQuizResultEventRequestRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'correctCount' in value
  isInstance = isInstance && 'totalCount' in value
  isInstance = isInstance && 'correctAnswers' in value
  isInstance = isInstance && 'studentAnswers' in value
  isInstance = isInstance && 'activityId' in value

  return isInstance
}

export function QuizResultEventRequestRepresentationFromJSON(
  json: any,
): QuizResultEventRequestRepresentation {
  return QuizResultEventRequestRepresentationFromJSONTyped(json, false)
}

export function QuizResultEventRequestRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): QuizResultEventRequestRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    correctCount: json['correct_count'],
    totalCount: json['total_count'],
    correctAnswers: json['correct_answers'],
    studentAnswers: json['student_answers'],
    activityId: json['activity_id'],
    assignmentId: !exists(json, 'assignment_id')
      ? undefined
      : json['assignment_id'],
    coinsEarned: !exists(json, 'coins_earned')
      ? undefined
      : json['coins_earned'],
    rewardIds: !exists(json, 'reward_ids') ? undefined : json['reward_ids'],
    passCriteriaIds: !exists(json, 'pass_criteria_ids')
      ? undefined
      : json['pass_criteria_ids'],
    contentDataName: !exists(json, 'content_data_name')
      ? undefined
      : json['content_data_name'],
    activityName: !exists(json, 'activity_name')
      ? undefined
      : json['activity_name'],
    activityType: !exists(json, 'activity_type')
      ? undefined
      : json['activity_type'],
    isEndOfLessonEvent: !exists(json, 'is_end_of_lesson_event')
      ? undefined
      : json['is_end_of_lesson_event'],
    eventId: !exists(json, 'eventId') ? undefined : json['eventId'],
  }
}

export function QuizResultEventRequestRepresentationToJSON(
  value?: QuizResultEventRequestRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    correct_count: value.correctCount,
    total_count: value.totalCount,
    correct_answers: value.correctAnswers,
    student_answers: value.studentAnswers,
    activity_id: value.activityId,
    assignment_id: value.assignmentId,
    coins_earned: value.coinsEarned,
    reward_ids: value.rewardIds,
    pass_criteria_ids: value.passCriteriaIds,
    content_data_name: value.contentDataName,
    activity_name: value.activityName,
    activity_type: value.activityType,
    is_end_of_lesson_event: value.isEndOfLessonEvent,
    eventId: value.eventId,
  }
}
