// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface RecentStudentBookBlurb
 */
export interface RecentStudentBookBlurb {
  /**
   *
   * @type {string}
   * @memberof RecentStudentBookBlurb
   */
  studentId?: string
  /**
   *
   * @type {string}
   * @memberof RecentStudentBookBlurb
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof RecentStudentBookBlurb
   */
  bookBlurbId?: string
  /**
   *
   * @type {Date}
   * @memberof RecentStudentBookBlurb
   */
  dateBlurbLastUpdated?: Date
}

/**
 * Check if a given object implements the RecentStudentBookBlurb interface.
 */
export function instanceOfRecentStudentBookBlurb(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function RecentStudentBookBlurbFromJSON(
  json: any,
): RecentStudentBookBlurb {
  return RecentStudentBookBlurbFromJSONTyped(json, false)
}

export function RecentStudentBookBlurbFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RecentStudentBookBlurb {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    bookBlurbId: !exists(json, 'book_blurb_id')
      ? undefined
      : json['book_blurb_id'],
    dateBlurbLastUpdated: !exists(json, 'date_blurb_last_updated')
      ? undefined
      : new Date(json['date_blurb_last_updated']),
  }
}

export function RecentStudentBookBlurbToJSON(
  value?: RecentStudentBookBlurb | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    student_id: value.studentId,
    activity_id: value.activityId,
    book_blurb_id: value.bookBlurbId,
    date_blurb_last_updated:
      value.dateBlurbLastUpdated === undefined
        ? undefined
        : value.dateBlurbLastUpdated.toISOString(),
  }
}
