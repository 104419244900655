// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TextStatsResponse
 */
export interface TextStatsResponse {
  /**
   *
   * @type {number}
   * @memberof TextStatsResponse
   */
  wordCount: number
  /**
   *
   * @type {number}
   * @memberof TextStatsResponse
   */
  uniqueWordCount: number
  /**
   *
   * @type {number}
   * @memberof TextStatsResponse
   */
  sentenceCount: number
  /**
   *
   * @type {Array<number>}
   * @memberof TextStatsResponse
   */
  sentenceLengths: Array<number>
  /**
   *
   * @type {number}
   * @memberof TextStatsResponse
   */
  wordVariety: number
  /**
   *
   * @type {number}
   * @memberof TextStatsResponse
   */
  sentenceLengthVariety: number
}

/**
 * Check if a given object implements the TextStatsResponse interface.
 */
export function instanceOfTextStatsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'wordCount' in value
  isInstance = isInstance && 'uniqueWordCount' in value
  isInstance = isInstance && 'sentenceCount' in value
  isInstance = isInstance && 'sentenceLengths' in value
  isInstance = isInstance && 'wordVariety' in value
  isInstance = isInstance && 'sentenceLengthVariety' in value

  return isInstance
}

export function TextStatsResponseFromJSON(json: any): TextStatsResponse {
  return TextStatsResponseFromJSONTyped(json, false)
}

export function TextStatsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TextStatsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    wordCount: json['word_count'],
    uniqueWordCount: json['unique_word_count'],
    sentenceCount: json['sentence_count'],
    sentenceLengths: json['sentence_lengths'],
    wordVariety: json['word_variety'],
    sentenceLengthVariety: json['sentence_length_variety'],
  }
}

export function TextStatsResponseToJSON(value?: TextStatsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    word_count: value.wordCount,
    unique_word_count: value.uniqueWordCount,
    sentence_count: value.sentenceCount,
    sentence_lengths: value.sentenceLengths,
    word_variety: value.wordVariety,
    sentence_length_variety: value.sentenceLengthVariety,
  }
}
