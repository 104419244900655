// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MisspellingRepresentation
 */
export interface MisspellingRepresentation {
  /**
   *
   * @type {string}
   * @memberof MisspellingRepresentation
   */
  text?: string | null
  /**
   *
   * @type {number}
   * @memberof MisspellingRepresentation
   */
  idx?: number
}

/**
 * Check if a given object implements the MisspellingRepresentation interface.
 */
export function instanceOfMisspellingRepresentation(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function MisspellingRepresentationFromJSON(
  json: any,
): MisspellingRepresentation {
  return MisspellingRepresentationFromJSONTyped(json, false)
}

export function MisspellingRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MisspellingRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    text: !exists(json, 'text') ? undefined : json['text'],
    idx: !exists(json, 'idx') ? undefined : json['idx'],
  }
}

export function MisspellingRepresentationToJSON(
  value?: MisspellingRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    text: value.text,
    idx: value.idx,
  }
}
