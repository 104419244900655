// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { WritingMood } from './WritingMood'
import {
  WritingMoodFromJSON,
  WritingMoodFromJSONTyped,
  WritingMoodToJSON,
} from './WritingMood'

/**
 *
 * @export
 * @interface PlacementTestRequest
 */
export interface PlacementTestRequest {
  /**
   *
   * @type {string}
   * @memberof PlacementTestRequest
   */
  activityId: string
  /**
   *
   * @type {Array<string>}
   * @memberof PlacementTestRequest
   */
  studentAnswers: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof PlacementTestRequest
   */
  isSkipped: boolean
  /**
   *
   * @type {number}
   * @memberof PlacementTestRequest
   */
  lessonUnlock: number
  /**
   *
   * @type {WritingMood}
   * @memberof PlacementTestRequest
   */
  writingMood: WritingMood
  /**
   *
   * @type {string}
   * @memberof PlacementTestRequest
   */
  eventId?: string | null
}

/**
 * Check if a given object implements the PlacementTestRequest interface.
 */
export function instanceOfPlacementTestRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'studentAnswers' in value
  isInstance = isInstance && 'isSkipped' in value
  isInstance = isInstance && 'lessonUnlock' in value
  isInstance = isInstance && 'writingMood' in value

  return isInstance
}

export function PlacementTestRequestFromJSON(json: any): PlacementTestRequest {
  return PlacementTestRequestFromJSONTyped(json, false)
}

export function PlacementTestRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PlacementTestRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityId: json['activity_id'],
    studentAnswers: json['student_answers'],
    isSkipped: json['is_skipped'],
    lessonUnlock: json['lesson_unlock'],
    writingMood: WritingMoodFromJSON(json['writing_mood']),
    eventId: !exists(json, 'eventId') ? undefined : json['eventId'],
  }
}

export function PlacementTestRequestToJSON(
  value?: PlacementTestRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_id: value.activityId,
    student_answers: value.studentAnswers,
    is_skipped: value.isSkipped,
    lesson_unlock: value.lessonUnlock,
    writing_mood: WritingMoodToJSON(value.writingMood),
    eventId: value.eventId,
  }
}
