// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BookBlurbRating } from './BookBlurbRating'
import {
  BookBlurbRatingFromJSON,
  BookBlurbRatingFromJSONTyped,
  BookBlurbRatingToJSON,
} from './BookBlurbRating'

/**
 *
 * @export
 * @interface BookBlurbRepresentation
 */
export interface BookBlurbRepresentation {
  /**
   *
   * @type {string}
   * @memberof BookBlurbRepresentation
   */
  bookBlurbId?: string
  /**
   *
   * @type {string}
   * @memberof BookBlurbRepresentation
   */
  studentId?: string
  /**
   *
   * @type {string}
   * @memberof BookBlurbRepresentation
   */
  activityId?: string
  /**
   *
   * @type {BookBlurbRating}
   * @memberof BookBlurbRepresentation
   */
  rating?: BookBlurbRating
  /**
   *
   * @type {Array<string>}
   * @memberof BookBlurbRepresentation
   */
  words?: Array<string> | null
  /**
   *
   * @type {Date}
   * @memberof BookBlurbRepresentation
   */
  lastModifiedDate?: Date
}

/**
 * Check if a given object implements the BookBlurbRepresentation interface.
 */
export function instanceOfBookBlurbRepresentation(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function BookBlurbRepresentationFromJSON(
  json: any,
): BookBlurbRepresentation {
  return BookBlurbRepresentationFromJSONTyped(json, false)
}

export function BookBlurbRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookBlurbRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    bookBlurbId: !exists(json, 'book_blurb_id')
      ? undefined
      : json['book_blurb_id'],
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    rating: !exists(json, 'rating')
      ? undefined
      : BookBlurbRatingFromJSON(json['rating']),
    words: !exists(json, 'words') ? undefined : json['words'],
    lastModifiedDate: !exists(json, 'last_modified_date')
      ? undefined
      : new Date(json['last_modified_date']),
  }
}

export function BookBlurbRepresentationToJSON(
  value?: BookBlurbRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    book_blurb_id: value.bookBlurbId,
    student_id: value.studentId,
    activity_id: value.activityId,
    rating: BookBlurbRatingToJSON(value.rating),
    words: value.words,
    last_modified_date:
      value.lastModifiedDate === undefined
        ? undefined
        : value.lastModifiedDate.toISOString(),
  }
}
