// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityAnswerSnapshotRepresentation } from './ActivityAnswerSnapshotRepresentation'
import {
  ActivityAnswerSnapshotRepresentationFromJSON,
  ActivityAnswerSnapshotRepresentationFromJSONTyped,
  ActivityAnswerSnapshotRepresentationToJSON,
} from './ActivityAnswerSnapshotRepresentation'
import type { ActivityResultSnapshotType } from './ActivityResultSnapshotType'
import {
  ActivityResultSnapshotTypeFromJSON,
  ActivityResultSnapshotTypeFromJSONTyped,
  ActivityResultSnapshotTypeToJSON,
} from './ActivityResultSnapshotType'
import type { LegendsFeedbackRepresentation } from './LegendsFeedbackRepresentation'
import {
  LegendsFeedbackRepresentationFromJSON,
  LegendsFeedbackRepresentationFromJSONTyped,
  LegendsFeedbackRepresentationToJSON,
} from './LegendsFeedbackRepresentation'
import type { WritingStatus } from './WritingStatus'
import {
  WritingStatusFromJSON,
  WritingStatusFromJSONTyped,
  WritingStatusToJSON,
} from './WritingStatus'

/**
 *
 * @export
 * @interface ActivityResultSnapshotCompositeRepresentation
 */
export interface ActivityResultSnapshotCompositeRepresentation {
  /**
   *
   * @type {string}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  activityResultSnapshotId?: string
  /**
   *
   * @type {ActivityResultSnapshotType}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  activityResultSnapshotType?: ActivityResultSnapshotType
  /**
   *
   * @type {string}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  resultId?: string
  /**
   *
   * @type {string}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  assignmentId?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  assignmentTaskId?: number | null
  /**
   *
   * @type {string}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  eventId?: string | null
  /**
   *
   * @type {WritingStatus}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  status?: WritingStatus
  /**
   *
   * @type {number}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  score?: number | null
  /**
   *
   * @type {Date}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  updatedDate?: Date | null
  /**
   *
   * @type {string}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  reflection?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  words?: number | null
  /**
   *
   * @type {number}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  uniqueWords?: number | null
  /**
   *
   * @type {Array<ActivityAnswerSnapshotRepresentation>}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  answerSnapshots?: Array<ActivityAnswerSnapshotRepresentation> | null
  /**
   *
   * @type {Array<LegendsFeedbackRepresentation>}
   * @memberof ActivityResultSnapshotCompositeRepresentation
   */
  legendsFeedback?: Array<LegendsFeedbackRepresentation> | null
}

/**
 * Check if a given object implements the ActivityResultSnapshotCompositeRepresentation interface.
 */
export function instanceOfActivityResultSnapshotCompositeRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ActivityResultSnapshotCompositeRepresentationFromJSON(
  json: any,
): ActivityResultSnapshotCompositeRepresentation {
  return ActivityResultSnapshotCompositeRepresentationFromJSONTyped(json, false)
}

export function ActivityResultSnapshotCompositeRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityResultSnapshotCompositeRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityResultSnapshotId: !exists(json, 'activity_result_snapshot_id')
      ? undefined
      : json['activity_result_snapshot_id'],
    activityResultSnapshotType: !exists(json, 'activity_result_snapshot_type')
      ? undefined
      : ActivityResultSnapshotTypeFromJSON(
          json['activity_result_snapshot_type'],
        ),
    resultId: !exists(json, 'result_id') ? undefined : json['result_id'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    assignmentId: !exists(json, 'assignment_id')
      ? undefined
      : json['assignment_id'],
    assignmentTaskId: !exists(json, 'assignment_task_id')
      ? undefined
      : json['assignment_task_id'],
    eventId: !exists(json, 'eventId') ? undefined : json['eventId'],
    status: !exists(json, 'status')
      ? undefined
      : WritingStatusFromJSON(json['status']),
    score: !exists(json, 'score') ? undefined : json['score'],
    createdDate: !exists(json, 'created_date')
      ? undefined
      : new Date(json['created_date']),
    updatedDate: !exists(json, 'updated_date')
      ? undefined
      : json['updated_date'] === null
        ? null
        : new Date(json['updated_date']),
    reflection: !exists(json, 'reflection') ? undefined : json['reflection'],
    words: !exists(json, 'words') ? undefined : json['words'],
    uniqueWords: !exists(json, 'unique_words')
      ? undefined
      : json['unique_words'],
    answerSnapshots: !exists(json, 'answer_snapshots')
      ? undefined
      : json['answer_snapshots'] === null
        ? null
        : (json['answer_snapshots'] as Array<any>).map(
            ActivityAnswerSnapshotRepresentationFromJSON,
          ),
    legendsFeedback: !exists(json, 'legends_feedback')
      ? undefined
      : json['legends_feedback'] === null
        ? null
        : (json['legends_feedback'] as Array<any>).map(
            LegendsFeedbackRepresentationFromJSON,
          ),
  }
}

export function ActivityResultSnapshotCompositeRepresentationToJSON(
  value?: ActivityResultSnapshotCompositeRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_result_snapshot_id: value.activityResultSnapshotId,
    activity_result_snapshot_type: ActivityResultSnapshotTypeToJSON(
      value.activityResultSnapshotType,
    ),
    result_id: value.resultId,
    user_id: value.userId,
    activity_id: value.activityId,
    assignment_id: value.assignmentId,
    assignment_task_id: value.assignmentTaskId,
    eventId: value.eventId,
    status: WritingStatusToJSON(value.status),
    score: value.score,
    created_date:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString(),
    updated_date:
      value.updatedDate === undefined
        ? undefined
        : value.updatedDate === null
          ? null
          : value.updatedDate.toISOString(),
    reflection: value.reflection,
    words: value.words,
    unique_words: value.uniqueWords,
    answer_snapshots:
      value.answerSnapshots === undefined
        ? undefined
        : value.answerSnapshots === null
          ? null
          : (value.answerSnapshots as Array<any>).map(
              ActivityAnswerSnapshotRepresentationToJSON,
            ),
    legends_feedback:
      value.legendsFeedback === undefined
        ? undefined
        : value.legendsFeedback === null
          ? null
          : (value.legendsFeedback as Array<any>).map(
              LegendsFeedbackRepresentationToJSON,
            ),
  }
}
