// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ActivityAnswerSnapshotRepresentation
 */
export interface ActivityAnswerSnapshotRepresentation {
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerSnapshotRepresentation
   */
  activityAnswerSnapshotId?: string
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerSnapshotRepresentation
   */
  activityResultSnapshotId?: string
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerSnapshotRepresentation
   */
  answerId?: string
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerSnapshotRepresentation
   */
  resultId?: string
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerSnapshotRepresentation
   */
  blockId?: string
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerSnapshotRepresentation
   */
  answerText?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerSnapshotRepresentation
   */
  plainText?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityAnswerSnapshotRepresentation
   */
  score?: number | null
}

/**
 * Check if a given object implements the ActivityAnswerSnapshotRepresentation interface.
 */
export function instanceOfActivityAnswerSnapshotRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ActivityAnswerSnapshotRepresentationFromJSON(
  json: any,
): ActivityAnswerSnapshotRepresentation {
  return ActivityAnswerSnapshotRepresentationFromJSONTyped(json, false)
}

export function ActivityAnswerSnapshotRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityAnswerSnapshotRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityAnswerSnapshotId: !exists(json, 'activity_answer_snapshot_id')
      ? undefined
      : json['activity_answer_snapshot_id'],
    activityResultSnapshotId: !exists(json, 'activity_result_snapshot_id')
      ? undefined
      : json['activity_result_snapshot_id'],
    answerId: !exists(json, 'answer_id') ? undefined : json['answer_id'],
    resultId: !exists(json, 'result_id') ? undefined : json['result_id'],
    blockId: !exists(json, 'block_id') ? undefined : json['block_id'],
    answerText: !exists(json, 'answer_text') ? undefined : json['answer_text'],
    plainText: !exists(json, 'plain_text') ? undefined : json['plain_text'],
    score: !exists(json, 'score') ? undefined : json['score'],
  }
}

export function ActivityAnswerSnapshotRepresentationToJSON(
  value?: ActivityAnswerSnapshotRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_answer_snapshot_id: value.activityAnswerSnapshotId,
    activity_result_snapshot_id: value.activityResultSnapshotId,
    answer_id: value.answerId,
    result_id: value.resultId,
    block_id: value.blockId,
    answer_text: value.answerText,
    plain_text: value.plainText,
    score: value.score,
  }
}
