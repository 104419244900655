// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ActivityBlockRepresentation
 */
export interface ActivityBlockRepresentation {
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  activityIdOwner?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  blockId: string
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  content?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  audioText?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  answerText?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  answerGraphic?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  distractorText?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  distractorGraphic?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  answerExplain?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  answerNote?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityBlockRepresentation
   */
  answerLimit?: number | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  answerHint?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  blockType?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  questionType?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  originalBlockId?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityBlockRepresentation
   */
  lexorank: string
  /**
   *
   * @type {boolean}
   * @memberof ActivityBlockRepresentation
   */
  isDeleted?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ActivityBlockRepresentation
   */
  isInternalBlock?: boolean
  /**
   *
   * @type {number}
   * @memberof ActivityBlockRepresentation
   */
  targetWordCount?: number
}

/**
 * Check if a given object implements the ActivityBlockRepresentation interface.
 */
export function instanceOfActivityBlockRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'blockId' in value
  isInstance = isInstance && 'lexorank' in value

  return isInstance
}

export function ActivityBlockRepresentationFromJSON(
  json: any,
): ActivityBlockRepresentation {
  return ActivityBlockRepresentationFromJSONTyped(json, false)
}

export function ActivityBlockRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityBlockRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityId: json['activity_id'],
    activityIdOwner: !exists(json, 'activity_id_owner')
      ? undefined
      : json['activity_id_owner'],
    blockId: json['block_id'],
    content: !exists(json, 'content') ? undefined : json['content'],
    audioText: !exists(json, 'audio_text') ? undefined : json['audio_text'],
    answerText: !exists(json, 'answer_text') ? undefined : json['answer_text'],
    answerGraphic: !exists(json, 'answer_graphic')
      ? undefined
      : json['answer_graphic'],
    distractorText: !exists(json, 'distractor_text')
      ? undefined
      : json['distractor_text'],
    distractorGraphic: !exists(json, 'distractor_graphic')
      ? undefined
      : json['distractor_graphic'],
    answerExplain: !exists(json, 'answer_explain')
      ? undefined
      : json['answer_explain'],
    answerNote: !exists(json, 'answer_note') ? undefined : json['answer_note'],
    answerLimit: !exists(json, 'answer_limit')
      ? undefined
      : json['answer_limit'],
    answerHint: !exists(json, 'answer_hint') ? undefined : json['answer_hint'],
    blockType: !exists(json, 'block_type') ? undefined : json['block_type'],
    questionType: !exists(json, 'question_type')
      ? undefined
      : json['question_type'],
    originalBlockId: !exists(json, 'original_block_id')
      ? undefined
      : json['original_block_id'],
    lexorank: json['lexorank'],
    isDeleted: !exists(json, 'is_deleted') ? undefined : json['is_deleted'],
    isInternalBlock: !exists(json, 'is_internal_block')
      ? undefined
      : json['is_internal_block'],
    targetWordCount: !exists(json, 'target_word_count')
      ? undefined
      : json['target_word_count'],
  }
}

export function ActivityBlockRepresentationToJSON(
  value?: ActivityBlockRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_id: value.activityId,
    activity_id_owner: value.activityIdOwner,
    block_id: value.blockId,
    content: value.content,
    audio_text: value.audioText,
    answer_text: value.answerText,
    answer_graphic: value.answerGraphic,
    distractor_text: value.distractorText,
    distractor_graphic: value.distractorGraphic,
    answer_explain: value.answerExplain,
    answer_note: value.answerNote,
    answer_limit: value.answerLimit,
    answer_hint: value.answerHint,
    block_type: value.blockType,
    question_type: value.questionType,
    original_block_id: value.originalBlockId,
    lexorank: value.lexorank,
    is_deleted: value.isDeleted,
    is_internal_block: value.isInternalBlock,
    target_word_count: value.targetWordCount,
  }
}
