// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BookBlurbRating } from './BookBlurbRating'
import {
  BookBlurbRatingFromJSON,
  BookBlurbRatingFromJSONTyped,
  BookBlurbRatingToJSON,
} from './BookBlurbRating'

/**
 *
 * @export
 * @interface BookBlurbsRepresentation
 */
export interface BookBlurbsRepresentation {
  /**
   *
   * @type {string}
   * @memberof BookBlurbsRepresentation
   */
  bookBlurbId?: string
  /**
   *
   * @type {string}
   * @memberof BookBlurbsRepresentation
   */
  studentId?: string
  /**
   *
   * @type {string}
   * @memberof BookBlurbsRepresentation
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof BookBlurbsRepresentation
   */
  grade?: string
  /**
   *
   * @type {BookBlurbRating}
   * @memberof BookBlurbsRepresentation
   */
  rating?: BookBlurbRating
  /**
   *
   * @type {string}
   * @memberof BookBlurbsRepresentation
   */
  metadata?: string | null
  /**
   *
   * @type {Date}
   * @memberof BookBlurbsRepresentation
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof BookBlurbsRepresentation
   */
  updatedDate?: Date | null
  /**
   *
   * @type {Array<string>}
   * @memberof BookBlurbsRepresentation
   */
  words?: Array<string> | null
}

/**
 * Check if a given object implements the BookBlurbsRepresentation interface.
 */
export function instanceOfBookBlurbsRepresentation(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function BookBlurbsRepresentationFromJSON(
  json: any,
): BookBlurbsRepresentation {
  return BookBlurbsRepresentationFromJSONTyped(json, false)
}

export function BookBlurbsRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookBlurbsRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    bookBlurbId: !exists(json, 'book_blurb_id')
      ? undefined
      : json['book_blurb_id'],
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    grade: !exists(json, 'grade') ? undefined : json['grade'],
    rating: !exists(json, 'rating')
      ? undefined
      : BookBlurbRatingFromJSON(json['rating']),
    metadata: !exists(json, 'metadata') ? undefined : json['metadata'],
    createdDate: !exists(json, 'created_date')
      ? undefined
      : new Date(json['created_date']),
    updatedDate: !exists(json, 'updated_date')
      ? undefined
      : json['updated_date'] === null
        ? null
        : new Date(json['updated_date']),
    words: !exists(json, 'words') ? undefined : json['words'],
  }
}

export function BookBlurbsRepresentationToJSON(
  value?: BookBlurbsRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    book_blurb_id: value.bookBlurbId,
    student_id: value.studentId,
    activity_id: value.activityId,
    grade: value.grade,
    rating: BookBlurbRatingToJSON(value.rating),
    metadata: value.metadata,
    created_date:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString(),
    updated_date:
      value.updatedDate === undefined
        ? undefined
        : value.updatedDate === null
          ? null
          : value.updatedDate.toISOString(),
    words: value.words,
  }
}
