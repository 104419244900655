// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface QuizResultResponseRepresentationData
 */
export interface QuizResultResponseRepresentationData {
  /**
   *
   * @type {boolean}
   * @memberof QuizResultResponseRepresentationData
   */
  passed: boolean
  /**
   *
   * @type {number}
   * @memberof QuizResultResponseRepresentationData
   */
  attempt: number
  /**
   *
   * @type {string}
   * @memberof QuizResultResponseRepresentationData
   */
  activityId: string
  /**
   *
   * @type {number}
   * @memberof QuizResultResponseRepresentationData
   */
  coinsEarned: number
  /**
   *
   * @type {string}
   * @memberof QuizResultResponseRepresentationData
   */
  lastSavedProgressionStep?: string
  /**
   *
   * @type {number}
   * @memberof QuizResultResponseRepresentationData
   */
  progressionLessonOverride?: number | null
  /**
   *
   * @type {string}
   * @memberof QuizResultResponseRepresentationData
   */
  assignmentId?: string | null
  /**
   *
   * @type {number}
   * @memberof QuizResultResponseRepresentationData
   */
  assignmentTaskId?: number | null
}

/**
 * Check if a given object implements the QuizResultResponseRepresentationData interface.
 */
export function instanceOfQuizResultResponseRepresentationData(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'passed' in value
  isInstance = isInstance && 'attempt' in value
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'coinsEarned' in value

  return isInstance
}

export function QuizResultResponseRepresentationDataFromJSON(
  json: any,
): QuizResultResponseRepresentationData {
  return QuizResultResponseRepresentationDataFromJSONTyped(json, false)
}

export function QuizResultResponseRepresentationDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): QuizResultResponseRepresentationData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    passed: json['passed'],
    attempt: json['attempt'],
    activityId: json['activity_id'],
    coinsEarned: json['coins_earned'],
    lastSavedProgressionStep: !exists(json, 'last_saved_progression_step')
      ? undefined
      : json['last_saved_progression_step'],
    progressionLessonOverride: !exists(json, 'progression_lesson_override')
      ? undefined
      : json['progression_lesson_override'],
    assignmentId: !exists(json, 'assignment_id')
      ? undefined
      : json['assignment_id'],
    assignmentTaskId: !exists(json, 'assignment_task_id')
      ? undefined
      : json['assignment_task_id'],
  }
}

export function QuizResultResponseRepresentationDataToJSON(
  value?: QuizResultResponseRepresentationData | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    passed: value.passed,
    attempt: value.attempt,
    activity_id: value.activityId,
    coins_earned: value.coinsEarned,
    last_saved_progression_step: value.lastSavedProgressionStep,
    progression_lesson_override: value.progressionLessonOverride,
    assignment_id: value.assignmentId,
    assignment_task_id: value.assignmentTaskId,
  }
}
