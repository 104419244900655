// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ClassroomBookBlurbsRepresentation
 */
export interface ClassroomBookBlurbsRepresentation {
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbsRepresentation
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbsRepresentation
   */
  activityTitle?: string
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbsRepresentation
   */
  activityThumbnail?: string
  /**
   *
   * @type {number}
   * @memberof ClassroomBookBlurbsRepresentation
   */
  ratingHighCount?: number
  /**
   *
   * @type {number}
   * @memberof ClassroomBookBlurbsRepresentation
   */
  ratingMedCount?: number
  /**
   *
   * @type {number}
   * @memberof ClassroomBookBlurbsRepresentation
   */
  ratingLowCount?: number
  /**
   *
   * @type {Date}
   * @memberof ClassroomBookBlurbsRepresentation
   */
  dateBlurbLastUpdated?: Date
}

/**
 * Check if a given object implements the ClassroomBookBlurbsRepresentation interface.
 */
export function instanceOfClassroomBookBlurbsRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ClassroomBookBlurbsRepresentationFromJSON(
  json: any,
): ClassroomBookBlurbsRepresentation {
  return ClassroomBookBlurbsRepresentationFromJSONTyped(json, false)
}

export function ClassroomBookBlurbsRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ClassroomBookBlurbsRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    activityTitle: !exists(json, 'activity_title')
      ? undefined
      : json['activity_title'],
    activityThumbnail: !exists(json, 'activity_thumbnail')
      ? undefined
      : json['activity_thumbnail'],
    ratingHighCount: !exists(json, 'rating_high_count')
      ? undefined
      : json['rating_high_count'],
    ratingMedCount: !exists(json, 'rating_med_count')
      ? undefined
      : json['rating_med_count'],
    ratingLowCount: !exists(json, 'rating_low_count')
      ? undefined
      : json['rating_low_count'],
    dateBlurbLastUpdated: !exists(json, 'date_blurb_last_updated')
      ? undefined
      : new Date(json['date_blurb_last_updated']),
  }
}

export function ClassroomBookBlurbsRepresentationToJSON(
  value?: ClassroomBookBlurbsRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_id: value.activityId,
    activity_title: value.activityTitle,
    activity_thumbnail: value.activityThumbnail,
    rating_high_count: value.ratingHighCount,
    rating_med_count: value.ratingMedCount,
    rating_low_count: value.ratingLowCount,
    date_blurb_last_updated:
      value.dateBlurbLastUpdated === undefined
        ? undefined
        : value.dateBlurbLastUpdated.toISOString(),
  }
}
