// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { LegendsFeedbackRepresentation } from './LegendsFeedbackRepresentation'
import {
  LegendsFeedbackRepresentationFromJSON,
  LegendsFeedbackRepresentationFromJSONTyped,
  LegendsFeedbackRepresentationToJSON,
} from './LegendsFeedbackRepresentation'

/**
 *
 * @export
 * @interface ActivityAnswerRepresentation
 */
export interface ActivityAnswerRepresentation {
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerRepresentation
   */
  answerId: string
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerRepresentation
   */
  resultId: string
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerRepresentation
   */
  blockId: string
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerRepresentation
   */
  answerText?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityAnswerRepresentation
   */
  plainText?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityAnswerRepresentation
   */
  score?: number | null
  /**
   *
   * @type {LegendsFeedbackRepresentation}
   * @memberof ActivityAnswerRepresentation
   */
  legendsFeedback?: LegendsFeedbackRepresentation
}

/**
 * Check if a given object implements the ActivityAnswerRepresentation interface.
 */
export function instanceOfActivityAnswerRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'answerId' in value
  isInstance = isInstance && 'resultId' in value
  isInstance = isInstance && 'blockId' in value

  return isInstance
}

export function ActivityAnswerRepresentationFromJSON(
  json: any,
): ActivityAnswerRepresentation {
  return ActivityAnswerRepresentationFromJSONTyped(json, false)
}

export function ActivityAnswerRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityAnswerRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    answerId: json['answer_id'],
    resultId: json['result_id'],
    blockId: json['block_id'],
    answerText: !exists(json, 'answer_text') ? undefined : json['answer_text'],
    plainText: !exists(json, 'plain_text') ? undefined : json['plain_text'],
    score: !exists(json, 'score') ? undefined : json['score'],
    legendsFeedback: !exists(json, 'legends_feedback')
      ? undefined
      : LegendsFeedbackRepresentationFromJSON(json['legends_feedback']),
  }
}

export function ActivityAnswerRepresentationToJSON(
  value?: ActivityAnswerRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    answer_id: value.answerId,
    result_id: value.resultId,
    block_id: value.blockId,
    answer_text: value.answerText,
    plain_text: value.plainText,
    score: value.score,
    legends_feedback: LegendsFeedbackRepresentationToJSON(
      value.legendsFeedback,
    ),
  }
}
