// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const BookBlurbRating = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
} as const
export type BookBlurbRating =
  (typeof BookBlurbRating)[keyof typeof BookBlurbRating]

export function BookBlurbRatingFromJSON(json: any): BookBlurbRating {
  return BookBlurbRatingFromJSONTyped(json, false)
}

export function BookBlurbRatingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookBlurbRating {
  return json as BookBlurbRating
}

export function BookBlurbRatingToJSON(value?: BookBlurbRating | null): any {
  return value as any
}
