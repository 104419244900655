// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EwiScoresResponse } from './EwiScoresResponse'
import {
  EwiScoresResponseFromJSON,
  EwiScoresResponseFromJSONTyped,
  EwiScoresResponseToJSON,
} from './EwiScoresResponse'
import type { MisspellingResponse } from './MisspellingResponse'
import {
  MisspellingResponseFromJSON,
  MisspellingResponseFromJSONTyped,
  MisspellingResponseToJSON,
} from './MisspellingResponse'

/**
 *
 * @export
 * @interface LegendFeedbacksResponse
 */
export interface LegendFeedbacksResponse {
  /**
   *
   * @type {number}
   * @memberof LegendFeedbacksResponse
   */
  wordCount: number
  /**
   *
   * @type {number}
   * @memberof LegendFeedbacksResponse
   */
  uniqueWordCount: number
  /**
   *
   * @type {number}
   * @memberof LegendFeedbacksResponse
   */
  sentenceCount: number
  /**
   *
   * @type {Array<number>}
   * @memberof LegendFeedbacksResponse
   */
  sentenceLengths: Array<number>
  /**
   *
   * @type {number}
   * @memberof LegendFeedbacksResponse
   */
  wordVariety: number
  /**
   *
   * @type {number}
   * @memberof LegendFeedbacksResponse
   */
  sentenceLengthVariety: number
  /**
   *
   * @type {number}
   * @memberof LegendFeedbacksResponse
   */
  targetWordCount: number
  /**
   *
   * @type {Array<string>}
   * @memberof LegendFeedbacksResponse
   */
  languageGems: Array<string>
  /**
   *
   * @type {Array<MisspellingResponse>}
   * @memberof LegendFeedbacksResponse
   */
  misspellings: Array<MisspellingResponse>
  /**
   *
   * @type {object}
   * @memberof LegendFeedbacksResponse
   */
  issueTypes: object
  /**
   *
   * @type {EwiScoresResponse}
   * @memberof LegendFeedbacksResponse
   */
  scores: EwiScoresResponse
}

/**
 * Check if a given object implements the LegendFeedbacksResponse interface.
 */
export function instanceOfLegendFeedbacksResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'wordCount' in value
  isInstance = isInstance && 'uniqueWordCount' in value
  isInstance = isInstance && 'sentenceCount' in value
  isInstance = isInstance && 'sentenceLengths' in value
  isInstance = isInstance && 'wordVariety' in value
  isInstance = isInstance && 'sentenceLengthVariety' in value
  isInstance = isInstance && 'targetWordCount' in value
  isInstance = isInstance && 'languageGems' in value
  isInstance = isInstance && 'misspellings' in value
  isInstance = isInstance && 'issueTypes' in value
  isInstance = isInstance && 'scores' in value

  return isInstance
}

export function LegendFeedbacksResponseFromJSON(
  json: any,
): LegendFeedbacksResponse {
  return LegendFeedbacksResponseFromJSONTyped(json, false)
}

export function LegendFeedbacksResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LegendFeedbacksResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    wordCount: json['word_count'],
    uniqueWordCount: json['unique_word_count'],
    sentenceCount: json['sentence_count'],
    sentenceLengths: json['sentence_lengths'],
    wordVariety: json['word_variety'],
    sentenceLengthVariety: json['sentence_length_variety'],
    targetWordCount: json['target_word_count'],
    languageGems: json['language_gems'],
    misspellings: (json['misspellings'] as Array<any>).map(
      MisspellingResponseFromJSON,
    ),
    issueTypes: json['issue_types'],
    scores: EwiScoresResponseFromJSON(json['scores']),
  }
}

export function LegendFeedbacksResponseToJSON(
  value?: LegendFeedbacksResponse | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    word_count: value.wordCount,
    unique_word_count: value.uniqueWordCount,
    sentence_count: value.sentenceCount,
    sentence_lengths: value.sentenceLengths,
    word_variety: value.wordVariety,
    sentence_length_variety: value.sentenceLengthVariety,
    target_word_count: value.targetWordCount,
    language_gems: value.languageGems,
    misspellings: (value.misspellings as Array<any>).map(
      MisspellingResponseToJSON,
    ),
    issue_types: value.issueTypes,
    scores: EwiScoresResponseToJSON(value.scores),
  }
}
