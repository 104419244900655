// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetSpellingByStudentWordResponseDto } from './GetSpellingByStudentWordResponseDto'
import {
  GetSpellingByStudentWordResponseDtoFromJSON,
  GetSpellingByStudentWordResponseDtoFromJSONTyped,
  GetSpellingByStudentWordResponseDtoToJSON,
} from './GetSpellingByStudentWordResponseDto'

/**
 *
 * @export
 * @interface SchoolsControllerGetSchoolSpellingByStudentWord200Response
 */
export interface SchoolsControllerGetSchoolSpellingByStudentWord200Response {
  /**
   *
   * @type {Array<GetSpellingByStudentWordResponseDto>}
   * @memberof SchoolsControllerGetSchoolSpellingByStudentWord200Response
   */
  data: Array<GetSpellingByStudentWordResponseDto>
  /**
   *
   * @type {number}
   * @memberof SchoolsControllerGetSchoolSpellingByStudentWord200Response
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof SchoolsControllerGetSchoolSpellingByStudentWord200Response
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof SchoolsControllerGetSchoolSpellingByStudentWord200Response
   */
  itemsPerPage: number
  /**
   * Additional metadata
   * @type {object}
   * @memberof SchoolsControllerGetSchoolSpellingByStudentWord200Response
   */
  meta?: object
}

/**
 * Check if a given object implements the SchoolsControllerGetSchoolSpellingByStudentWord200Response interface.
 */
export function instanceOfSchoolsControllerGetSchoolSpellingByStudentWord200Response(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'total' in value
  isInstance = isInstance && 'page' in value
  isInstance = isInstance && 'itemsPerPage' in value

  return isInstance
}

export function SchoolsControllerGetSchoolSpellingByStudentWord200ResponseFromJSON(
  json: any,
): SchoolsControllerGetSchoolSpellingByStudentWord200Response {
  return SchoolsControllerGetSchoolSpellingByStudentWord200ResponseFromJSONTyped(
    json,
    false,
  )
}

export function SchoolsControllerGetSchoolSpellingByStudentWord200ResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SchoolsControllerGetSchoolSpellingByStudentWord200Response {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(
      GetSpellingByStudentWordResponseDtoFromJSON,
    ),
    total: json['total'],
    page: json['page'],
    itemsPerPage: json['itemsPerPage'],
    meta: !exists(json, 'meta') ? undefined : json['meta'],
  }
}

export function SchoolsControllerGetSchoolSpellingByStudentWord200ResponseToJSON(
  value?: SchoolsControllerGetSchoolSpellingByStudentWord200Response | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(
      GetSpellingByStudentWordResponseDtoToJSON,
    ),
    total: value.total,
    page: value.page,
    itemsPerPage: value.itemsPerPage,
    meta: value.meta,
  }
}
