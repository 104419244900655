// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AttemptDto } from './AttemptDto'
import {
  AttemptDtoFromJSON,
  AttemptDtoFromJSONTyped,
  AttemptDtoToJSON,
} from './AttemptDto'

/**
 *
 * @export
 * @interface GetSpellingByStudentWordResponseDto
 */
export interface GetSpellingByStudentWordResponseDto {
  /**
   * The word that was spelt incorrectly
   * @type {string}
   * @memberof GetSpellingByStudentWordResponseDto
   */
  incorrectWord: string | null
  /**
   * The likely correct spelling of the word
   * @type {string}
   * @memberof GetSpellingByStudentWordResponseDto
   */
  likelyCorrectWords: string | null
  /**
   * Description of the spelling issue
   * @type {string}
   * @memberof GetSpellingByStudentWordResponseDto
   */
  spellingIssue: string | null
  /**
   * An array of attempts to spell the word correctly
   * @type {Array<AttemptDto>}
   * @memberof GetSpellingByStudentWordResponseDto
   */
  attempts: Array<AttemptDto> | null
}

/**
 * Check if a given object implements the GetSpellingByStudentWordResponseDto interface.
 */
export function instanceOfGetSpellingByStudentWordResponseDto(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'incorrectWord' in value
  isInstance = isInstance && 'likelyCorrectWords' in value
  isInstance = isInstance && 'spellingIssue' in value
  isInstance = isInstance && 'attempts' in value

  return isInstance
}

export function GetSpellingByStudentWordResponseDtoFromJSON(
  json: any,
): GetSpellingByStudentWordResponseDto {
  return GetSpellingByStudentWordResponseDtoFromJSONTyped(json, false)
}

export function GetSpellingByStudentWordResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetSpellingByStudentWordResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    incorrectWord: json['incorrectWord'],
    likelyCorrectWords: json['likelyCorrectWords'],
    spellingIssue: json['spellingIssue'],
    attempts:
      json['attempts'] === null
        ? null
        : (json['attempts'] as Array<any>).map(AttemptDtoFromJSON),
  }
}

export function GetSpellingByStudentWordResponseDtoToJSON(
  value?: GetSpellingByStudentWordResponseDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    incorrectWord: value.incorrectWord,
    likelyCorrectWords: value.likelyCorrectWords,
    spellingIssue: value.spellingIssue,
    attempts:
      value.attempts === null
        ? null
        : (value.attempts as Array<any>).map(AttemptDtoToJSON),
  }
}
