// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EwiScorerStrategies } from './EwiScorerStrategies'
import {
  EwiScorerStrategiesFromJSON,
  EwiScorerStrategiesFromJSONTyped,
  EwiScorerStrategiesToJSON,
} from './EwiScorerStrategies'

/**
 *
 * @export
 * @interface EwiDataRequest
 */
export interface EwiDataRequest {
  /**
   *
   * @type {string}
   * @memberof EwiDataRequest
   */
  transactionId?: string
  /**
   *
   * @type {string}
   * @memberof EwiDataRequest
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof EwiDataRequest
   */
  language?: string
  /**
   *
   * @type {number}
   * @memberof EwiDataRequest
   */
  targetWordCount: number
  /**
   *
   * @type {EwiScorerStrategies}
   * @memberof EwiDataRequest
   */
  scorerVersion?: EwiScorerStrategies
}

/**
 * Check if a given object implements the EwiDataRequest interface.
 */
export function instanceOfEwiDataRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'text' in value
  isInstance = isInstance && 'targetWordCount' in value

  return isInstance
}

export function EwiDataRequestFromJSON(json: any): EwiDataRequest {
  return EwiDataRequestFromJSONTyped(json, false)
}

export function EwiDataRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EwiDataRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    transactionId: !exists(json, 'transaction_id')
      ? undefined
      : json['transaction_id'],
    text: json['text'],
    language: !exists(json, 'language') ? undefined : json['language'],
    targetWordCount: json['target_word_count'],
    scorerVersion: !exists(json, 'scorer_version')
      ? undefined
      : EwiScorerStrategiesFromJSON(json['scorer_version']),
  }
}

export function EwiDataRequestToJSON(value?: EwiDataRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    transaction_id: value.transactionId,
    text: value.text,
    language: value.language,
    target_word_count: value.targetWordCount,
    scorer_version: EwiScorerStrategiesToJSON(value.scorerVersion),
  }
}
