// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { RealmItemRepresentation } from './RealmItemRepresentation'
import {
  RealmItemRepresentationFromJSON,
  RealmItemRepresentationFromJSONTyped,
  RealmItemRepresentationToJSON,
} from './RealmItemRepresentation'

/**
 *
 * @export
 * @interface StudentRealmStateSaveRequest
 */
export interface StudentRealmStateSaveRequest {
  /**
   *
   * @type {Date}
   * @memberof StudentRealmStateSaveRequest
   */
  timestamp: Date
  /**
   *
   * @type {Array<RealmItemRepresentation>}
   * @memberof StudentRealmStateSaveRequest
   */
  placedItems: Array<RealmItemRepresentation>
}

/**
 * Check if a given object implements the StudentRealmStateSaveRequest interface.
 */
export function instanceOfStudentRealmStateSaveRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'timestamp' in value
  isInstance = isInstance && 'placedItems' in value

  return isInstance
}

export function StudentRealmStateSaveRequestFromJSON(
  json: any,
): StudentRealmStateSaveRequest {
  return StudentRealmStateSaveRequestFromJSONTyped(json, false)
}

export function StudentRealmStateSaveRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentRealmStateSaveRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    timestamp: new Date(json['timestamp']),
    placedItems: (json['placedItems'] as Array<any>).map(
      RealmItemRepresentationFromJSON,
    ),
  }
}

export function StudentRealmStateSaveRequestToJSON(
  value?: StudentRealmStateSaveRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    timestamp: value.timestamp.toISOString(),
    placedItems: (value.placedItems as Array<any>).map(
      RealmItemRepresentationToJSON,
    ),
  }
}
