// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PositionRepresentation
 */
export interface PositionRepresentation {
  /**
   *
   * @type {number}
   * @memberof PositionRepresentation
   */
  x: number
  /**
   *
   * @type {number}
   * @memberof PositionRepresentation
   */
  y: number
}

/**
 * Check if a given object implements the PositionRepresentation interface.
 */
export function instanceOfPositionRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'x' in value
  isInstance = isInstance && 'y' in value

  return isInstance
}

export function PositionRepresentationFromJSON(
  json: any,
): PositionRepresentation {
  return PositionRepresentationFromJSONTyped(json, false)
}

export function PositionRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PositionRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    x: json['x'],
    y: json['y'],
  }
}

export function PositionRepresentationToJSON(
  value?: PositionRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    x: value.x,
    y: value.y,
  }
}
