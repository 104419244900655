// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AvatarRepresentation } from './AvatarRepresentation'
import {
  AvatarRepresentationFromJSON,
  AvatarRepresentationFromJSONTyped,
  AvatarRepresentationToJSON,
} from './AvatarRepresentation'

/**
 *
 * @export
 * @interface AvatarRepresentationBaseApiListResult
 */
export interface AvatarRepresentationBaseApiListResult {
  /**
   *
   * @type {Array<AvatarRepresentation>}
   * @memberof AvatarRepresentationBaseApiListResult
   */
  data: Array<AvatarRepresentation>
  /**
   *
   * @type {number}
   * @memberof AvatarRepresentationBaseApiListResult
   */
  total: number
}

/**
 * Check if a given object implements the AvatarRepresentationBaseApiListResult interface.
 */
export function instanceOfAvatarRepresentationBaseApiListResult(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'total' in value

  return isInstance
}

export function AvatarRepresentationBaseApiListResultFromJSON(
  json: any,
): AvatarRepresentationBaseApiListResult {
  return AvatarRepresentationBaseApiListResultFromJSONTyped(json, false)
}

export function AvatarRepresentationBaseApiListResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AvatarRepresentationBaseApiListResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(AvatarRepresentationFromJSON),
    total: json['total'],
  }
}

export function AvatarRepresentationBaseApiListResultToJSON(
  value?: AvatarRepresentationBaseApiListResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(AvatarRepresentationToJSON),
    total: value.total,
  }
}
