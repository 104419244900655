// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  RealmItemRepresentationListBaseApiResult,
  StudentInventoryCategory,
  StudentInventoryPurchaseRepresentation,
  StudentPurchaseRequestV2,
  StudentPurchasedItemRepresentationBaseApiListResult,
  StudentRealmItemPurchaseRequest,
  StudentRealmStateSaveRequest,
} from '../models/index'
import {
  RealmItemRepresentationListBaseApiResultFromJSON,
  RealmItemRepresentationListBaseApiResultToJSON,
  StudentInventoryCategoryFromJSON,
  StudentInventoryCategoryToJSON,
  StudentInventoryPurchaseRepresentationFromJSON,
  StudentInventoryPurchaseRepresentationToJSON,
  StudentPurchaseRequestV2FromJSON,
  StudentPurchaseRequestV2ToJSON,
  StudentPurchasedItemRepresentationBaseApiListResultFromJSON,
  StudentPurchasedItemRepresentationBaseApiListResultToJSON,
  StudentRealmItemPurchaseRequestFromJSON,
  StudentRealmItemPurchaseRequestToJSON,
  StudentRealmStateSaveRequestFromJSON,
  StudentRealmStateSaveRequestToJSON,
} from '../models/index'

export interface ApiV1InventoriesStudentIdCategoriesRealmsPurchasesPostRequest {
  studentId: string
  studentPurchaseRequestV2?: StudentPurchaseRequestV2
}

export interface ApiV1InventoriesStudentIdPurchasesGetRequest {
  studentId: string
  category?: StudentInventoryCategory
}

export interface ApiV1InventoriesStudentIdRealmsRealmIdPurchasesPostRequest {
  studentId: string
  realmId: string
  studentRealmItemPurchaseRequest?: StudentRealmItemPurchaseRequest
}

export interface ApiV1InventoriesStudentIdRealmsRealmIdStateGetRequest {
  studentId: string
  realmId: string
}

export interface ApiV1InventoriesStudentIdRealmsRealmIdStatePutRequest {
  studentId: string
  realmId: string
  studentRealmStateSaveRequest?: StudentRealmStateSaveRequest
}

/**
 *
 */
export class InventoriesApi extends runtime.BaseAPI {
  /**
   * Purchase a realm (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdCategoriesRealmsPurchasesPostRaw(
    requestParameters: ApiV1InventoriesStudentIdCategoriesRealmsPurchasesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentInventoryPurchaseRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1InventoriesStudentIdCategoriesRealmsPurchasesPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/inventories/{studentId}/categories/realms/purchases`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: StudentPurchaseRequestV2ToJSON(
          requestParameters.studentPurchaseRequestV2,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentInventoryPurchaseRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Purchase a realm (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdCategoriesRealmsPurchasesPost(
    requestParameters: ApiV1InventoriesStudentIdCategoriesRealmsPurchasesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentInventoryPurchaseRepresentation> {
    const response =
      await this.apiV1InventoriesStudentIdCategoriesRealmsPurchasesPostRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdPurchasesGetRaw(
    requestParameters: ApiV1InventoriesStudentIdPurchasesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<StudentPurchasedItemRepresentationBaseApiListResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1InventoriesStudentIdPurchasesGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.category !== undefined) {
      queryParameters['category'] = requestParameters.category
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/inventories/{studentId}/purchases`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentPurchasedItemRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdPurchasesGet(
    requestParameters: ApiV1InventoriesStudentIdPurchasesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentPurchasedItemRepresentationBaseApiListResult> {
    const response = await this.apiV1InventoriesStudentIdPurchasesGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Purchase a realm item (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdRealmsRealmIdPurchasesPostRaw(
    requestParameters: ApiV1InventoriesStudentIdRealmsRealmIdPurchasesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentInventoryPurchaseRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1InventoriesStudentIdRealmsRealmIdPurchasesPost.',
      )
    }

    if (
      requestParameters.realmId === null ||
      requestParameters.realmId === undefined
    ) {
      throw new runtime.RequiredError(
        'realmId',
        'Required parameter requestParameters.realmId was null or undefined when calling apiV1InventoriesStudentIdRealmsRealmIdPurchasesPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/inventories/{studentId}/realms/{realmId}/purchases`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'realmId'}}`,
            encodeURIComponent(String(requestParameters.realmId)),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: StudentRealmItemPurchaseRequestToJSON(
          requestParameters.studentRealmItemPurchaseRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentInventoryPurchaseRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Purchase a realm item (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdRealmsRealmIdPurchasesPost(
    requestParameters: ApiV1InventoriesStudentIdRealmsRealmIdPurchasesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentInventoryPurchaseRepresentation> {
    const response =
      await this.apiV1InventoriesStudentIdRealmsRealmIdPurchasesPostRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Get the realm state (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdRealmsRealmIdStateGetRaw(
    requestParameters: ApiV1InventoriesStudentIdRealmsRealmIdStateGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RealmItemRepresentationListBaseApiResult>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1InventoriesStudentIdRealmsRealmIdStateGet.',
      )
    }

    if (
      requestParameters.realmId === null ||
      requestParameters.realmId === undefined
    ) {
      throw new runtime.RequiredError(
        'realmId',
        'Required parameter requestParameters.realmId was null or undefined when calling apiV1InventoriesStudentIdRealmsRealmIdStateGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/inventories/{studentId}/realms/{realmId}/state`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'realmId'}}`,
            encodeURIComponent(String(requestParameters.realmId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RealmItemRepresentationListBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   * Get the realm state (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdRealmsRealmIdStateGet(
    requestParameters: ApiV1InventoriesStudentIdRealmsRealmIdStateGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RealmItemRepresentationListBaseApiResult> {
    const response =
      await this.apiV1InventoriesStudentIdRealmsRealmIdStateGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Save realm state (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdRealmsRealmIdStatePutRaw(
    requestParameters: ApiV1InventoriesStudentIdRealmsRealmIdStatePutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1InventoriesStudentIdRealmsRealmIdStatePut.',
      )
    }

    if (
      requestParameters.realmId === null ||
      requestParameters.realmId === undefined
    ) {
      throw new runtime.RequiredError(
        'realmId',
        'Required parameter requestParameters.realmId was null or undefined when calling apiV1InventoriesStudentIdRealmsRealmIdStatePut.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/inventories/{studentId}/realms/{realmId}/state`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'realmId'}}`,
            encodeURIComponent(String(requestParameters.realmId)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: StudentRealmStateSaveRequestToJSON(
          requestParameters.studentRealmStateSaveRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Save realm state (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1InventoriesStudentIdRealmsRealmIdStatePut(
    requestParameters: ApiV1InventoriesStudentIdRealmsRealmIdStatePutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.apiV1InventoriesStudentIdRealmsRealmIdStatePutRaw(
      requestParameters,
      initOverrides,
    )
  }
}
