// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityCatalogue } from './ActivityCatalogue'
import {
  ActivityCatalogueFromJSON,
  ActivityCatalogueFromJSONTyped,
  ActivityCatalogueToJSON,
} from './ActivityCatalogue'

/**
 *
 * @export
 * @interface ActivityCatalogueBaseApiResult
 */
export interface ActivityCatalogueBaseApiResult {
  /**
   *
   * @type {ActivityCatalogue}
   * @memberof ActivityCatalogueBaseApiResult
   */
  data?: ActivityCatalogue
}

/**
 * Check if a given object implements the ActivityCatalogueBaseApiResult interface.
 */
export function instanceOfActivityCatalogueBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ActivityCatalogueBaseApiResultFromJSON(
  json: any,
): ActivityCatalogueBaseApiResult {
  return ActivityCatalogueBaseApiResultFromJSONTyped(json, false)
}

export function ActivityCatalogueBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityCatalogueBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : ActivityCatalogueFromJSON(json['data']),
  }
}

export function ActivityCatalogueBaseApiResultToJSON(
  value?: ActivityCatalogueBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: ActivityCatalogueToJSON(value.data),
  }
}
