// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentNewWordResponseDto
 */
export interface StudentNewWordResponseDto {
  /**
   * The word used by the student
   * @type {string}
   * @memberof StudentNewWordResponseDto
   */
  word: string | null
  /**
   * The example usage of the word in a sentence
   * @type {string}
   * @memberof StudentNewWordResponseDto
   */
  sentenceOfWord: string | null
  /**
   * The CUID for the activity result snapshot
   * @type {string}
   * @memberof StudentNewWordResponseDto
   */
  activityResultSnapshotId: string | null
}

/**
 * Check if a given object implements the StudentNewWordResponseDto interface.
 */
export function instanceOfStudentNewWordResponseDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'word' in value
  isInstance = isInstance && 'sentenceOfWord' in value
  isInstance = isInstance && 'activityResultSnapshotId' in value

  return isInstance
}

export function StudentNewWordResponseDtoFromJSON(
  json: any,
): StudentNewWordResponseDto {
  return StudentNewWordResponseDtoFromJSONTyped(json, false)
}

export function StudentNewWordResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentNewWordResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    word: json['Word'],
    sentenceOfWord: json['SentenceOfWord'],
    activityResultSnapshotId: json['ActivityResultSnapshotId'],
  }
}

export function StudentNewWordResponseDtoToJSON(
  value?: StudentNewWordResponseDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Word: value.word,
    SentenceOfWord: value.sentenceOfWord,
    ActivityResultSnapshotId: value.activityResultSnapshotId,
  }
}
