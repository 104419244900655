// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentPurchaseRequestV2
 */
export interface StudentPurchaseRequestV2 {
  /**
   *
   * @type {string}
   * @memberof StudentPurchaseRequestV2
   */
  eventId?: string
  /**
   *
   * @type {string}
   * @memberof StudentPurchaseRequestV2
   */
  itemId?: string
}

/**
 * Check if a given object implements the StudentPurchaseRequestV2 interface.
 */
export function instanceOfStudentPurchaseRequestV2(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function StudentPurchaseRequestV2FromJSON(
  json: any,
): StudentPurchaseRequestV2 {
  return StudentPurchaseRequestV2FromJSONTyped(json, false)
}

export function StudentPurchaseRequestV2FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentPurchaseRequestV2 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    eventId: !exists(json, 'eventId') ? undefined : json['eventId'],
    itemId: !exists(json, 'itemId') ? undefined : json['itemId'],
  }
}

export function StudentPurchaseRequestV2ToJSON(
  value?: StudentPurchaseRequestV2 | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    eventId: value.eventId,
    itemId: value.itemId,
  }
}
