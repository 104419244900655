// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { LatestSubmittedSnapshotFeedbackRepresentation } from './LatestSubmittedSnapshotFeedbackRepresentation'
import {
  LatestSubmittedSnapshotFeedbackRepresentationFromJSON,
  LatestSubmittedSnapshotFeedbackRepresentationFromJSONTyped,
  LatestSubmittedSnapshotFeedbackRepresentationToJSON,
} from './LatestSubmittedSnapshotFeedbackRepresentation'

/**
 *
 * @export
 * @interface LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult
 */
export interface LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult {
  /**
   *
   * @type {Array<LatestSubmittedSnapshotFeedbackRepresentation>}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult
   */
  data: Array<LatestSubmittedSnapshotFeedbackRepresentation>
  /**
   *
   * @type {number}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult
   */
  total: number
}

/**
 * Check if a given object implements the LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult interface.
 */
export function instanceOfLatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'total' in value

  return isInstance
}

export function LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResultFromJSON(
  json: any,
): LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult {
  return LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResultFromJSONTyped(
    json,
    false,
  )
}

export function LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(
      LatestSubmittedSnapshotFeedbackRepresentationFromJSON,
    ),
    total: json['total'],
  }
}

export function LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResultToJSON(
  value?: LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(
      LatestSubmittedSnapshotFeedbackRepresentationToJSON,
    ),
    total: value.total,
  }
}
