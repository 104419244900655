// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const EwiScorerStrategies = {
  LfV1: 'lf_v1',
  LfV2: 'lf_v2',
  PoetryV1: 'poetry_v1',
  QwV1: 'qw_v1',
  QwV2: 'qw_v2',
} as const
export type EwiScorerStrategies =
  (typeof EwiScorerStrategies)[keyof typeof EwiScorerStrategies]

export function EwiScorerStrategiesFromJSON(json: any): EwiScorerStrategies {
  return EwiScorerStrategiesFromJSONTyped(json, false)
}

export function EwiScorerStrategiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EwiScorerStrategies {
  return json as EwiScorerStrategies
}

export function EwiScorerStrategiesToJSON(
  value?: EwiScorerStrategies | null,
): any {
  return value as any
}
