// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SimpleSchoolRepresentation
 */
export interface SimpleSchoolRepresentation {
  /**
   *
   * @type {string}
   * @memberof SimpleSchoolRepresentation
   */
  schoolId: string
  /**
   *
   * @type {number}
   * @memberof SimpleSchoolRepresentation
   */
  gradeSetId: number
  /**
   *
   * @type {number}
   * @memberof SimpleSchoolRepresentation
   */
  blakeSchoolId: number
  /**
   *
   * @type {string}
   * @memberof SimpleSchoolRepresentation
   */
  schoolCode: string
  /**
   *
   * @type {string}
   * @memberof SimpleSchoolRepresentation
   */
  schoolName: string
  /**
   *
   * @type {string}
   * @memberof SimpleSchoolRepresentation
   */
  countryCode: string
}

/**
 * Check if a given object implements the SimpleSchoolRepresentation interface.
 */
export function instanceOfSimpleSchoolRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'schoolId' in value
  isInstance = isInstance && 'gradeSetId' in value
  isInstance = isInstance && 'blakeSchoolId' in value
  isInstance = isInstance && 'schoolCode' in value
  isInstance = isInstance && 'schoolName' in value
  isInstance = isInstance && 'countryCode' in value

  return isInstance
}

export function SimpleSchoolRepresentationFromJSON(
  json: any,
): SimpleSchoolRepresentation {
  return SimpleSchoolRepresentationFromJSONTyped(json, false)
}

export function SimpleSchoolRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SimpleSchoolRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    schoolId: json['school_id'],
    gradeSetId: json['grade_set_id'],
    blakeSchoolId: json['blake_school_id'],
    schoolCode: json['school_code'],
    schoolName: json['school_name'],
    countryCode: json['country_code'],
  }
}

export function SimpleSchoolRepresentationToJSON(
  value?: SimpleSchoolRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    school_id: value.schoolId,
    grade_set_id: value.gradeSetId,
    blake_school_id: value.blakeSchoolId,
    school_code: value.schoolCode,
    school_name: value.schoolName,
    country_code: value.countryCode,
  }
}
