// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentLessonResultRepresentationV2
 */
export interface StudentLessonResultRepresentationV2 {
  /**
   *
   * @type {string}
   * @memberof StudentLessonResultRepresentationV2
   */
  eventId: string
  /**
   *
   * @type {string}
   * @memberof StudentLessonResultRepresentationV2
   */
  studentId: string
  /**
   *
   * @type {string}
   * @memberof StudentLessonResultRepresentationV2
   */
  activityId: string
  /**
   *
   * @type {number}
   * @memberof StudentLessonResultRepresentationV2
   */
  attemptCount: number
  /**
   *
   * @type {boolean}
   * @memberof StudentLessonResultRepresentationV2
   */
  isPassed: boolean
  /**
   *
   * @type {string}
   * @memberof StudentLessonResultRepresentationV2
   */
  highestPassedActivityId: string | null
}

/**
 * Check if a given object implements the StudentLessonResultRepresentationV2 interface.
 */
export function instanceOfStudentLessonResultRepresentationV2(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'eventId' in value
  isInstance = isInstance && 'studentId' in value
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'attemptCount' in value
  isInstance = isInstance && 'isPassed' in value
  isInstance = isInstance && 'highestPassedActivityId' in value

  return isInstance
}

export function StudentLessonResultRepresentationV2FromJSON(
  json: any,
): StudentLessonResultRepresentationV2 {
  return StudentLessonResultRepresentationV2FromJSONTyped(json, false)
}

export function StudentLessonResultRepresentationV2FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentLessonResultRepresentationV2 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    eventId: json['event_id'],
    studentId: json['student_id'],
    activityId: json['activity_id'],
    attemptCount: json['attempt_count'],
    isPassed: json['is_passed'],
    highestPassedActivityId: json['highest_passed_activity_id'],
  }
}

export function StudentLessonResultRepresentationV2ToJSON(
  value?: StudentLessonResultRepresentationV2 | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    event_id: value.eventId,
    student_id: value.studentId,
    activity_id: value.activityId,
    attempt_count: value.attemptCount,
    is_passed: value.isPassed,
    highest_passed_activity_id: value.highestPassedActivityId,
  }
}
