// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  BookBlurbBookSearchResult,
  BookBlurbRepresentation,
  BookBlurbRepresentationBaseApiListResult,
  BookBlurbsPutRequestV2,
  BookBlurbsRepresentation,
  ClassroomBookBlurbPreferencesRepresentation,
  ClassroomBookBlurbRatingsRepresentation,
  ClassroomBookBlurbsRepresentationBaseApiListResult,
  ClassroomWowWordCountRepresentation,
} from '../models/index'
import {
  BookBlurbBookSearchResultFromJSON,
  BookBlurbBookSearchResultToJSON,
  BookBlurbRepresentationFromJSON,
  BookBlurbRepresentationToJSON,
  BookBlurbRepresentationBaseApiListResultFromJSON,
  BookBlurbRepresentationBaseApiListResultToJSON,
  BookBlurbsPutRequestV2FromJSON,
  BookBlurbsPutRequestV2ToJSON,
  BookBlurbsRepresentationFromJSON,
  BookBlurbsRepresentationToJSON,
  ClassroomBookBlurbPreferencesRepresentationFromJSON,
  ClassroomBookBlurbPreferencesRepresentationToJSON,
  ClassroomBookBlurbRatingsRepresentationFromJSON,
  ClassroomBookBlurbRatingsRepresentationToJSON,
  ClassroomBookBlurbsRepresentationBaseApiListResultFromJSON,
  ClassroomBookBlurbsRepresentationBaseApiListResultToJSON,
  ClassroomWowWordCountRepresentationFromJSON,
  ClassroomWowWordCountRepresentationToJSON,
} from '../models/index'

export interface ApiV1BooksClassroomsClassroomIdActivitiesActivityIdRatingsGetRequest {
  activityId: string
  classroomId: string
}

export interface ApiV1BooksClassroomsClassroomIdBookblurbsGetRequest {
  classroomId: string
  skip?: number
  take?: number
}

export interface ApiV1BooksClassroomsClassroomIdPreferencesGetRequest {
  classroomId: string
}

export interface ApiV1BooksClassroomsClassroomIdWowWordsGetRequest {
  classroomId: string
}

export interface ApiV1BooksStudentIdActivitiesActivityIdBookblurbsPutRequest {
  studentId: string
  activityId: string
  classroomId?: string
  bookBlurbsPutRequestV2?: BookBlurbsPutRequestV2
}

export interface ApiV1BooksStudentIdActivitiesActivityIdLastBookblurbGetRequest {
  studentId: string
  activityId: string
}

export interface ApiV1BooksStudentIdActivitiesActivityIdPopularWordsGetRequest {
  studentId: string
  activityId: string
}

export interface ApiV1BooksStudentIdBookblurbsGetRequest {
  studentId: string
  skip?: number
  take?: number
}

export interface ApiV1BooksStudentIdBookblurbsLatestGetRequest {
  studentId: string
  skip?: number
  take?: number
}

export interface ApiV1BooksStudentsGetRequest {
  studentIds?: Array<string>
  fromDate?: number
  toDate?: number
}

export interface ApiV1BooksWowwordbooksearchGetRequest {
  words?: Array<string>
  skip?: number
  take?: number
}

/**
 *
 */
export class BooksApi extends runtime.BaseAPI {
  /**
   * Get book blurb ratings for a classroom (Auth policies: ClassroomResourceRequirement) (Auth policies: ClassroomResourceRequirement)
   */
  async apiV1BooksClassroomsClassroomIdActivitiesActivityIdRatingsGetRaw(
    requestParameters: ApiV1BooksClassroomsClassroomIdActivitiesActivityIdRatingsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ClassroomBookBlurbRatingsRepresentation>> {
    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1BooksClassroomsClassroomIdActivitiesActivityIdRatingsGet.',
      )
    }

    if (
      requestParameters.classroomId === null ||
      requestParameters.classroomId === undefined
    ) {
      throw new runtime.RequiredError(
        'classroomId',
        'Required parameter requestParameters.classroomId was null or undefined when calling apiV1BooksClassroomsClassroomIdActivitiesActivityIdRatingsGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/classrooms/{classroomId}/activities/{activityId}/ratings`
          .replace(
            `{${'activityId'}}`,
            encodeURIComponent(String(requestParameters.activityId)),
          )
          .replace(
            `{${'classroomId'}}`,
            encodeURIComponent(String(requestParameters.classroomId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ClassroomBookBlurbRatingsRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Get book blurb ratings for a classroom (Auth policies: ClassroomResourceRequirement) (Auth policies: ClassroomResourceRequirement)
   */
  async apiV1BooksClassroomsClassroomIdActivitiesActivityIdRatingsGet(
    requestParameters: ApiV1BooksClassroomsClassroomIdActivitiesActivityIdRatingsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ClassroomBookBlurbRatingsRepresentation> {
    const response =
      await this.apiV1BooksClassroomsClassroomIdActivitiesActivityIdRatingsGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Get bookblurbs for a classroom (Auth policies: ClassroomResourceRequirement) (Auth policies: ClassroomResourceRequirement)
   */
  async apiV1BooksClassroomsClassroomIdBookblurbsGetRaw(
    requestParameters: ApiV1BooksClassroomsClassroomIdBookblurbsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<ClassroomBookBlurbsRepresentationBaseApiListResult>
  > {
    if (
      requestParameters.classroomId === null ||
      requestParameters.classroomId === undefined
    ) {
      throw new runtime.RequiredError(
        'classroomId',
        'Required parameter requestParameters.classroomId was null or undefined when calling apiV1BooksClassroomsClassroomIdBookblurbsGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/classrooms/{classroomId}/bookblurbs`.replace(
          `{${'classroomId'}}`,
          encodeURIComponent(String(requestParameters.classroomId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ClassroomBookBlurbsRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   * Get bookblurbs for a classroom (Auth policies: ClassroomResourceRequirement) (Auth policies: ClassroomResourceRequirement)
   */
  async apiV1BooksClassroomsClassroomIdBookblurbsGet(
    requestParameters: ApiV1BooksClassroomsClassroomIdBookblurbsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ClassroomBookBlurbsRepresentationBaseApiListResult> {
    const response = await this.apiV1BooksClassroomsClassroomIdBookblurbsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Get book blurb preferences for a classroom (Auth policies: ClassroomResourceRequirement) (Auth policies: ClassroomResourceRequirement)
   */
  async apiV1BooksClassroomsClassroomIdPreferencesGetRaw(
    requestParameters: ApiV1BooksClassroomsClassroomIdPreferencesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<Array<ClassroomBookBlurbPreferencesRepresentation>>
  > {
    if (
      requestParameters.classroomId === null ||
      requestParameters.classroomId === undefined
    ) {
      throw new runtime.RequiredError(
        'classroomId',
        'Required parameter requestParameters.classroomId was null or undefined when calling apiV1BooksClassroomsClassroomIdPreferencesGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/classrooms/{classroomId}/preferences`.replace(
          `{${'classroomId'}}`,
          encodeURIComponent(String(requestParameters.classroomId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ClassroomBookBlurbPreferencesRepresentationFromJSON),
    )
  }

  /**
   * Get book blurb preferences for a classroom (Auth policies: ClassroomResourceRequirement) (Auth policies: ClassroomResourceRequirement)
   */
  async apiV1BooksClassroomsClassroomIdPreferencesGet(
    requestParameters: ApiV1BooksClassroomsClassroomIdPreferencesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ClassroomBookBlurbPreferencesRepresentation>> {
    const response =
      await this.apiV1BooksClassroomsClassroomIdPreferencesGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Get wow words for a classroom (Auth policies: ClassroomResourceRequirement) (Auth policies: ClassroomResourceRequirement)
   */
  async apiV1BooksClassroomsClassroomIdWowWordsGetRaw(
    requestParameters: ApiV1BooksClassroomsClassroomIdWowWordsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ClassroomWowWordCountRepresentation>> {
    if (
      requestParameters.classroomId === null ||
      requestParameters.classroomId === undefined
    ) {
      throw new runtime.RequiredError(
        'classroomId',
        'Required parameter requestParameters.classroomId was null or undefined when calling apiV1BooksClassroomsClassroomIdWowWordsGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/classrooms/{classroomId}/wow_words`.replace(
          `{${'classroomId'}}`,
          encodeURIComponent(String(requestParameters.classroomId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ClassroomWowWordCountRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Get wow words for a classroom (Auth policies: ClassroomResourceRequirement) (Auth policies: ClassroomResourceRequirement)
   */
  async apiV1BooksClassroomsClassroomIdWowWordsGet(
    requestParameters: ApiV1BooksClassroomsClassroomIdWowWordsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ClassroomWowWordCountRepresentation> {
    const response = await this.apiV1BooksClassroomsClassroomIdWowWordsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Submit a new bookblurb (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdActivitiesActivityIdBookblurbsPutRaw(
    requestParameters: ApiV1BooksStudentIdActivitiesActivityIdBookblurbsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1BooksStudentIdActivitiesActivityIdBookblurbsPut.',
      )
    }

    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1BooksStudentIdActivitiesActivityIdBookblurbsPut.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.classroomId !== undefined) {
      queryParameters['classroomId'] = requestParameters.classroomId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/{studentId}/activities/{activityId}/bookblurbs`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityId'}}`,
            encodeURIComponent(String(requestParameters.activityId)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: BookBlurbsPutRequestV2ToJSON(
          requestParameters.bookBlurbsPutRequestV2,
        ),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Submit a new bookblurb (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdActivitiesActivityIdBookblurbsPut(
    requestParameters: ApiV1BooksStudentIdActivitiesActivityIdBookblurbsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.apiV1BooksStudentIdActivitiesActivityIdBookblurbsPutRaw(
      requestParameters,
      initOverrides,
    )
  }

  /**
   * Get a BookBlurb for a student and activity (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdActivitiesActivityIdLastBookblurbGetRaw(
    requestParameters: ApiV1BooksStudentIdActivitiesActivityIdLastBookblurbGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BookBlurbRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1BooksStudentIdActivitiesActivityIdLastBookblurbGet.',
      )
    }

    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1BooksStudentIdActivitiesActivityIdLastBookblurbGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/{studentId}/activities/{activityId}/last_bookblurb`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityId'}}`,
            encodeURIComponent(String(requestParameters.activityId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookBlurbRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Get a BookBlurb for a student and activity (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdActivitiesActivityIdLastBookblurbGet(
    requestParameters: ApiV1BooksStudentIdActivitiesActivityIdLastBookblurbGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BookBlurbRepresentation> {
    const response =
      await this.apiV1BooksStudentIdActivitiesActivityIdLastBookblurbGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Get popular words for a book (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdActivitiesActivityIdPopularWordsGetRaw(
    requestParameters: ApiV1BooksStudentIdActivitiesActivityIdPopularWordsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: number }>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1BooksStudentIdActivitiesActivityIdPopularWordsGet.',
      )
    }

    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1BooksStudentIdActivitiesActivityIdPopularWordsGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/{studentId}/activities/{activityId}/popular_words`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityId'}}`,
            encodeURIComponent(String(requestParameters.activityId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * Get popular words for a book (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdActivitiesActivityIdPopularWordsGet(
    requestParameters: ApiV1BooksStudentIdActivitiesActivityIdPopularWordsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: number }> {
    const response =
      await this.apiV1BooksStudentIdActivitiesActivityIdPopularWordsGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Gets BookBlurbs for a student in last updated date-descending order (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdBookblurbsGetRaw(
    requestParameters: ApiV1BooksStudentIdBookblurbsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BookBlurbRepresentationBaseApiListResult>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1BooksStudentIdBookblurbsGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/{studentId}/bookblurbs`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookBlurbRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   * Gets BookBlurbs for a student in last updated date-descending order (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdBookblurbsGet(
    requestParameters: ApiV1BooksStudentIdBookblurbsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BookBlurbRepresentationBaseApiListResult> {
    const response = await this.apiV1BooksStudentIdBookblurbsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Gets the latest book blurbs and wow words (older attempts are not returned) (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdBookblurbsLatestGetRaw(
    requestParameters: ApiV1BooksStudentIdBookblurbsLatestGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BookBlurbRepresentationBaseApiListResult>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1BooksStudentIdBookblurbsLatestGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/{studentId}/bookblurbs/latest`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookBlurbRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   * Gets the latest book blurbs and wow words (older attempts are not returned) (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1BooksStudentIdBookblurbsLatestGet(
    requestParameters: ApiV1BooksStudentIdBookblurbsLatestGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BookBlurbRepresentationBaseApiListResult> {
    const response = await this.apiV1BooksStudentIdBookblurbsLatestGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Get BookBlurb ny studentIds (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1BooksStudentsGetRaw(
    requestParameters: ApiV1BooksStudentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<BookBlurbsRepresentation>>> {
    const queryParameters: any = {}

    if (requestParameters.studentIds) {
      queryParameters['studentIds'] = requestParameters.studentIds
    }

    if (requestParameters.fromDate !== undefined) {
      queryParameters['fromDate'] = requestParameters.fromDate
    }

    if (requestParameters.toDate !== undefined) {
      queryParameters['toDate'] = requestParameters.toDate
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/students`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(BookBlurbsRepresentationFromJSON),
    )
  }

  /**
   * Get BookBlurb ny studentIds (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1BooksStudentsGet(
    requestParameters: ApiV1BooksStudentsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<BookBlurbsRepresentation>> {
    const response = await this.apiV1BooksStudentsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Full-text searches Block.Content for Book-Blurb Activities (Auth) (Auth)
   */
  async apiV1BooksWowwordbooksearchGetRaw(
    requestParameters: ApiV1BooksWowwordbooksearchGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BookBlurbBookSearchResult>> {
    const queryParameters: any = {}

    if (requestParameters.words) {
      queryParameters['words'] = requestParameters.words
    }

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/books/wowwordbooksearch`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookBlurbBookSearchResultFromJSON(jsonValue),
    )
  }

  /**
   * Full-text searches Block.Content for Book-Blurb Activities (Auth) (Auth)
   */
  async apiV1BooksWowwordbooksearchGet(
    requestParameters: ApiV1BooksWowwordbooksearchGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BookBlurbBookSearchResult> {
    const response = await this.apiV1BooksWowwordbooksearchGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
