// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { RecentStudentBookBlurb } from './RecentStudentBookBlurb'
import {
  RecentStudentBookBlurbFromJSON,
  RecentStudentBookBlurbFromJSONTyped,
  RecentStudentBookBlurbToJSON,
} from './RecentStudentBookBlurb'

/**
 *
 * @export
 * @interface ClassroomBookBlurbPreferencesRepresentation
 */
export interface ClassroomBookBlurbPreferencesRepresentation {
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbPreferencesRepresentation
   */
  studentId?: string | null
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbPreferencesRepresentation
   */
  firstName?: string | null
  /**
   *
   * @type {string}
   * @memberof ClassroomBookBlurbPreferencesRepresentation
   */
  lastName?: string | null
  /**
   *
   * @type {number}
   * @memberof ClassroomBookBlurbPreferencesRepresentation
   */
  ratingHighCountAllTime?: number
  /**
   *
   * @type {number}
   * @memberof ClassroomBookBlurbPreferencesRepresentation
   */
  ratingMedCountAllTime?: number
  /**
   *
   * @type {number}
   * @memberof ClassroomBookBlurbPreferencesRepresentation
   */
  ratingLowCountAllTime?: number
  /**
   *
   * @type {Array<RecentStudentBookBlurb>}
   * @memberof ClassroomBookBlurbPreferencesRepresentation
   */
  recentBooksReviewed?: Array<RecentStudentBookBlurb> | null
}

/**
 * Check if a given object implements the ClassroomBookBlurbPreferencesRepresentation interface.
 */
export function instanceOfClassroomBookBlurbPreferencesRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ClassroomBookBlurbPreferencesRepresentationFromJSON(
  json: any,
): ClassroomBookBlurbPreferencesRepresentation {
  return ClassroomBookBlurbPreferencesRepresentationFromJSONTyped(json, false)
}

export function ClassroomBookBlurbPreferencesRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ClassroomBookBlurbPreferencesRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    ratingHighCountAllTime: !exists(json, 'rating_high_count_all_time')
      ? undefined
      : json['rating_high_count_all_time'],
    ratingMedCountAllTime: !exists(json, 'rating_med_count_all_time')
      ? undefined
      : json['rating_med_count_all_time'],
    ratingLowCountAllTime: !exists(json, 'rating_low_count_all_time')
      ? undefined
      : json['rating_low_count_all_time'],
    recentBooksReviewed: !exists(json, 'recent_books_reviewed')
      ? undefined
      : json['recent_books_reviewed'] === null
        ? null
        : (json['recent_books_reviewed'] as Array<any>).map(
            RecentStudentBookBlurbFromJSON,
          ),
  }
}

export function ClassroomBookBlurbPreferencesRepresentationToJSON(
  value?: ClassroomBookBlurbPreferencesRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    student_id: value.studentId,
    first_name: value.firstName,
    last_name: value.lastName,
    rating_high_count_all_time: value.ratingHighCountAllTime,
    rating_med_count_all_time: value.ratingMedCountAllTime,
    rating_low_count_all_time: value.ratingLowCountAllTime,
    recent_books_reviewed:
      value.recentBooksReviewed === undefined
        ? undefined
        : value.recentBooksReviewed === null
          ? null
          : (value.recentBooksReviewed as Array<any>).map(
              RecentStudentBookBlurbToJSON,
            ),
  }
}
