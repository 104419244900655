// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StudentInventoryCategory } from './StudentInventoryCategory'
import {
  StudentInventoryCategoryFromJSON,
  StudentInventoryCategoryFromJSONTyped,
  StudentInventoryCategoryToJSON,
} from './StudentInventoryCategory'

/**
 *
 * @export
 * @interface StudentInventoryPurchaseRepresentation
 */
export interface StudentInventoryPurchaseRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentInventoryPurchaseRepresentation
   */
  studentInventoryId?: string
  /**
   *
   * @type {string}
   * @memberof StudentInventoryPurchaseRepresentation
   */
  eventId?: string
  /**
   *
   * @type {StudentInventoryCategory}
   * @memberof StudentInventoryPurchaseRepresentation
   */
  category?: StudentInventoryCategory
  /**
   *
   * @type {string}
   * @memberof StudentInventoryPurchaseRepresentation
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof StudentInventoryPurchaseRepresentation
   */
  coins?: number
}

/**
 * Check if a given object implements the StudentInventoryPurchaseRepresentation interface.
 */
export function instanceOfStudentInventoryPurchaseRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function StudentInventoryPurchaseRepresentationFromJSON(
  json: any,
): StudentInventoryPurchaseRepresentation {
  return StudentInventoryPurchaseRepresentationFromJSONTyped(json, false)
}

export function StudentInventoryPurchaseRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentInventoryPurchaseRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentInventoryId: !exists(json, 'studentInventoryId')
      ? undefined
      : json['studentInventoryId'],
    eventId: !exists(json, 'eventId') ? undefined : json['eventId'],
    category: !exists(json, 'category')
      ? undefined
      : StudentInventoryCategoryFromJSON(json['category']),
    id: !exists(json, 'id') ? undefined : json['id'],
    coins: !exists(json, 'coins') ? undefined : json['coins'],
  }
}

export function StudentInventoryPurchaseRepresentationToJSON(
  value?: StudentInventoryPurchaseRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentInventoryId: value.studentInventoryId,
    eventId: value.eventId,
    category: StudentInventoryCategoryToJSON(value.category),
    id: value.id,
    coins: value.coins,
  }
}
