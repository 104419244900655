// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BookBlurbBook } from './BookBlurbBook'
import {
  BookBlurbBookFromJSON,
  BookBlurbBookFromJSONTyped,
  BookBlurbBookToJSON,
} from './BookBlurbBook'

/**
 *
 * @export
 * @interface BookBlurbBookSearchResult
 */
export interface BookBlurbBookSearchResult {
  /**
   *
   * @type {number}
   * @memberof BookBlurbBookSearchResult
   */
  totalCount?: number
  /**
   *
   * @type {Array<BookBlurbBook>}
   * @memberof BookBlurbBookSearchResult
   */
  books?: Array<BookBlurbBook> | null
}

/**
 * Check if a given object implements the BookBlurbBookSearchResult interface.
 */
export function instanceOfBookBlurbBookSearchResult(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function BookBlurbBookSearchResultFromJSON(
  json: any,
): BookBlurbBookSearchResult {
  return BookBlurbBookSearchResultFromJSONTyped(json, false)
}

export function BookBlurbBookSearchResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookBlurbBookSearchResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    totalCount: !exists(json, 'totalCount') ? undefined : json['totalCount'],
    books: !exists(json, 'books')
      ? undefined
      : json['books'] === null
        ? null
        : (json['books'] as Array<any>).map(BookBlurbBookFromJSON),
  }
}

export function BookBlurbBookSearchResultToJSON(
  value?: BookBlurbBookSearchResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    totalCount: value.totalCount,
    books:
      value.books === undefined
        ? undefined
        : value.books === null
          ? null
          : (value.books as Array<any>).map(BookBlurbBookToJSON),
  }
}
