// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CoinLogRepresentation
 */
export interface CoinLogRepresentation {
  /**
   *
   * @type {string}
   * @memberof CoinLogRepresentation
   */
  studentId?: string
  /**
   *
   * @type {string}
   * @memberof CoinLogRepresentation
   */
  eventId?: string
  /**
   *
   * @type {string}
   * @memberof CoinLogRepresentation
   */
  sourceEventId?: string
  /**
   *
   * @type {string}
   * @memberof CoinLogRepresentation
   */
  eventType?: string
  /**
   *
   * @type {number}
   * @memberof CoinLogRepresentation
   */
  coinBalanceChange?: number
  /**
   *
   * @type {Date}
   * @memberof CoinLogRepresentation
   */
  createdOn?: Date
}

/**
 * Check if a given object implements the CoinLogRepresentation interface.
 */
export function instanceOfCoinLogRepresentation(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function CoinLogRepresentationFromJSON(
  json: any,
): CoinLogRepresentation {
  return CoinLogRepresentationFromJSONTyped(json, false)
}

export function CoinLogRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CoinLogRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    eventId: !exists(json, 'event_id') ? undefined : json['event_id'],
    sourceEventId: !exists(json, 'source_event_id')
      ? undefined
      : json['source_event_id'],
    eventType: !exists(json, 'event_type') ? undefined : json['event_type'],
    coinBalanceChange: !exists(json, 'coin_balance_change')
      ? undefined
      : json['coin_balance_change'],
    createdOn: !exists(json, 'created_on')
      ? undefined
      : new Date(json['created_on']),
  }
}

export function CoinLogRepresentationToJSON(
  value?: CoinLogRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    student_id: value.studentId,
    event_id: value.eventId,
    source_event_id: value.sourceEventId,
    event_type: value.eventType,
    coin_balance_change: value.coinBalanceChange,
    created_on:
      value.createdOn === undefined ? undefined : value.createdOn.toISOString(),
  }
}
