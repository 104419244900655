// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MisspellingResponse
 */
export interface MisspellingResponse {
  /**
   *
   * @type {string}
   * @memberof MisspellingResponse
   */
  text: string
  /**
   *
   * @type {number}
   * @memberof MisspellingResponse
   */
  idx: number
  /**
   *
   * @type {number}
   * @memberof MisspellingResponse
   */
  len: number
}

/**
 * Check if a given object implements the MisspellingResponse interface.
 */
export function instanceOfMisspellingResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'text' in value
  isInstance = isInstance && 'idx' in value
  isInstance = isInstance && 'len' in value

  return isInstance
}

export function MisspellingResponseFromJSON(json: any): MisspellingResponse {
  return MisspellingResponseFromJSONTyped(json, false)
}

export function MisspellingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MisspellingResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    text: json['text'],
    idx: json['idx'],
    len: json['len'],
  }
}

export function MisspellingResponseToJSON(
  value?: MisspellingResponse | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    text: value.text,
    idx: value.idx,
    len: value.len,
  }
}
