// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SpellingByStudentIssueWordDateResponseDto } from './SpellingByStudentIssueWordDateResponseDto'
import {
  SpellingByStudentIssueWordDateResponseDtoFromJSON,
  SpellingByStudentIssueWordDateResponseDtoFromJSONTyped,
  SpellingByStudentIssueWordDateResponseDtoToJSON,
} from './SpellingByStudentIssueWordDateResponseDto'

/**
 *
 * @export
 * @interface SchoolsControllerGetSpellingByStudentIssueWordDate200Response
 */
export interface SchoolsControllerGetSpellingByStudentIssueWordDate200Response {
  /**
   *
   * @type {Array<SpellingByStudentIssueWordDateResponseDto>}
   * @memberof SchoolsControllerGetSpellingByStudentIssueWordDate200Response
   */
  data: Array<SpellingByStudentIssueWordDateResponseDto>
  /**
   *
   * @type {number}
   * @memberof SchoolsControllerGetSpellingByStudentIssueWordDate200Response
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof SchoolsControllerGetSpellingByStudentIssueWordDate200Response
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof SchoolsControllerGetSpellingByStudentIssueWordDate200Response
   */
  itemsPerPage: number
  /**
   * Additional metadata
   * @type {object}
   * @memberof SchoolsControllerGetSpellingByStudentIssueWordDate200Response
   */
  meta?: object
}

/**
 * Check if a given object implements the SchoolsControllerGetSpellingByStudentIssueWordDate200Response interface.
 */
export function instanceOfSchoolsControllerGetSpellingByStudentIssueWordDate200Response(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'total' in value
  isInstance = isInstance && 'page' in value
  isInstance = isInstance && 'itemsPerPage' in value

  return isInstance
}

export function SchoolsControllerGetSpellingByStudentIssueWordDate200ResponseFromJSON(
  json: any,
): SchoolsControllerGetSpellingByStudentIssueWordDate200Response {
  return SchoolsControllerGetSpellingByStudentIssueWordDate200ResponseFromJSONTyped(
    json,
    false,
  )
}

export function SchoolsControllerGetSpellingByStudentIssueWordDate200ResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SchoolsControllerGetSpellingByStudentIssueWordDate200Response {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(
      SpellingByStudentIssueWordDateResponseDtoFromJSON,
    ),
    total: json['total'],
    page: json['page'],
    itemsPerPage: json['itemsPerPage'],
    meta: !exists(json, 'meta') ? undefined : json['meta'],
  }
}

export function SchoolsControllerGetSpellingByStudentIssueWordDate200ResponseToJSON(
  value?: SchoolsControllerGetSpellingByStudentIssueWordDate200Response | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(
      SpellingByStudentIssueWordDateResponseDtoToJSON,
    ),
    total: value.total,
    page: value.page,
    itemsPerPage: value.itemsPerPage,
    meta: value.meta,
  }
}
