// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentLessonProgressionRepresentation
 */
export interface StudentLessonProgressionRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentLessonProgressionRepresentation
   */
  lessonStepId?: string | null
  /**
   *
   * @type {string}
   * @memberof StudentLessonProgressionRepresentation
   */
  onboardingStepId?: string | null
  /**
   *
   * @type {number}
   * @memberof StudentLessonProgressionRepresentation
   */
  lessonOverride?: number | null
  /**
   *
   * @type {boolean}
   * @memberof StudentLessonProgressionRepresentation
   */
  isPlacementTestOverride?: boolean
  /**
   *
   * @type {string}
   * @memberof StudentLessonProgressionRepresentation
   */
  assignmentId?: string | null
}

/**
 * Check if a given object implements the StudentLessonProgressionRepresentation interface.
 */
export function instanceOfStudentLessonProgressionRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function StudentLessonProgressionRepresentationFromJSON(
  json: any,
): StudentLessonProgressionRepresentation {
  return StudentLessonProgressionRepresentationFromJSONTyped(json, false)
}

export function StudentLessonProgressionRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentLessonProgressionRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    lessonStepId: !exists(json, 'lessonStepId')
      ? undefined
      : json['lessonStepId'],
    onboardingStepId: !exists(json, 'onboardingStepId')
      ? undefined
      : json['onboardingStepId'],
    lessonOverride: !exists(json, 'lessonOverride')
      ? undefined
      : json['lessonOverride'],
    isPlacementTestOverride: !exists(json, 'isPlacementTestOverride')
      ? undefined
      : json['isPlacementTestOverride'],
    assignmentId: !exists(json, 'assignmentId')
      ? undefined
      : json['assignmentId'],
  }
}

export function StudentLessonProgressionRepresentationToJSON(
  value?: StudentLessonProgressionRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    lessonStepId: value.lessonStepId,
    onboardingStepId: value.onboardingStepId,
    lessonOverride: value.lessonOverride,
    isPlacementTestOverride: value.isPlacementTestOverride,
    assignmentId: value.assignmentId,
  }
}
