// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityResultResponseRepresentationData } from './ActivityResultResponseRepresentationData'
import {
  ActivityResultResponseRepresentationDataFromJSON,
  ActivityResultResponseRepresentationDataFromJSONTyped,
  ActivityResultResponseRepresentationDataToJSON,
} from './ActivityResultResponseRepresentationData'

/**
 *
 * @export
 * @interface WritingResultResponseRepresentation
 */
export interface WritingResultResponseRepresentation {
  /**
   *
   * @type {ActivityResultResponseRepresentationData}
   * @memberof WritingResultResponseRepresentation
   */
  data: ActivityResultResponseRepresentationData
}

/**
 * Check if a given object implements the WritingResultResponseRepresentation interface.
 */
export function instanceOfWritingResultResponseRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value

  return isInstance
}

export function WritingResultResponseRepresentationFromJSON(
  json: any,
): WritingResultResponseRepresentation {
  return WritingResultResponseRepresentationFromJSONTyped(json, false)
}

export function WritingResultResponseRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WritingResultResponseRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: ActivityResultResponseRepresentationDataFromJSON(json['data']),
  }
}

export function WritingResultResponseRepresentationToJSON(
  value?: WritingResultResponseRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: ActivityResultResponseRepresentationDataToJSON(value.data),
  }
}
