// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SimpleClassroomRepresentation
 */
export interface SimpleClassroomRepresentation {
  /**
   *
   * @type {string}
   * @memberof SimpleClassroomRepresentation
   */
  classId: string
  /**
   *
   * @type {string}
   * @memberof SimpleClassroomRepresentation
   */
  classCode: string
  /**
   *
   * @type {string}
   * @memberof SimpleClassroomRepresentation
   */
  className: string
  /**
   *
   * @type {number}
   * @memberof SimpleClassroomRepresentation
   */
  blakeClassroomId: number
}

/**
 * Check if a given object implements the SimpleClassroomRepresentation interface.
 */
export function instanceOfSimpleClassroomRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'classId' in value
  isInstance = isInstance && 'classCode' in value
  isInstance = isInstance && 'className' in value
  isInstance = isInstance && 'blakeClassroomId' in value

  return isInstance
}

export function SimpleClassroomRepresentationFromJSON(
  json: any,
): SimpleClassroomRepresentation {
  return SimpleClassroomRepresentationFromJSONTyped(json, false)
}

export function SimpleClassroomRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SimpleClassroomRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    classId: json['class_id'],
    classCode: json['class_code'],
    className: json['class_name'],
    blakeClassroomId: json['blake_classroom_id'],
  }
}

export function SimpleClassroomRepresentationToJSON(
  value?: SimpleClassroomRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    class_id: value.classId,
    class_code: value.classCode,
    class_name: value.className,
    blake_classroom_id: value.blakeClassroomId,
  }
}
