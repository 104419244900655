// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentActivityCoinsRepresentationV2
 */
export interface StudentActivityCoinsRepresentationV2 {
  /**
   *
   * @type {number}
   * @memberof StudentActivityCoinsRepresentationV2
   */
  coinsEarned: number
}

/**
 * Check if a given object implements the StudentActivityCoinsRepresentationV2 interface.
 */
export function instanceOfStudentActivityCoinsRepresentationV2(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'coinsEarned' in value

  return isInstance
}

export function StudentActivityCoinsRepresentationV2FromJSON(
  json: any,
): StudentActivityCoinsRepresentationV2 {
  return StudentActivityCoinsRepresentationV2FromJSONTyped(json, false)
}

export function StudentActivityCoinsRepresentationV2FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentActivityCoinsRepresentationV2 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    coinsEarned: json['coins_earned'],
  }
}

export function StudentActivityCoinsRepresentationV2ToJSON(
  value?: StudentActivityCoinsRepresentationV2 | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    coins_earned: value.coinsEarned,
  }
}
