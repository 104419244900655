// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LatestSubmittedSnapshotFeedbackRepresentation
 */
export interface LatestSubmittedSnapshotFeedbackRepresentation {
  /**
   *
   * @type {string}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  activityResultSnapshotId: string
  /**
   *
   * @type {number}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  score: number
  /**
   *
   * @type {number}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  maxPoints: number
  /**
   *
   * @type {Date}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  updatedDate: Date
  /**
   *
   * @type {string}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  resultId: string
  /**
   *
   * @type {string}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  activityTitle: string
  /**
   *
   * @type {string}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  feedbackId?: string | null
  /**
   *
   * @type {number}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  feedbackMark?: number | null
  /**
   *
   * @type {Date}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  feedbackUpdatedDate?: Date | null
  /**
   *
   * @type {string}
   * @memberof LatestSubmittedSnapshotFeedbackRepresentation
   */
  plainText?: string | null
}

/**
 * Check if a given object implements the LatestSubmittedSnapshotFeedbackRepresentation interface.
 */
export function instanceOfLatestSubmittedSnapshotFeedbackRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'activityResultSnapshotId' in value
  isInstance = isInstance && 'score' in value
  isInstance = isInstance && 'maxPoints' in value
  isInstance = isInstance && 'updatedDate' in value
  isInstance = isInstance && 'resultId' in value
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'activityTitle' in value

  return isInstance
}

export function LatestSubmittedSnapshotFeedbackRepresentationFromJSON(
  json: any,
): LatestSubmittedSnapshotFeedbackRepresentation {
  return LatestSubmittedSnapshotFeedbackRepresentationFromJSONTyped(json, false)
}

export function LatestSubmittedSnapshotFeedbackRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LatestSubmittedSnapshotFeedbackRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityResultSnapshotId: json['activity_result_snapshot_id'],
    score: json['score'],
    maxPoints: json['max_points'],
    updatedDate: new Date(json['updated_date']),
    resultId: json['result_id'],
    activityId: json['activity_id'],
    activityTitle: json['activity_title'],
    feedbackId: !exists(json, 'feedback_id') ? undefined : json['feedback_id'],
    feedbackMark: !exists(json, 'feedback_mark')
      ? undefined
      : json['feedback_mark'],
    feedbackUpdatedDate: !exists(json, 'feedback_updated_date')
      ? undefined
      : json['feedback_updated_date'] === null
        ? null
        : new Date(json['feedback_updated_date']),
    plainText: !exists(json, 'plain_text') ? undefined : json['plain_text'],
  }
}

export function LatestSubmittedSnapshotFeedbackRepresentationToJSON(
  value?: LatestSubmittedSnapshotFeedbackRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_result_snapshot_id: value.activityResultSnapshotId,
    score: value.score,
    max_points: value.maxPoints,
    updated_date: value.updatedDate.toISOString(),
    result_id: value.resultId,
    activity_id: value.activityId,
    activity_title: value.activityTitle,
    feedback_id: value.feedbackId,
    feedback_mark: value.feedbackMark,
    feedback_updated_date:
      value.feedbackUpdatedDate === undefined
        ? undefined
        : value.feedbackUpdatedDate === null
          ? null
          : value.feedbackUpdatedDate.toISOString(),
    plain_text: value.plainText,
  }
}
