// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityResultRepresentation } from './ActivityResultRepresentation'
import {
  ActivityResultRepresentationFromJSON,
  ActivityResultRepresentationFromJSONTyped,
  ActivityResultRepresentationToJSON,
} from './ActivityResultRepresentation'

/**
 *
 * @export
 * @interface ActivityResultRepresentationBaseApiResult
 */
export interface ActivityResultRepresentationBaseApiResult {
  /**
   *
   * @type {ActivityResultRepresentation}
   * @memberof ActivityResultRepresentationBaseApiResult
   */
  data?: ActivityResultRepresentation
}

/**
 * Check if a given object implements the ActivityResultRepresentationBaseApiResult interface.
 */
export function instanceOfActivityResultRepresentationBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ActivityResultRepresentationBaseApiResultFromJSON(
  json: any,
): ActivityResultRepresentationBaseApiResult {
  return ActivityResultRepresentationBaseApiResultFromJSONTyped(json, false)
}

export function ActivityResultRepresentationBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityResultRepresentationBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : ActivityResultRepresentationFromJSON(json['data']),
  }
}

export function ActivityResultRepresentationBaseApiResultToJSON(
  value?: ActivityResultRepresentationBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: ActivityResultRepresentationToJSON(value.data),
  }
}
