// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateActivityFeedbackViewModel
 */
export interface CreateActivityFeedbackViewModel {
  /**
   *
   * @type {string}
   * @memberof CreateActivityFeedbackViewModel
   */
  feedbackId?: string
  /**
   *
   * @type {string}
   * @memberof CreateActivityFeedbackViewModel
   */
  studentId?: string
  /**
   *
   * @type {string}
   * @memberof CreateActivityFeedbackViewModel
   */
  resultId?: string
  /**
   *
   * @type {string}
   * @memberof CreateActivityFeedbackViewModel
   */
  activityResultSnapshotId?: string
  /**
   *
   * @type {string}
   * @memberof CreateActivityFeedbackViewModel
   */
  feedbackText?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateActivityFeedbackViewModel
   */
  mark?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateActivityFeedbackViewModel
   */
  sticker?: string | null
}

/**
 * Check if a given object implements the CreateActivityFeedbackViewModel interface.
 */
export function instanceOfCreateActivityFeedbackViewModel(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function CreateActivityFeedbackViewModelFromJSON(
  json: any,
): CreateActivityFeedbackViewModel {
  return CreateActivityFeedbackViewModelFromJSONTyped(json, false)
}

export function CreateActivityFeedbackViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateActivityFeedbackViewModel {
  if (json === undefined || json === null) {
    return json
  }
  return {
    feedbackId: !exists(json, 'feedback_id') ? undefined : json['feedback_id'],
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    resultId: !exists(json, 'result_id') ? undefined : json['result_id'],
    activityResultSnapshotId: !exists(json, 'activity_result_snapshot_id')
      ? undefined
      : json['activity_result_snapshot_id'],
    feedbackText: !exists(json, 'feedback_text')
      ? undefined
      : json['feedback_text'],
    mark: !exists(json, 'mark') ? undefined : json['mark'],
    sticker: !exists(json, 'sticker') ? undefined : json['sticker'],
  }
}

export function CreateActivityFeedbackViewModelToJSON(
  value?: CreateActivityFeedbackViewModel | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    feedback_id: value.feedbackId,
    student_id: value.studentId,
    result_id: value.resultId,
    activity_result_snapshot_id: value.activityResultSnapshotId,
    feedback_text: value.feedbackText,
    mark: value.mark,
    sticker: value.sticker,
  }
}
