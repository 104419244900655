// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BookBlurbRating } from './BookBlurbRating'
import {
  BookBlurbRatingFromJSON,
  BookBlurbRatingFromJSONTyped,
  BookBlurbRatingToJSON,
} from './BookBlurbRating'

/**
 *
 * @export
 * @interface BookBlurbsPutRequestV2
 */
export interface BookBlurbsPutRequestV2 {
  /**
   *
   * @type {string}
   * @memberof BookBlurbsPutRequestV2
   */
  eventId: string
  /**
   *
   * @type {BookBlurbRating}
   * @memberof BookBlurbsPutRequestV2
   */
  rating?: BookBlurbRating
  /**
   *
   * @type {Array<string>}
   * @memberof BookBlurbsPutRequestV2
   */
  words?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof BookBlurbsPutRequestV2
   */
  assignmentId?: string | null
  /**
   *
   * @type {number}
   * @memberof BookBlurbsPutRequestV2
   */
  assignmentTaskId?: number | null
}

/**
 * Check if a given object implements the BookBlurbsPutRequestV2 interface.
 */
export function instanceOfBookBlurbsPutRequestV2(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'eventId' in value

  return isInstance
}

export function BookBlurbsPutRequestV2FromJSON(
  json: any,
): BookBlurbsPutRequestV2 {
  return BookBlurbsPutRequestV2FromJSONTyped(json, false)
}

export function BookBlurbsPutRequestV2FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookBlurbsPutRequestV2 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    eventId: json['eventId'],
    rating: !exists(json, 'rating')
      ? undefined
      : BookBlurbRatingFromJSON(json['rating']),
    words: !exists(json, 'words') ? undefined : json['words'],
    assignmentId: !exists(json, 'assignmentId')
      ? undefined
      : json['assignmentId'],
    assignmentTaskId: !exists(json, 'assignmentTaskId')
      ? undefined
      : json['assignmentTaskId'],
  }
}

export function BookBlurbsPutRequestV2ToJSON(
  value?: BookBlurbsPutRequestV2 | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    eventId: value.eventId,
    rating: BookBlurbRatingToJSON(value.rating),
    words: value.words,
    assignmentId: value.assignmentId,
    assignmentTaskId: value.assignmentTaskId,
  }
}
