// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AttemptDto
 */
export interface AttemptDto {
  /**
   * Indicates if the the spelling attempt was correct
   * @type {boolean}
   * @memberof AttemptDto
   */
  isCorrect: boolean | null
  /**
   * The date and time when the attempt was made
   * @type {Date}
   * @memberof AttemptDto
   */
  createdDate?: Date | null
}

/**
 * Check if a given object implements the AttemptDto interface.
 */
export function instanceOfAttemptDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'isCorrect' in value

  return isInstance
}

export function AttemptDtoFromJSON(json: any): AttemptDto {
  return AttemptDtoFromJSONTyped(json, false)
}

export function AttemptDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AttemptDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    isCorrect: json['isCorrect'],
    createdDate: !exists(json, 'createdDate')
      ? undefined
      : json['createdDate'] === null
        ? null
        : new Date(json['createdDate']),
  }
}

export function AttemptDtoToJSON(value?: AttemptDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    isCorrect: value.isCorrect,
    createdDate:
      value.createdDate === undefined
        ? undefined
        : value.createdDate === null
          ? null
          : value.createdDate.toISOString(),
  }
}
