// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetStudentResultTotalsRepresentation
 */
export interface GetStudentResultTotalsRepresentation {
  /**
   *
   * @type {string}
   * @memberof GetStudentResultTotalsRepresentation
   */
  userId?: string | null
  /**
   *
   * @type {number}
   * @memberof GetStudentResultTotalsRepresentation
   */
  totalResults?: number
  /**
   *
   * @type {number}
   * @memberof GetStudentResultTotalsRepresentation
   */
  totalWords?: number
  /**
   *
   * @type {number}
   * @memberof GetStudentResultTotalsRepresentation
   */
  reflectionSmileCount?: number
  /**
   *
   * @type {number}
   * @memberof GetStudentResultTotalsRepresentation
   */
  reflectionUnsureCount?: number
  /**
   *
   * @type {number}
   * @memberof GetStudentResultTotalsRepresentation
   */
  reflectionDisappointedCount?: number
}

/**
 * Check if a given object implements the GetStudentResultTotalsRepresentation interface.
 */
export function instanceOfGetStudentResultTotalsRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function GetStudentResultTotalsRepresentationFromJSON(
  json: any,
): GetStudentResultTotalsRepresentation {
  return GetStudentResultTotalsRepresentationFromJSONTyped(json, false)
}

export function GetStudentResultTotalsRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetStudentResultTotalsRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    totalResults: !exists(json, 'totalResults')
      ? undefined
      : json['totalResults'],
    totalWords: !exists(json, 'totalWords') ? undefined : json['totalWords'],
    reflectionSmileCount: !exists(json, 'reflectionSmileCount')
      ? undefined
      : json['reflectionSmileCount'],
    reflectionUnsureCount: !exists(json, 'reflectionUnsureCount')
      ? undefined
      : json['reflectionUnsureCount'],
    reflectionDisappointedCount: !exists(json, 'reflectionDisappointedCount')
      ? undefined
      : json['reflectionDisappointedCount'],
  }
}

export function GetStudentResultTotalsRepresentationToJSON(
  value?: GetStudentResultTotalsRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_id: value.userId,
    totalResults: value.totalResults,
    totalWords: value.totalWords,
    reflectionSmileCount: value.reflectionSmileCount,
    reflectionUnsureCount: value.reflectionUnsureCount,
    reflectionDisappointedCount: value.reflectionDisappointedCount,
  }
}
