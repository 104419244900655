// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EwiPointsResponse } from './EwiPointsResponse'
import {
  EwiPointsResponseFromJSON,
  EwiPointsResponseFromJSONTyped,
  EwiPointsResponseToJSON,
} from './EwiPointsResponse'
import type { EwiScorerStrategies } from './EwiScorerStrategies'
import {
  EwiScorerStrategiesFromJSON,
  EwiScorerStrategiesFromJSONTyped,
  EwiScorerStrategiesToJSON,
} from './EwiScorerStrategies'

/**
 *
 * @export
 * @interface EwiScoresResponse
 */
export interface EwiScoresResponse {
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  wordCount: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  extraWords: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  wordVariety: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  sentenceLengthVariety: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  languageGems: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  misspelling: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  lowercaseSentenceStart: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  gt2AndsSentence: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  gt1ButsSentence: number
  /**
   *
   * @type {number}
   * @memberof EwiScoresResponse
   */
  total: number
  /**
   *
   * @type {EwiScorerStrategies}
   * @memberof EwiScoresResponse
   */
  version: EwiScorerStrategies
  /**
   *
   * @type {EwiPointsResponse}
   * @memberof EwiScoresResponse
   */
  points: EwiPointsResponse
}

/**
 * Check if a given object implements the EwiScoresResponse interface.
 */
export function instanceOfEwiScoresResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'wordCount' in value
  isInstance = isInstance && 'extraWords' in value
  isInstance = isInstance && 'wordVariety' in value
  isInstance = isInstance && 'sentenceLengthVariety' in value
  isInstance = isInstance && 'languageGems' in value
  isInstance = isInstance && 'misspelling' in value
  isInstance = isInstance && 'lowercaseSentenceStart' in value
  isInstance = isInstance && 'gt2AndsSentence' in value
  isInstance = isInstance && 'gt1ButsSentence' in value
  isInstance = isInstance && 'total' in value
  isInstance = isInstance && 'version' in value
  isInstance = isInstance && 'points' in value

  return isInstance
}

export function EwiScoresResponseFromJSON(json: any): EwiScoresResponse {
  return EwiScoresResponseFromJSONTyped(json, false)
}

export function EwiScoresResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EwiScoresResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    wordCount: json['word_count'],
    extraWords: json['extra_words'],
    wordVariety: json['word_variety'],
    sentenceLengthVariety: json['sentence_length_variety'],
    languageGems: json['language_gems'],
    misspelling: json['misspelling'],
    lowercaseSentenceStart: json['lowercase_sentence_start'],
    gt2AndsSentence: json['gt2_ands_sentence'],
    gt1ButsSentence: json['gt1_buts_sentence'],
    total: json['total'],
    version: EwiScorerStrategiesFromJSON(json['version']),
    points: EwiPointsResponseFromJSON(json['points']),
  }
}

export function EwiScoresResponseToJSON(value?: EwiScoresResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    word_count: value.wordCount,
    extra_words: value.extraWords,
    word_variety: value.wordVariety,
    sentence_length_variety: value.sentenceLengthVariety,
    language_gems: value.languageGems,
    misspelling: value.misspelling,
    lowercase_sentence_start: value.lowercaseSentenceStart,
    gt2_ands_sentence: value.gt2AndsSentence,
    gt1_buts_sentence: value.gt1ButsSentence,
    total: value.total,
    version: EwiScorerStrategiesToJSON(value.version),
    points: EwiPointsResponseToJSON(value.points),
  }
}
