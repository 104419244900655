// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentVocabularyWeekResponseDto
 */
export interface StudentVocabularyWeekResponseDto {
  /**
   * The vocabulary word by the student
   * @type {string}
   * @memberof StudentVocabularyWeekResponseDto
   */
  word: string | null
  /**
   * Indicates whether the word is a misspelling or not
   * @type {boolean}
   * @memberof StudentVocabularyWeekResponseDto
   */
  isMisspelling: boolean | null
  /**
   * The number of times the word was used
   * @type {number}
   * @memberof StudentVocabularyWeekResponseDto
   */
  frequency: number | null
}

/**
 * Check if a given object implements the StudentVocabularyWeekResponseDto interface.
 */
export function instanceOfStudentVocabularyWeekResponseDto(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'word' in value
  isInstance = isInstance && 'isMisspelling' in value
  isInstance = isInstance && 'frequency' in value

  return isInstance
}

export function StudentVocabularyWeekResponseDtoFromJSON(
  json: any,
): StudentVocabularyWeekResponseDto {
  return StudentVocabularyWeekResponseDtoFromJSONTyped(json, false)
}

export function StudentVocabularyWeekResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentVocabularyWeekResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    word: json['word'],
    isMisspelling: json['isMisspelling'],
    frequency: json['frequency'],
  }
}

export function StudentVocabularyWeekResponseDtoToJSON(
  value?: StudentVocabularyWeekResponseDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    word: value.word,
    isMisspelling: value.isMisspelling,
    frequency: value.frequency,
  }
}
