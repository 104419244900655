// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ActivityCatalogueBaseApiResult } from '../models/index'
import {
  ActivityCatalogueBaseApiResultFromJSON,
  ActivityCatalogueBaseApiResultToJSON,
} from '../models/index'

export interface ApiV1ViewsViewActivityCatalogueGetRequest {
  view: string
  grade?: string
  skip?: number
  take?: number
}

export interface ApiV1ViewsViewStudentIdActivitiescatalogueGetRequest {
  view: string
  studentId: string
  grade?: string
  includeResultStatus?: boolean
  skip?: number
  take?: number
}

/**
 *
 */
export class ViewsApi extends runtime.BaseAPI {
  /**
   * Returns an ActivityCatalogue for EWI (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1ViewsViewActivityCatalogueGetRaw(
    requestParameters: ApiV1ViewsViewActivityCatalogueGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ActivityCatalogueBaseApiResult>> {
    if (
      requestParameters.view === null ||
      requestParameters.view === undefined
    ) {
      throw new runtime.RequiredError(
        'view',
        'Required parameter requestParameters.view was null or undefined when calling apiV1ViewsViewActivityCatalogueGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.grade !== undefined) {
      queryParameters['grade'] = requestParameters.grade
    }

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/views/{view}/activity_catalogue`.replace(
          `{${'view'}}`,
          encodeURIComponent(String(requestParameters.view)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityCatalogueBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   * Returns an ActivityCatalogue for EWI (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1ViewsViewActivityCatalogueGet(
    requestParameters: ApiV1ViewsViewActivityCatalogueGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityCatalogueBaseApiResult> {
    const response = await this.apiV1ViewsViewActivityCatalogueGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Returns a catalogue of EWI activities, and where in progress or submitted, the WritingStatus and ActivityResultId (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1ViewsViewStudentIdActivitiescatalogueGetRaw(
    requestParameters: ApiV1ViewsViewStudentIdActivitiescatalogueGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ActivityCatalogueBaseApiResult>> {
    if (
      requestParameters.view === null ||
      requestParameters.view === undefined
    ) {
      throw new runtime.RequiredError(
        'view',
        'Required parameter requestParameters.view was null or undefined when calling apiV1ViewsViewStudentIdActivitiescatalogueGet.',
      )
    }

    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1ViewsViewStudentIdActivitiescatalogueGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.grade !== undefined) {
      queryParameters['grade'] = requestParameters.grade
    }

    if (requestParameters.includeResultStatus !== undefined) {
      queryParameters['includeResultStatus'] =
        requestParameters.includeResultStatus
    }

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/views/{view}/{studentId}/activitiescatalogue`
          .replace(
            `{${'view'}}`,
            encodeURIComponent(String(requestParameters.view)),
          )
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityCatalogueBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   * Returns a catalogue of EWI activities, and where in progress or submitted, the WritingStatus and ActivityResultId (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1ViewsViewStudentIdActivitiescatalogueGet(
    requestParameters: ApiV1ViewsViewStudentIdActivitiescatalogueGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityCatalogueBaseApiResult> {
    const response =
      await this.apiV1ViewsViewStudentIdActivitiescatalogueGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }
}
