// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { StudentCurrentTaskRepresentation } from './StudentCurrentTaskRepresentation'
import {
  StudentCurrentTaskRepresentationFromJSON,
  StudentCurrentTaskRepresentationFromJSONTyped,
  StudentCurrentTaskRepresentationToJSON,
} from './StudentCurrentTaskRepresentation'

/**
 *
 * @export
 * @interface StudentCurrentTaskRepresentationBaseApiResult
 */
export interface StudentCurrentTaskRepresentationBaseApiResult {
  /**
   *
   * @type {StudentCurrentTaskRepresentation}
   * @memberof StudentCurrentTaskRepresentationBaseApiResult
   */
  data?: StudentCurrentTaskRepresentation
}

/**
 * Check if a given object implements the StudentCurrentTaskRepresentationBaseApiResult interface.
 */
export function instanceOfStudentCurrentTaskRepresentationBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function StudentCurrentTaskRepresentationBaseApiResultFromJSON(
  json: any,
): StudentCurrentTaskRepresentationBaseApiResult {
  return StudentCurrentTaskRepresentationBaseApiResultFromJSONTyped(json, false)
}

export function StudentCurrentTaskRepresentationBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentCurrentTaskRepresentationBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : StudentCurrentTaskRepresentationFromJSON(json['data']),
  }
}

export function StudentCurrentTaskRepresentationBaseApiResultToJSON(
  value?: StudentCurrentTaskRepresentationBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: StudentCurrentTaskRepresentationToJSON(value.data),
  }
}
