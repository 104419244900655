// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityCatalogueItem } from './ActivityCatalogueItem'
import {
  ActivityCatalogueItemFromJSON,
  ActivityCatalogueItemFromJSONTyped,
  ActivityCatalogueItemToJSON,
} from './ActivityCatalogueItem'

/**
 *
 * @export
 * @interface ActivityCatalogue
 */
export interface ActivityCatalogue {
  /**
   *
   * @type {Array<ActivityCatalogueItem>}
   * @memberof ActivityCatalogue
   */
  items: Array<ActivityCatalogueItem>
  /**
   *
   * @type {string}
   * @memberof ActivityCatalogue
   */
  view: string
  /**
   *
   * @type {number}
   * @memberof ActivityCatalogue
   */
  take?: number
  /**
   *
   * @type {number}
   * @memberof ActivityCatalogue
   */
  skip?: number
  /**
   *
   * @type {number}
   * @memberof ActivityCatalogue
   */
  pageCount?: number
  /**
   *
   * @type {number}
   * @memberof ActivityCatalogue
   */
  totalItems?: number
}

/**
 * Check if a given object implements the ActivityCatalogue interface.
 */
export function instanceOfActivityCatalogue(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'items' in value
  isInstance = isInstance && 'view' in value

  return isInstance
}

export function ActivityCatalogueFromJSON(json: any): ActivityCatalogue {
  return ActivityCatalogueFromJSONTyped(json, false)
}

export function ActivityCatalogueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityCatalogue {
  if (json === undefined || json === null) {
    return json
  }
  return {
    items: (json['items'] as Array<any>).map(ActivityCatalogueItemFromJSON),
    view: json['view'],
    take: !exists(json, 'take') ? undefined : json['take'],
    skip: !exists(json, 'skip') ? undefined : json['skip'],
    pageCount: !exists(json, 'pageCount') ? undefined : json['pageCount'],
    totalItems: !exists(json, 'totalItems') ? undefined : json['totalItems'],
  }
}

export function ActivityCatalogueToJSON(value?: ActivityCatalogue | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    items: (value.items as Array<any>).map(ActivityCatalogueItemToJSON),
    view: value.view,
    take: value.take,
    skip: value.skip,
    pageCount: value.pageCount,
    totalItems: value.totalItems,
  }
}
