// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityAnswerRepresentation } from './ActivityAnswerRepresentation'
import {
  ActivityAnswerRepresentationFromJSON,
  ActivityAnswerRepresentationFromJSONTyped,
  ActivityAnswerRepresentationToJSON,
} from './ActivityAnswerRepresentation'
import type { ActivityRepresentation } from './ActivityRepresentation'
import {
  ActivityRepresentationFromJSON,
  ActivityRepresentationFromJSONTyped,
  ActivityRepresentationToJSON,
} from './ActivityRepresentation'
import type { ActivityResultSnapshotRepresentation } from './ActivityResultSnapshotRepresentation'
import {
  ActivityResultSnapshotRepresentationFromJSON,
  ActivityResultSnapshotRepresentationFromJSONTyped,
  ActivityResultSnapshotRepresentationToJSON,
} from './ActivityResultSnapshotRepresentation'
import type { FeedbackStatus } from './FeedbackStatus'
import {
  FeedbackStatusFromJSON,
  FeedbackStatusFromJSONTyped,
  FeedbackStatusToJSON,
} from './FeedbackStatus'
import type { SimpleProfileRepresentation } from './SimpleProfileRepresentation'
import {
  SimpleProfileRepresentationFromJSON,
  SimpleProfileRepresentationFromJSONTyped,
  SimpleProfileRepresentationToJSON,
} from './SimpleProfileRepresentation'

/**
 *
 * @export
 * @interface ActivityFeedbackDataRepresentation
 */
export interface ActivityFeedbackDataRepresentation {
  /**
   *
   * @type {ActivityRepresentation}
   * @memberof ActivityFeedbackDataRepresentation
   */
  activity?: ActivityRepresentation
  /**
   *
   * @type {ActivityResultSnapshotRepresentation}
   * @memberof ActivityFeedbackDataRepresentation
   */
  resultSnapshot?: ActivityResultSnapshotRepresentation
  /**
   *
   * @type {ActivityAnswerRepresentation}
   * @memberof ActivityFeedbackDataRepresentation
   */
  answer?: ActivityAnswerRepresentation
  /**
   *
   * @type {SimpleProfileRepresentation}
   * @memberof ActivityFeedbackDataRepresentation
   */
  user?: SimpleProfileRepresentation
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackDataRepresentation
   */
  feedbackId: string
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackDataRepresentation
   */
  studentId: string
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackDataRepresentation
   */
  resultId: string
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackDataRepresentation
   */
  activityResultSnapshotId: string
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackDataRepresentation
   */
  answerId?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackDataRepresentation
   */
  sticker?: string | null
  /**
   *
   * @type {FeedbackStatus}
   * @memberof ActivityFeedbackDataRepresentation
   */
  status?: FeedbackStatus
  /**
   *
   * @type {boolean}
   * @memberof ActivityFeedbackDataRepresentation
   */
  isSeen?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ActivityFeedbackDataRepresentation
   */
  isDeleted?: boolean
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackDataRepresentation
   */
  feedbackText?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityFeedbackDataRepresentation
   */
  timeTaken?: number | null
  /**
   *
   * @type {number}
   * @memberof ActivityFeedbackDataRepresentation
   */
  mark?: number | null
  /**
   *
   * @type {Date}
   * @memberof ActivityFeedbackDataRepresentation
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof ActivityFeedbackDataRepresentation
   */
  updatedDate?: Date | null
  /**
   *
   * @type {string}
   * @memberof ActivityFeedbackDataRepresentation
   */
  teacherFullName?: string | null
}

/**
 * Check if a given object implements the ActivityFeedbackDataRepresentation interface.
 */
export function instanceOfActivityFeedbackDataRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'feedbackId' in value
  isInstance = isInstance && 'studentId' in value
  isInstance = isInstance && 'resultId' in value
  isInstance = isInstance && 'activityResultSnapshotId' in value

  return isInstance
}

export function ActivityFeedbackDataRepresentationFromJSON(
  json: any,
): ActivityFeedbackDataRepresentation {
  return ActivityFeedbackDataRepresentationFromJSONTyped(json, false)
}

export function ActivityFeedbackDataRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityFeedbackDataRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activity: !exists(json, 'activity')
      ? undefined
      : ActivityRepresentationFromJSON(json['activity']),
    resultSnapshot: !exists(json, 'result_snapshot')
      ? undefined
      : ActivityResultSnapshotRepresentationFromJSON(json['result_snapshot']),
    answer: !exists(json, 'answer')
      ? undefined
      : ActivityAnswerRepresentationFromJSON(json['answer']),
    user: !exists(json, 'user')
      ? undefined
      : SimpleProfileRepresentationFromJSON(json['user']),
    feedbackId: json['feedback_id'],
    studentId: json['student_id'],
    resultId: json['result_id'],
    activityResultSnapshotId: json['activity_result_snapshot_id'],
    answerId: !exists(json, 'answer_id') ? undefined : json['answer_id'],
    sticker: !exists(json, 'sticker') ? undefined : json['sticker'],
    status: !exists(json, 'status')
      ? undefined
      : FeedbackStatusFromJSON(json['status']),
    isSeen: !exists(json, 'is_seen') ? undefined : json['is_seen'],
    isDeleted: !exists(json, 'is_deleted') ? undefined : json['is_deleted'],
    feedbackText: !exists(json, 'feedback_text')
      ? undefined
      : json['feedback_text'],
    timeTaken: !exists(json, 'time_taken') ? undefined : json['time_taken'],
    mark: !exists(json, 'mark') ? undefined : json['mark'],
    createdDate: !exists(json, 'created_date')
      ? undefined
      : new Date(json['created_date']),
    updatedDate: !exists(json, 'updated_date')
      ? undefined
      : json['updated_date'] === null
        ? null
        : new Date(json['updated_date']),
    teacherFullName: !exists(json, 'teacher_full_name')
      ? undefined
      : json['teacher_full_name'],
  }
}

export function ActivityFeedbackDataRepresentationToJSON(
  value?: ActivityFeedbackDataRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity: ActivityRepresentationToJSON(value.activity),
    result_snapshot: ActivityResultSnapshotRepresentationToJSON(
      value.resultSnapshot,
    ),
    answer: ActivityAnswerRepresentationToJSON(value.answer),
    user: SimpleProfileRepresentationToJSON(value.user),
    feedback_id: value.feedbackId,
    student_id: value.studentId,
    result_id: value.resultId,
    activity_result_snapshot_id: value.activityResultSnapshotId,
    answer_id: value.answerId,
    sticker: value.sticker,
    status: FeedbackStatusToJSON(value.status),
    is_seen: value.isSeen,
    is_deleted: value.isDeleted,
    feedback_text: value.feedbackText,
    time_taken: value.timeTaken,
    mark: value.mark,
    created_date:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString(),
    updated_date:
      value.updatedDate === undefined
        ? undefined
        : value.updatedDate === null
          ? null
          : value.updatedDate.toISOString(),
    teacher_full_name: value.teacherFullName,
  }
}
