// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SubmissionCoinsRepresentation
 */
export interface SubmissionCoinsRepresentation {
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  readonly resultScore: number
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  readonly preexistingActivityCoinsEarned: number
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  readonly submissionCoinsEarned: number
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  readonly coinsBalance: number
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  snapshotScore: number
  /**
   *
   * @type {boolean}
   * @memberof SubmissionCoinsRepresentation
   */
  isNewPersonalBest: boolean
  /**
   *
   * @type {boolean}
   * @memberof SubmissionCoinsRepresentation
   */
  isFirstSubmission: boolean
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  preExistingActivityCoinsEarned: number
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  snapshotCoinsEarned: number
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  activityCoinsEarned: number
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  activityCoinsMax: number
  /**
   *
   * @type {number}
   * @memberof SubmissionCoinsRepresentation
   */
  studentCoinsBalance: number
}

/**
 * Check if a given object implements the SubmissionCoinsRepresentation interface.
 */
export function instanceOfSubmissionCoinsRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'resultScore' in value
  isInstance = isInstance && 'preexistingActivityCoinsEarned' in value
  isInstance = isInstance && 'submissionCoinsEarned' in value
  isInstance = isInstance && 'coinsBalance' in value
  isInstance = isInstance && 'snapshotScore' in value
  isInstance = isInstance && 'isNewPersonalBest' in value
  isInstance = isInstance && 'isFirstSubmission' in value
  isInstance = isInstance && 'preExistingActivityCoinsEarned' in value
  isInstance = isInstance && 'snapshotCoinsEarned' in value
  isInstance = isInstance && 'activityCoinsEarned' in value
  isInstance = isInstance && 'activityCoinsMax' in value
  isInstance = isInstance && 'studentCoinsBalance' in value

  return isInstance
}

export function SubmissionCoinsRepresentationFromJSON(
  json: any,
): SubmissionCoinsRepresentation {
  return SubmissionCoinsRepresentationFromJSONTyped(json, false)
}

export function SubmissionCoinsRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubmissionCoinsRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    resultScore: json['result_score'],
    preexistingActivityCoinsEarned: json['preexisting_activity_coins_earned'],
    submissionCoinsEarned: json['submission_coins_earned'],
    coinsBalance: json['coins_balance'],
    snapshotScore: json['snapshotScore'],
    isNewPersonalBest: json['isNewPersonalBest'],
    isFirstSubmission: json['isFirstSubmission'],
    preExistingActivityCoinsEarned: json['preExistingActivityCoinsEarned'],
    snapshotCoinsEarned: json['snapshotCoinsEarned'],
    activityCoinsEarned: json['activityCoinsEarned'],
    activityCoinsMax: json['activityCoinsMax'],
    studentCoinsBalance: json['studentCoinsBalance'],
  }
}

export function SubmissionCoinsRepresentationToJSON(
  value?: SubmissionCoinsRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    snapshotScore: value.snapshotScore,
    isNewPersonalBest: value.isNewPersonalBest,
    isFirstSubmission: value.isFirstSubmission,
    preExistingActivityCoinsEarned: value.preExistingActivityCoinsEarned,
    snapshotCoinsEarned: value.snapshotCoinsEarned,
    activityCoinsEarned: value.activityCoinsEarned,
    activityCoinsMax: value.activityCoinsMax,
    studentCoinsBalance: value.studentCoinsBalance,
  }
}
