// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PositionRepresentation } from './PositionRepresentation'
import {
  PositionRepresentationFromJSON,
  PositionRepresentationFromJSONTyped,
  PositionRepresentationToJSON,
} from './PositionRepresentation'

/**
 *
 * @export
 * @interface RealmItemRepresentation
 */
export interface RealmItemRepresentation {
  /**
   *
   * @type {string}
   * @memberof RealmItemRepresentation
   */
  itemId: string
  /**
   *
   * @type {string}
   * @memberof RealmItemRepresentation
   */
  instanceId: string
  /**
   *
   * @type {PositionRepresentation}
   * @memberof RealmItemRepresentation
   */
  position: PositionRepresentation
}

/**
 * Check if a given object implements the RealmItemRepresentation interface.
 */
export function instanceOfRealmItemRepresentation(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'itemId' in value
  isInstance = isInstance && 'instanceId' in value
  isInstance = isInstance && 'position' in value

  return isInstance
}

export function RealmItemRepresentationFromJSON(
  json: any,
): RealmItemRepresentation {
  return RealmItemRepresentationFromJSONTyped(json, false)
}

export function RealmItemRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RealmItemRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    itemId: json['itemId'],
    instanceId: json['instanceId'],
    position: PositionRepresentationFromJSON(json['position']),
  }
}

export function RealmItemRepresentationToJSON(
  value?: RealmItemRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    itemId: value.itemId,
    instanceId: value.instanceId,
    position: PositionRepresentationToJSON(value.position),
  }
}
