// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentActivityCoinsRepresentation
 */
export interface StudentActivityCoinsRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentActivityCoinsRepresentation
   */
  studentId?: string
  /**
   *
   * @type {string}
   * @memberof StudentActivityCoinsRepresentation
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof StudentActivityCoinsRepresentation
   */
  eventId?: string
  /**
   *
   * @type {string}
   * @memberof StudentActivityCoinsRepresentation
   */
  sourceEventId?: string
  /**
   *
   * @type {string}
   * @memberof StudentActivityCoinsRepresentation
   */
  eventType?: string
  /**
   *
   * @type {number}
   * @memberof StudentActivityCoinsRepresentation
   */
  coinsEarned?: number
  /**
   *
   * @type {Date}
   * @memberof StudentActivityCoinsRepresentation
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof StudentActivityCoinsRepresentation
   */
  updatedDate?: Date | null
}

/**
 * Check if a given object implements the StudentActivityCoinsRepresentation interface.
 */
export function instanceOfStudentActivityCoinsRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function StudentActivityCoinsRepresentationFromJSON(
  json: any,
): StudentActivityCoinsRepresentation {
  return StudentActivityCoinsRepresentationFromJSONTyped(json, false)
}

export function StudentActivityCoinsRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentActivityCoinsRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    eventId: !exists(json, 'event_id') ? undefined : json['event_id'],
    sourceEventId: !exists(json, 'source_event_id')
      ? undefined
      : json['source_event_id'],
    eventType: !exists(json, 'event_type') ? undefined : json['event_type'],
    coinsEarned: !exists(json, 'coins_earned')
      ? undefined
      : json['coins_earned'],
    createdDate: !exists(json, 'created_date')
      ? undefined
      : new Date(json['created_date']),
    updatedDate: !exists(json, 'updated_date')
      ? undefined
      : json['updated_date'] === null
        ? null
        : new Date(json['updated_date']),
  }
}

export function StudentActivityCoinsRepresentationToJSON(
  value?: StudentActivityCoinsRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    student_id: value.studentId,
    activity_id: value.activityId,
    event_id: value.eventId,
    source_event_id: value.sourceEventId,
    event_type: value.eventType,
    coins_earned: value.coinsEarned,
    created_date:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString(),
    updated_date:
      value.updatedDate === undefined
        ? undefined
        : value.updatedDate === null
          ? null
          : value.updatedDate.toISOString(),
  }
}
