// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ClassroomBookBlurbsRepresentation } from './ClassroomBookBlurbsRepresentation'
import {
  ClassroomBookBlurbsRepresentationFromJSON,
  ClassroomBookBlurbsRepresentationFromJSONTyped,
  ClassroomBookBlurbsRepresentationToJSON,
} from './ClassroomBookBlurbsRepresentation'

/**
 *
 * @export
 * @interface ClassroomBookBlurbsRepresentationBaseApiListResult
 */
export interface ClassroomBookBlurbsRepresentationBaseApiListResult {
  /**
   *
   * @type {Array<ClassroomBookBlurbsRepresentation>}
   * @memberof ClassroomBookBlurbsRepresentationBaseApiListResult
   */
  data: Array<ClassroomBookBlurbsRepresentation>
  /**
   *
   * @type {number}
   * @memberof ClassroomBookBlurbsRepresentationBaseApiListResult
   */
  total: number
}

/**
 * Check if a given object implements the ClassroomBookBlurbsRepresentationBaseApiListResult interface.
 */
export function instanceOfClassroomBookBlurbsRepresentationBaseApiListResult(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'total' in value

  return isInstance
}

export function ClassroomBookBlurbsRepresentationBaseApiListResultFromJSON(
  json: any,
): ClassroomBookBlurbsRepresentationBaseApiListResult {
  return ClassroomBookBlurbsRepresentationBaseApiListResultFromJSONTyped(
    json,
    false,
  )
}

export function ClassroomBookBlurbsRepresentationBaseApiListResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ClassroomBookBlurbsRepresentationBaseApiListResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(
      ClassroomBookBlurbsRepresentationFromJSON,
    ),
    total: json['total'],
  }
}

export function ClassroomBookBlurbsRepresentationBaseApiListResultToJSON(
  value?: ClassroomBookBlurbsRepresentationBaseApiListResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(
      ClassroomBookBlurbsRepresentationToJSON,
    ),
    total: value.total,
  }
}
