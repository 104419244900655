// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface WowWordCountRepresentation
 */
export interface WowWordCountRepresentation {
  /**
   *
   * @type {string}
   * @memberof WowWordCountRepresentation
   */
  word?: string
  /**
   *
   * @type {number}
   * @memberof WowWordCountRepresentation
   */
  count?: number
  /**
   *
   * @type {string}
   * @memberof WowWordCountRepresentation
   */
  latestActivityId?: string
  /**
   *
   * @type {Date}
   * @memberof WowWordCountRepresentation
   */
  lastUpdatedDate?: Date
  /**
   *
   * @type {string}
   * @memberof WowWordCountRepresentation
   */
  lastUpdatedBy?: string
}

/**
 * Check if a given object implements the WowWordCountRepresentation interface.
 */
export function instanceOfWowWordCountRepresentation(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function WowWordCountRepresentationFromJSON(
  json: any,
): WowWordCountRepresentation {
  return WowWordCountRepresentationFromJSONTyped(json, false)
}

export function WowWordCountRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WowWordCountRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    word: !exists(json, 'word') ? undefined : json['word'],
    count: !exists(json, 'count') ? undefined : json['count'],
    latestActivityId: !exists(json, 'latest_activity_id')
      ? undefined
      : json['latest_activity_id'],
    lastUpdatedDate: !exists(json, 'last_updated_date')
      ? undefined
      : new Date(json['last_updated_date']),
    lastUpdatedBy: !exists(json, 'last_updated_by')
      ? undefined
      : json['last_updated_by'],
  }
}

export function WowWordCountRepresentationToJSON(
  value?: WowWordCountRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    word: value.word,
    count: value.count,
    latest_activity_id: value.latestActivityId,
    last_updated_date:
      value.lastUpdatedDate === undefined
        ? undefined
        : value.lastUpdatedDate.toISOString(),
    last_updated_by: value.lastUpdatedBy,
  }
}
