// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  GetSpellingByStudentWordResponseDto,
  SchoolsControllerGetSchoolSpellingByStudentWord200Response,
  SchoolsControllerGetSpellingByStudentDate200Response,
  SchoolsControllerGetSpellingByStudentIssueDate200Response,
  SchoolsControllerGetSpellingByStudentIssueWordDate200Response,
  SchoolsControllerGetSpellingByStudentWordDate200Response,
  SortType,
  SpellingByStudentDateSortableFields,
  SpellingByStudentIssueDateSortableFields,
  SpellingByStudentIssueWordDateSortableFields,
  SpellingByStudentWordDateSortableFields,
  SpellingByStudentWordSortableFields,
} from '../models/index'
import {
  GetSpellingByStudentWordResponseDtoFromJSON,
  GetSpellingByStudentWordResponseDtoToJSON,
  SchoolsControllerGetSchoolSpellingByStudentWord200ResponseFromJSON,
  SchoolsControllerGetSchoolSpellingByStudentWord200ResponseToJSON,
  SchoolsControllerGetSpellingByStudentDate200ResponseFromJSON,
  SchoolsControllerGetSpellingByStudentDate200ResponseToJSON,
  SchoolsControllerGetSpellingByStudentIssueDate200ResponseFromJSON,
  SchoolsControllerGetSpellingByStudentIssueDate200ResponseToJSON,
  SchoolsControllerGetSpellingByStudentIssueWordDate200ResponseFromJSON,
  SchoolsControllerGetSpellingByStudentIssueWordDate200ResponseToJSON,
  SchoolsControllerGetSpellingByStudentWordDate200ResponseFromJSON,
  SchoolsControllerGetSpellingByStudentWordDate200ResponseToJSON,
  SortTypeFromJSON,
  SortTypeToJSON,
  SpellingByStudentDateSortableFieldsFromJSON,
  SpellingByStudentDateSortableFieldsToJSON,
  SpellingByStudentIssueDateSortableFieldsFromJSON,
  SpellingByStudentIssueDateSortableFieldsToJSON,
  SpellingByStudentIssueWordDateSortableFieldsFromJSON,
  SpellingByStudentIssueWordDateSortableFieldsToJSON,
  SpellingByStudentWordDateSortableFieldsFromJSON,
  SpellingByStudentWordDateSortableFieldsToJSON,
  SpellingByStudentWordSortableFieldsFromJSON,
  SpellingByStudentWordSortableFieldsToJSON,
} from '../models/index'

export interface SchoolsControllerGetSchoolSpellingByStudentWordRequest {
  schoolBlakeId: number
  studentBlakeId: number
  skip?: number
  take?: number
  sortBy?: SpellingByStudentWordSortableFields
  sortType?: SortType
  sort?: string
}

export interface SchoolsControllerGetSpellingByStudentDateRequest {
  schoolBlakeId: number
  skip?: number
  take?: number
  sortBy?: SpellingByStudentDateSortableFields
  sortType?: SortType
  from?: Date
  to?: Date
  sId?: Array<number>
  sIdList?: string
  sort?: string
  apiVersion?: string
}

export interface SchoolsControllerGetSpellingByStudentIssueDateRequest {
  schoolBlakeId: number
  skip?: number
  take?: number
  sortBy?: SpellingByStudentIssueDateSortableFields
  sortType?: SortType
  from?: Date
  to?: Date
  sId?: Array<number>
  sIdList?: string
  sort?: string
}

export interface SchoolsControllerGetSpellingByStudentIssueWordDateRequest {
  schoolBlakeId: number
  spellingIssueId: number
  skip?: number
  take?: number
  sortBy?: SpellingByStudentIssueWordDateSortableFields
  sortType?: SortType
  from?: Date
  to?: Date
  sId?: Array<number>
  sIdList?: string
  sort?: string
}

export interface SchoolsControllerGetSpellingByStudentWordDateRequest {
  schoolBlakeId: number
  skip?: number
  take?: number
  sortBy?: SpellingByStudentWordDateSortableFields
  sortType?: SortType
  from?: Date
  to?: Date
  sId?: Array<number>
  sIdList?: string
  sort?: string
}

export interface StudentsControllerGetByStudentIdRequest {
  studentId: string
  skip?: number
  take?: number
  sortBy?: SpellingByStudentWordSortableFields
  sortType?: SortType
  sort?: string
}

/**
 *
 */
export class SpellingsApi extends runtime.BaseAPI {
  /**
   */
  async schoolsControllerGetSchoolSpellingByStudentWordRaw(
    requestParameters: SchoolsControllerGetSchoolSpellingByStudentWordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<SchoolsControllerGetSchoolSpellingByStudentWord200Response>
  > {
    if (
      requestParameters.schoolBlakeId === null ||
      requestParameters.schoolBlakeId === undefined
    ) {
      throw new runtime.RequiredError(
        'schoolBlakeId',
        'Required parameter requestParameters.schoolBlakeId was null or undefined when calling schoolsControllerGetSchoolSpellingByStudentWord.',
      )
    }

    if (
      requestParameters.studentBlakeId === null ||
      requestParameters.studentBlakeId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentBlakeId',
        'Required parameter requestParameters.studentBlakeId was null or undefined when calling schoolsControllerGetSchoolSpellingByStudentWord.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/spellings/schools/{schoolBlakeId}/students/{studentBlakeId}/words`
          .replace(
            `{${'schoolBlakeId'}}`,
            encodeURIComponent(String(requestParameters.schoolBlakeId)),
          )
          .replace(
            `{${'studentBlakeId'}}`,
            encodeURIComponent(String(requestParameters.studentBlakeId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SchoolsControllerGetSchoolSpellingByStudentWord200ResponseFromJSON(
        jsonValue,
      ),
    )
  }

  /**
   */
  async schoolsControllerGetSchoolSpellingByStudentWord(
    requestParameters: SchoolsControllerGetSchoolSpellingByStudentWordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolsControllerGetSchoolSpellingByStudentWord200Response> {
    const response =
      await this.schoolsControllerGetSchoolSpellingByStudentWordRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   */
  async schoolsControllerGetSpellingByStudentDateRaw(
    requestParameters: SchoolsControllerGetSpellingByStudentDateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<SchoolsControllerGetSpellingByStudentDate200Response>
  > {
    if (
      requestParameters.schoolBlakeId === null ||
      requestParameters.schoolBlakeId === undefined
    ) {
      throw new runtime.RequiredError(
        'schoolBlakeId',
        'Required parameter requestParameters.schoolBlakeId was null or undefined when calling schoolsControllerGetSpellingByStudentDate.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString()
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString()
    }

    if (requestParameters.sId) {
      queryParameters['sId'] = requestParameters.sId
    }

    if (requestParameters.sIdList !== undefined) {
      queryParameters['sIdList'] = requestParameters.sIdList
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      requestParameters.apiVersion !== undefined &&
      requestParameters.apiVersion !== null
    ) {
      headerParameters['Api-Version'] = String(requestParameters.apiVersion)
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/spellings/schools/{schoolBlakeId}/students`.replace(
          `{${'schoolBlakeId'}}`,
          encodeURIComponent(String(requestParameters.schoolBlakeId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SchoolsControllerGetSpellingByStudentDate200ResponseFromJSON(jsonValue),
    )
  }

  /**
   */
  async schoolsControllerGetSpellingByStudentDate(
    requestParameters: SchoolsControllerGetSpellingByStudentDateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolsControllerGetSpellingByStudentDate200Response> {
    const response = await this.schoolsControllerGetSpellingByStudentDateRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async schoolsControllerGetSpellingByStudentIssueDateRaw(
    requestParameters: SchoolsControllerGetSpellingByStudentIssueDateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<SchoolsControllerGetSpellingByStudentIssueDate200Response>
  > {
    if (
      requestParameters.schoolBlakeId === null ||
      requestParameters.schoolBlakeId === undefined
    ) {
      throw new runtime.RequiredError(
        'schoolBlakeId',
        'Required parameter requestParameters.schoolBlakeId was null or undefined when calling schoolsControllerGetSpellingByStudentIssueDate.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString()
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString()
    }

    if (requestParameters.sId) {
      queryParameters['sId'] = requestParameters.sId
    }

    if (requestParameters.sIdList !== undefined) {
      queryParameters['sIdList'] = requestParameters.sIdList
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/spellings/schools/{schoolBlakeId}/issues`.replace(
          `{${'schoolBlakeId'}}`,
          encodeURIComponent(String(requestParameters.schoolBlakeId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SchoolsControllerGetSpellingByStudentIssueDate200ResponseFromJSON(
        jsonValue,
      ),
    )
  }

  /**
   */
  async schoolsControllerGetSpellingByStudentIssueDate(
    requestParameters: SchoolsControllerGetSpellingByStudentIssueDateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolsControllerGetSpellingByStudentIssueDate200Response> {
    const response =
      await this.schoolsControllerGetSpellingByStudentIssueDateRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   */
  async schoolsControllerGetSpellingByStudentIssueWordDateRaw(
    requestParameters: SchoolsControllerGetSpellingByStudentIssueWordDateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<SchoolsControllerGetSpellingByStudentIssueWordDate200Response>
  > {
    if (
      requestParameters.schoolBlakeId === null ||
      requestParameters.schoolBlakeId === undefined
    ) {
      throw new runtime.RequiredError(
        'schoolBlakeId',
        'Required parameter requestParameters.schoolBlakeId was null or undefined when calling schoolsControllerGetSpellingByStudentIssueWordDate.',
      )
    }

    if (
      requestParameters.spellingIssueId === null ||
      requestParameters.spellingIssueId === undefined
    ) {
      throw new runtime.RequiredError(
        'spellingIssueId',
        'Required parameter requestParameters.spellingIssueId was null or undefined when calling schoolsControllerGetSpellingByStudentIssueWordDate.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString()
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString()
    }

    if (requestParameters.sId) {
      queryParameters['sId'] = requestParameters.sId
    }

    if (requestParameters.sIdList !== undefined) {
      queryParameters['sIdList'] = requestParameters.sIdList
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/spellings/schools/{schoolBlakeId}/spellingIssues/{spellingIssueId}/words`
          .replace(
            `{${'schoolBlakeId'}}`,
            encodeURIComponent(String(requestParameters.schoolBlakeId)),
          )
          .replace(
            `{${'spellingIssueId'}}`,
            encodeURIComponent(String(requestParameters.spellingIssueId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SchoolsControllerGetSpellingByStudentIssueWordDate200ResponseFromJSON(
        jsonValue,
      ),
    )
  }

  /**
   */
  async schoolsControllerGetSpellingByStudentIssueWordDate(
    requestParameters: SchoolsControllerGetSpellingByStudentIssueWordDateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolsControllerGetSpellingByStudentIssueWordDate200Response> {
    const response =
      await this.schoolsControllerGetSpellingByStudentIssueWordDateRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   */
  async schoolsControllerGetSpellingByStudentWordDateRaw(
    requestParameters: SchoolsControllerGetSpellingByStudentWordDateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<SchoolsControllerGetSpellingByStudentWordDate200Response>
  > {
    if (
      requestParameters.schoolBlakeId === null ||
      requestParameters.schoolBlakeId === undefined
    ) {
      throw new runtime.RequiredError(
        'schoolBlakeId',
        'Required parameter requestParameters.schoolBlakeId was null or undefined when calling schoolsControllerGetSpellingByStudentWordDate.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString()
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString()
    }

    if (requestParameters.sId) {
      queryParameters['sId'] = requestParameters.sId
    }

    if (requestParameters.sIdList !== undefined) {
      queryParameters['sIdList'] = requestParameters.sIdList
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/spellings/schools/{schoolBlakeId}/words`.replace(
          `{${'schoolBlakeId'}}`,
          encodeURIComponent(String(requestParameters.schoolBlakeId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SchoolsControllerGetSpellingByStudentWordDate200ResponseFromJSON(
        jsonValue,
      ),
    )
  }

  /**
   */
  async schoolsControllerGetSpellingByStudentWordDate(
    requestParameters: SchoolsControllerGetSpellingByStudentWordDateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolsControllerGetSpellingByStudentWordDate200Response> {
    const response =
      await this.schoolsControllerGetSpellingByStudentWordDateRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   */
  async studentsControllerGetByStudentIdRaw(
    requestParameters: StudentsControllerGetByStudentIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<GetSpellingByStudentWordResponseDto>>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling studentsControllerGetByStudentId.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/spellings/students/{studentId}/words`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(GetSpellingByStudentWordResponseDtoFromJSON),
    )
  }

  /**
   */
  async studentsControllerGetByStudentId(
    requestParameters: StudentsControllerGetByStudentIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<GetSpellingByStudentWordResponseDto>> {
    const response = await this.studentsControllerGetByStudentIdRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
