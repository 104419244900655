// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StudentLessonWritingRepresentation
 */
export interface StudentLessonWritingRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentLessonWritingRepresentation
   */
  eventId: string
  /**
   *
   * @type {string}
   * @memberof StudentLessonWritingRepresentation
   */
  activityId: string
  /**
   *
   * @type {number}
   * @memberof StudentLessonWritingRepresentation
   */
  map: number
  /**
   *
   * @type {number}
   * @memberof StudentLessonWritingRepresentation
   */
  lesson: number
  /**
   *
   * @type {number}
   * @memberof StudentLessonWritingRepresentation
   */
  lessonPart: number
  /**
   *
   * @type {string}
   * @memberof StudentLessonWritingRepresentation
   */
  contentDataName: string
  /**
   *
   * @type {Array<string>}
   * @memberof StudentLessonWritingRepresentation
   */
  studentAnswers: Array<string>
  /**
   *
   * @type {Date}
   * @memberof StudentLessonWritingRepresentation
   */
  createdDate: Date
}

/**
 * Check if a given object implements the StudentLessonWritingRepresentation interface.
 */
export function instanceOfStudentLessonWritingRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'eventId' in value
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'map' in value
  isInstance = isInstance && 'lesson' in value
  isInstance = isInstance && 'lessonPart' in value
  isInstance = isInstance && 'contentDataName' in value
  isInstance = isInstance && 'studentAnswers' in value
  isInstance = isInstance && 'createdDate' in value

  return isInstance
}

export function StudentLessonWritingRepresentationFromJSON(
  json: any,
): StudentLessonWritingRepresentation {
  return StudentLessonWritingRepresentationFromJSONTyped(json, false)
}

export function StudentLessonWritingRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentLessonWritingRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    eventId: json['event_id'],
    activityId: json['activity_id'],
    map: json['map'],
    lesson: json['lesson'],
    lessonPart: json['lesson_part'],
    contentDataName: json['content_data_name'],
    studentAnswers: json['student_answers'],
    createdDate: new Date(json['created_date']),
  }
}

export function StudentLessonWritingRepresentationToJSON(
  value?: StudentLessonWritingRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    event_id: value.eventId,
    activity_id: value.activityId,
    map: value.map,
    lesson: value.lesson,
    lesson_part: value.lessonPart,
    content_data_name: value.contentDataName,
    student_answers: value.studentAnswers,
    created_date: value.createdDate.toISOString(),
  }
}
