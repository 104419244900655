// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * wl_spacy_fastapi
 * Writing Legends NLP API Service
 *
 * The version of the OpenAPI document: default
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { LegendFeedbacksResponse } from './LegendFeedbacksResponse'
import {
  LegendFeedbacksResponseFromJSON,
  LegendFeedbacksResponseFromJSONTyped,
  LegendFeedbacksResponseToJSON,
} from './LegendFeedbacksResponse'

/**
 *
 * @export
 * @interface AnalyzeTextResponse
 */
export interface AnalyzeTextResponse {
  /**
   *
   * @type {LegendFeedbacksResponse}
   * @memberof AnalyzeTextResponse
   */
  legendFeedbacks: LegendFeedbacksResponse
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof AnalyzeTextResponse
   */
  tokenCounts: { [key: string]: number }
}

/**
 * Check if a given object implements the AnalyzeTextResponse interface.
 */
export function instanceOfAnalyzeTextResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'legendFeedbacks' in value
  isInstance = isInstance && 'tokenCounts' in value

  return isInstance
}

export function AnalyzeTextResponseFromJSON(json: any): AnalyzeTextResponse {
  return AnalyzeTextResponseFromJSONTyped(json, false)
}

export function AnalyzeTextResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnalyzeTextResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    legendFeedbacks: LegendFeedbacksResponseFromJSON(json['legend_feedbacks']),
    tokenCounts: json['token_counts'],
  }
}

export function AnalyzeTextResponseToJSON(
  value?: AnalyzeTextResponse | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    legend_feedbacks: LegendFeedbacksResponseToJSON(value.legendFeedbacks),
    token_counts: value.tokenCounts,
  }
}
