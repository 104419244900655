// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { MisspellingRepresentation } from './MisspellingRepresentation'
import {
  MisspellingRepresentationFromJSON,
  MisspellingRepresentationFromJSONTyped,
  MisspellingRepresentationToJSON,
} from './MisspellingRepresentation'

/**
 *
 * @export
 * @interface LegendsFeedbackRepresentation
 */
export interface LegendsFeedbackRepresentation {
  /**
   *
   * @type {string}
   * @memberof LegendsFeedbackRepresentation
   */
  activityAnswerId?: string
  /**
   *
   * @type {string}
   * @memberof LegendsFeedbackRepresentation
   */
  activityAnswerSnapshotId?: string | null
  /**
   *
   * @type {string}
   * @memberof LegendsFeedbackRepresentation
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof LegendsFeedbackRepresentation
   */
  activityResultId?: string
  /**
   *
   * @type {string}
   * @memberof LegendsFeedbackRepresentation
   */
  activityResultSnapshotId?: string | null
  /**
   *
   * @type {string}
   * @memberof LegendsFeedbackRepresentation
   */
  version?: string | null
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  targetWordCount?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  uniqueWordCount?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  wordCount?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  sentenceCount?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  wordVariety?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  sentenceVariety?: number
  /**
   *
   * @type {Array<string>}
   * @memberof LegendsFeedbackRepresentation
   */
  languageGems?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  scoreTotal?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  scoreWordCount?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  scoreWordCountBonus?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  scoreLanguageGemCount?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  scoreSentenceLengthVariation?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  scoreWordVariety?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  maxScoreTotal?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  maxScoreWordCount?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  maxScoreWordCountBonus?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  maxScoreLanguageGemCount?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  maxScoreSentenceLengthVariation?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  maxScoreWordVariety?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  errorTotalPointsDeducted?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  errorMisspelling?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  errorLowercaseSentenceStart?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  errorGt2AndsSentence?: number
  /**
   *
   * @type {number}
   * @memberof LegendsFeedbackRepresentation
   */
  errorGt1ButsSentence?: number
  /**
   *
   * @type {string}
   * @memberof LegendsFeedbackRepresentation
   */
  nlperror?: string | null
  /**
   *
   * @type {Array<MisspellingRepresentation>}
   * @memberof LegendsFeedbackRepresentation
   */
  misspellings?: Array<MisspellingRepresentation> | null
}

/**
 * Check if a given object implements the LegendsFeedbackRepresentation interface.
 */
export function instanceOfLegendsFeedbackRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function LegendsFeedbackRepresentationFromJSON(
  json: any,
): LegendsFeedbackRepresentation {
  return LegendsFeedbackRepresentationFromJSONTyped(json, false)
}

export function LegendsFeedbackRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LegendsFeedbackRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityAnswerId: !exists(json, 'activity_answer_id')
      ? undefined
      : json['activity_answer_id'],
    activityAnswerSnapshotId: !exists(json, 'activity_answer_snapshot_id')
      ? undefined
      : json['activity_answer_snapshot_id'],
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    activityResultId: !exists(json, 'activity_result_id')
      ? undefined
      : json['activity_result_id'],
    activityResultSnapshotId: !exists(json, 'activity_result_snapshot_id')
      ? undefined
      : json['activity_result_snapshot_id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    targetWordCount: !exists(json, 'target_word_count')
      ? undefined
      : json['target_word_count'],
    uniqueWordCount: !exists(json, 'unique_word_count')
      ? undefined
      : json['unique_word_count'],
    wordCount: !exists(json, 'word_count') ? undefined : json['word_count'],
    sentenceCount: !exists(json, 'sentence_count')
      ? undefined
      : json['sentence_count'],
    wordVariety: !exists(json, 'word_variety')
      ? undefined
      : json['word_variety'],
    sentenceVariety: !exists(json, 'sentence_variety')
      ? undefined
      : json['sentence_variety'],
    languageGems: !exists(json, 'language_gems')
      ? undefined
      : json['language_gems'],
    scoreTotal: !exists(json, 'score_total') ? undefined : json['score_total'],
    scoreWordCount: !exists(json, 'score_word_count')
      ? undefined
      : json['score_word_count'],
    scoreWordCountBonus: !exists(json, 'score_word_count_bonus')
      ? undefined
      : json['score_word_count_bonus'],
    scoreLanguageGemCount: !exists(json, 'score_language_gem_count')
      ? undefined
      : json['score_language_gem_count'],
    scoreSentenceLengthVariation: !exists(
      json,
      'score_sentence_length_variation',
    )
      ? undefined
      : json['score_sentence_length_variation'],
    scoreWordVariety: !exists(json, 'score_word_variety')
      ? undefined
      : json['score_word_variety'],
    maxScoreTotal: !exists(json, 'max_score_total')
      ? undefined
      : json['max_score_total'],
    maxScoreWordCount: !exists(json, 'max_score_word_count')
      ? undefined
      : json['max_score_word_count'],
    maxScoreWordCountBonus: !exists(json, 'max_score_word_count_bonus')
      ? undefined
      : json['max_score_word_count_bonus'],
    maxScoreLanguageGemCount: !exists(json, 'max_score_language_gem_count')
      ? undefined
      : json['max_score_language_gem_count'],
    maxScoreSentenceLengthVariation: !exists(
      json,
      'max_score_sentence_length_variation',
    )
      ? undefined
      : json['max_score_sentence_length_variation'],
    maxScoreWordVariety: !exists(json, 'max_score_word_variety')
      ? undefined
      : json['max_score_word_variety'],
    errorTotalPointsDeducted: !exists(json, 'error_total_points_deducted')
      ? undefined
      : json['error_total_points_deducted'],
    errorMisspelling: !exists(json, 'error_misspelling')
      ? undefined
      : json['error_misspelling'],
    errorLowercaseSentenceStart: !exists(json, 'error_lowercase_sentence_start')
      ? undefined
      : json['error_lowercase_sentence_start'],
    errorGt2AndsSentence: !exists(json, 'error_gt2_ands_sentence')
      ? undefined
      : json['error_gt2_ands_sentence'],
    errorGt1ButsSentence: !exists(json, 'error_gt1_buts_sentence')
      ? undefined
      : json['error_gt1_buts_sentence'],
    nlperror: !exists(json, 'nlperror') ? undefined : json['nlperror'],
    misspellings: !exists(json, 'misspellings')
      ? undefined
      : json['misspellings'] === null
        ? null
        : (json['misspellings'] as Array<any>).map(
            MisspellingRepresentationFromJSON,
          ),
  }
}

export function LegendsFeedbackRepresentationToJSON(
  value?: LegendsFeedbackRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_answer_id: value.activityAnswerId,
    activity_answer_snapshot_id: value.activityAnswerSnapshotId,
    activity_id: value.activityId,
    activity_result_id: value.activityResultId,
    activity_result_snapshot_id: value.activityResultSnapshotId,
    version: value.version,
    target_word_count: value.targetWordCount,
    unique_word_count: value.uniqueWordCount,
    word_count: value.wordCount,
    sentence_count: value.sentenceCount,
    word_variety: value.wordVariety,
    sentence_variety: value.sentenceVariety,
    language_gems: value.languageGems,
    score_total: value.scoreTotal,
    score_word_count: value.scoreWordCount,
    score_word_count_bonus: value.scoreWordCountBonus,
    score_language_gem_count: value.scoreLanguageGemCount,
    score_sentence_length_variation: value.scoreSentenceLengthVariation,
    score_word_variety: value.scoreWordVariety,
    max_score_total: value.maxScoreTotal,
    max_score_word_count: value.maxScoreWordCount,
    max_score_word_count_bonus: value.maxScoreWordCountBonus,
    max_score_language_gem_count: value.maxScoreLanguageGemCount,
    max_score_sentence_length_variation: value.maxScoreSentenceLengthVariation,
    max_score_word_variety: value.maxScoreWordVariety,
    error_total_points_deducted: value.errorTotalPointsDeducted,
    error_misspelling: value.errorMisspelling,
    error_lowercase_sentence_start: value.errorLowercaseSentenceStart,
    error_gt2_ands_sentence: value.errorGt2AndsSentence,
    error_gt1_buts_sentence: value.errorGt1ButsSentence,
    nlperror: value.nlperror,
    misspellings:
      value.misspellings === undefined
        ? undefined
        : value.misspellings === null
          ? null
          : (value.misspellings as Array<any>).map(
              MisspellingRepresentationToJSON,
            ),
  }
}
