// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityDataRepresentation } from './ActivityDataRepresentation'
import {
  ActivityDataRepresentationFromJSON,
  ActivityDataRepresentationFromJSONTyped,
  ActivityDataRepresentationToJSON,
} from './ActivityDataRepresentation'

/**
 *
 * @export
 * @interface ActivityDataRepresentationBaseApiResult
 */
export interface ActivityDataRepresentationBaseApiResult {
  /**
   *
   * @type {ActivityDataRepresentation}
   * @memberof ActivityDataRepresentationBaseApiResult
   */
  data?: ActivityDataRepresentation
}

/**
 * Check if a given object implements the ActivityDataRepresentationBaseApiResult interface.
 */
export function instanceOfActivityDataRepresentationBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ActivityDataRepresentationBaseApiResultFromJSON(
  json: any,
): ActivityDataRepresentationBaseApiResult {
  return ActivityDataRepresentationBaseApiResultFromJSONTyped(json, false)
}

export function ActivityDataRepresentationBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityDataRepresentationBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : ActivityDataRepresentationFromJSON(json['data']),
  }
}

export function ActivityDataRepresentationBaseApiResultToJSON(
  value?: ActivityDataRepresentationBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: ActivityDataRepresentationToJSON(value.data),
  }
}
