// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SpellingByStudentWordDateResponseDto
 */
export interface SpellingByStudentWordDateResponseDto {
  /**
   * The correctly spelt word
   * @type {number}
   * @memberof SpellingByStudentWordDateResponseDto
   */
  correctWord: number
  /**
   * The incorrectly spelt word(s)
   * @type {number}
   * @memberof SpellingByStudentWordDateResponseDto
   */
  incorrectWord: number
  /**
   * The total number of correct spelling
   * @type {number}
   * @memberof SpellingByStudentWordDateResponseDto
   */
  totalCorrect?: number | null
  /**
   * The total number of incorrect spelling
   * @type {number}
   * @memberof SpellingByStudentWordDateResponseDto
   */
  totalIncorrect?: number | null
}

/**
 * Check if a given object implements the SpellingByStudentWordDateResponseDto interface.
 */
export function instanceOfSpellingByStudentWordDateResponseDto(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'correctWord' in value
  isInstance = isInstance && 'incorrectWord' in value

  return isInstance
}

export function SpellingByStudentWordDateResponseDtoFromJSON(
  json: any,
): SpellingByStudentWordDateResponseDto {
  return SpellingByStudentWordDateResponseDtoFromJSONTyped(json, false)
}

export function SpellingByStudentWordDateResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SpellingByStudentWordDateResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    correctWord: json['correctWord'],
    incorrectWord: json['incorrectWord'],
    totalCorrect: !exists(json, 'totalCorrect')
      ? undefined
      : json['totalCorrect'],
    totalIncorrect: !exists(json, 'totalIncorrect')
      ? undefined
      : json['totalIncorrect'],
  }
}

export function SpellingByStudentWordDateResponseDtoToJSON(
  value?: SpellingByStudentWordDateResponseDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    correctWord: value.correctWord,
    incorrectWord: value.incorrectWord,
    totalCorrect: value.totalCorrect,
    totalIncorrect: value.totalIncorrect,
  }
}
