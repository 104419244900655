// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BookBlurbRating } from './BookBlurbRating'
import {
  BookBlurbRatingFromJSON,
  BookBlurbRatingFromJSONTyped,
  BookBlurbRatingToJSON,
} from './BookBlurbRating'

/**
 *
 * @export
 * @interface StudentBookBlurbRatingRepresentation
 */
export interface StudentBookBlurbRatingRepresentation {
  /**
   *
   * @type {string}
   * @memberof StudentBookBlurbRatingRepresentation
   */
  studentId?: string
  /**
   *
   * @type {string}
   * @memberof StudentBookBlurbRatingRepresentation
   */
  bookBlurbId?: string
  /**
   *
   * @type {string}
   * @memberof StudentBookBlurbRatingRepresentation
   */
  firstName?: string | null
  /**
   *
   * @type {string}
   * @memberof StudentBookBlurbRatingRepresentation
   */
  lastName?: string | null
  /**
   *
   * @type {BookBlurbRating}
   * @memberof StudentBookBlurbRatingRepresentation
   */
  rating?: BookBlurbRating
  /**
   *
   * @type {Array<string>}
   * @memberof StudentBookBlurbRatingRepresentation
   */
  words?: Array<string> | null
  /**
   *
   * @type {Date}
   * @memberof StudentBookBlurbRatingRepresentation
   */
  lastModifiedDate?: Date
}

/**
 * Check if a given object implements the StudentBookBlurbRatingRepresentation interface.
 */
export function instanceOfStudentBookBlurbRatingRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function StudentBookBlurbRatingRepresentationFromJSON(
  json: any,
): StudentBookBlurbRatingRepresentation {
  return StudentBookBlurbRatingRepresentationFromJSONTyped(json, false)
}

export function StudentBookBlurbRatingRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentBookBlurbRatingRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    bookBlurbId: !exists(json, 'book_blurb_id')
      ? undefined
      : json['book_blurb_id'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    rating: !exists(json, 'rating')
      ? undefined
      : BookBlurbRatingFromJSON(json['rating']),
    words: !exists(json, 'words') ? undefined : json['words'],
    lastModifiedDate: !exists(json, 'last_modified_date')
      ? undefined
      : new Date(json['last_modified_date']),
  }
}

export function StudentBookBlurbRatingRepresentationToJSON(
  value?: StudentBookBlurbRatingRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    student_id: value.studentId,
    book_blurb_id: value.bookBlurbId,
    first_name: value.firstName,
    last_name: value.lastName,
    rating: BookBlurbRatingToJSON(value.rating),
    words: value.words,
    last_modified_date:
      value.lastModifiedDate === undefined
        ? undefined
        : value.lastModifiedDate.toISOString(),
  }
}
